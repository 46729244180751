import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Box,
  Chip
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ImageUploading from "react-images-uploading";
import DefaultProfileImg from "../../assets/images/defaultProfileImg.jpg";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Style from "../../styles/profile.module.scss";
import { TimeField } from "@mui/x-date-pickers";
import { toast } from "react-hot-toast";
import { shortVideoEditApi } from "../../store/shortVideo/editShortVideo";
import { shortVideoAddApi } from "../../store/shortVideo/addShortVideo";
import { categoryApi } from "../../store/categoryDetails/categoryList";
import { shortVideoDetailsApi } from "../../store/shortVideo/shortVideoDetail";
import { uploadImageApi } from "../../store/upload/uploadImage";
import RichTextEditor from "react-rte";
import { teachingVideoAddApi } from "../../store/teachingVideo/addTeachingVideo";
import { teachingVideoEditApi } from "../../store/teachingVideo/editTeachingVideo";
import { teachingVideoDetailsApi } from "../../store/teachingVideo/shortTeachingDetail";
import { classGlobalApi } from "../../store/class/classListGlobal";
import { subjectGlobalApi } from "../../store/subject/subjectListGlobal";
import { useTheme } from '@mui/material/styles';
import { topicsGlobalApi } from "../../store/topics/topicsListGlobal";
import BackdropUI from "../../ui/Backdrop";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


const schema = yup
  .object({
    title: yup.string().required("Please Provide Content Name").trim(),
    video_url: yup.string().required("Please Provide Video Link").trim(),
    duration: yup.string(),
    points: yup
      .number()
      .required("Please Provide Number Of Stars ")
      .min(1, "Minimum stars should be 1")
      .max(1000, "Max Stars Can Be 1000")
      .typeError("Please provide a number"),
    category: yup.string().required("Please Provide A Catergory "),
    is_free: yup.string().required("Please Select Option "),
    language: yup.string().required("Please Select Language "),
  })
  .required();

export default function AddTeachingVideo() {
  const theme = useTheme();
  const { result: classList, loading: classLoading } = useSelector((state) => state?.classGlobal);
  const { result: subjectList, loading: subjectLoading } = useSelector((state) => state?.subjectGlobal);
  const { result: topicsList, loading: topicsLoading } = useSelector((state) => state?.topicsGlobal);
  const [fileUploadLoader, setFileUploadLoader] = useState(false);

  const shortVideoEdit = useSelector((state) => state?.teachingVideoEdit);
  const shortVideoAdd = useSelector((state) => state?.teachingVideoAdd);
  const shortVideoDetail = useSelector((state) => state?.teachingVideoDetail);
  const uploadImage = useSelector((state) => state?.uploadImage);
  const { result, loiding } = shortVideoDetail;
  const {
    result: {
      title,
      video_url,
      mobile_number,
      duration: timeDuration,
      points,
      meta_tags,
      category,
    },
  } = shortVideoDetail;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });

  const [cat, setCat] = useState();
  const [meta, setMeta] = useState("");
  const [metaTag, setMetaTag] = useState([]);
  const [images, setImages] = React.useState([]);
  const [duration, setDuration] = useState('0:00');
  const [imageLink, setImageLink] = useState();
  const ref = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const [is_free, setFree] = useState();
  const [language, setLanguage] = useState();
  const [classValue, setClassValue] = useState();
  const [chooseSubjects, setChooseSubjects] = useState();
  const categories = useSelector((state) => state?.categories);
  const [valueDescription, setValueDescription] = useState("");
  const [editorValue, setEditorValue] = React.useState(
    RichTextEditor.createValueFromString('', "markdown")
  );
  useEffect(() => {
    dispatch(categoryApi());
    dispatch(classGlobalApi());
    dispatch(subjectGlobalApi());
    dispatch(topicsGlobalApi());
    if (params.id) {
      dispatch(teachingVideoDetailsApi(params.id));
    }
  }, [dispatch]);
  useEffect(() => {
    setDuration(timeDuration);
    setCat(shortVideoDetail.result?.category);
    setLanguage(shortVideoDetail.result?.language)
    setFree(shortVideoDetail.result?.is_free)
    setClassValue(shortVideoDetail.result?.teaching_class)
    setChooseSubjects(shortVideoDetail.result?.teaching_subjects)
    setImageLink(
      shortVideoDetail.result?.banner
    );
    if (meta_tags) {
      setMetaTag(meta_tags.split(","));
    }
    //console.log(duration);
  }, [shortVideoDetail]);

  const onUploadImage = async (imageList, addUpdateIndex) => {
    setFileUploadLoader(true)
    if (imageList.length > 0) {
      let formData = new FormData();

      formData.append("file", imageList[0]?.file);
      await dispatch(uploadImageApi(formData));
    }
    setImages(imageList);
    setFileUploadLoader(false)
  };

  const handleUpdateForm = async (data) => {
    var newObj;

    if (imageLink) {
      newObj = { ...data, banner: imageLink, description: valueDescription, teaching_class: data["classes"], teaching_subjects: data["subjects"] };
    } else if (images.length > 0 && !uploadImage?.loading && uploadImage.result) {
      newObj = { ...data, banner: uploadImage.result, description: valueDescription, teaching_class: data["classes"], teaching_subjects: data["subjects"] };
    } else {
      toast.error("Please Upload An Image", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      return;
    }
    if (metaTag.length < 5) {

      toast.error("Please provide 5 meta tags", {
        id: "toast",
        duration: 4000,
        position: "top-center",
      });
      return
    }
    newObj = { ...newObj, meta_tags: metaTag.join(","), duration: duration, description: valueDescription, teaching_topics: data?.topics };
    if (params.id) {
      newObj = { ...newObj, id: params.id };
      setFileUploadLoader(true)

      await dispatch(teachingVideoEditApi(newObj));
      if (!shortVideoEdit?.loading) {
        navigate("/teaching-videos");
        setFileUploadLoader(false)

      }
    } else {
      setFileUploadLoader(true)

      await dispatch(teachingVideoAddApi(newObj));
      if (!shortVideoAdd?.loading) {
        setFileUploadLoader(false)
        navigate("/teaching-videos");

      }
    }
    setFileUploadLoader(false)

  };

  const handleChipDelete = (element) => {
    //console.log(element);
    // metaTag.remove(value);
    var local = metaTag;
    setMetaTag(local.filter((item) => item !== element));
  };
  const handleChipAdd = (value) => {
    if (!metaTag.includes(ref?.current?.value.trim())) {
      setMetaTag([...metaTag, meta]);
      setMeta(null);
      ref.current.value = null;
    } else {
      toast.error("This Meta Tag Already Exists", {
        id: "toast",
        duration: 4000,
        position: "top-center",
      });
    }
    //console.log(ref);
  };

  useEffect(() => {
    let defalutValues = {};
    defalutValues["title"] = result?.title;
    defalutValues["video_url"] = result?.video_url;
    defalutValues["banner"] = result?.banner;
    defalutValues["points"] = result?.points;
    defalutValues["category"] = result?.category;
    defalutValues["language"] = result?.language;
    defalutValues["topics"] = result?.teaching_topics?.map(o => o?.id);
    defalutValues["is_free"] = result?.is_free;
    defalutValues["duration"] = result?.duration;
    defalutValues["subjects"] = result?.teaching_subjects?.map(o => o?.id);
    defalutValues["classes"] = result?.teaching_class?.map(o => o?.id);

    if (result?.description) {
      setEditorValue(
        RichTextEditor.createValueFromString(
          result?.description,
          "html"
        )
      );
    }
    reset(defalutValues);
  }, [result, classList, subjectList]);
  const getSecondsFromHHMMSS = (value) => {
    const [str1, str2, str3] = value.split(":");

    const val1 = Number(str1);
    const val2 = Number(str2);
    const val3 = Number(str3);

    if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
      return val1;
    }

    if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
      return val1 * 60 + val2;
    }

    if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
      return val1 * 60 * 60 + val2 * 60 + val3;
    }

    return 0;
  };
  const toHHMMSS = (secs) => {
    const secNum = parseInt(secs.toString(), 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor(secNum / 60) % 60;
    const seconds = secNum % 60;

    return [hours, minutes, seconds]
      .map((val) => (val < 10 ? `0${val}` : val))
      .filter((val, index) => val !== "00" || index > 0)
      .join(":")
      .replace(/^0/, "");
  };
  const handleDurtion = (e) => {
    //console.log(e.target.value)
    const value1 = e.target.value;
    const seconds = Math.max(0, getSecondsFromHHMMSS(value1));

    const time = toHHMMSS(seconds);

    setDuration(time);
  }
  const handleChange = (value) => {
    setEditorValue(value);
    setValueDescription(value.toString("html"));
  };
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>

                <Typography>Teaching Video Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />

                  {/* {accountToggle == "accountToggle" && */}
                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Title</InputLabel>
                        <OutlinedInput
                          {...register("title")}
                          label="title"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.title?.message}
                          defaultValue={result.title}
                        />
                        <FormHelperText error>
                          {errors?.title?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Video Link</InputLabel>
                        <OutlinedInput
                          {...register("video_url")}
                          label="Video Link"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.video_url?.message}
                          defaultValue={result.video_url}
                        />
                        <FormHelperText error>
                          {errors?.video_url?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink >Video Duration*</InputLabel>
                        <OutlinedInput
                          {...register("duration")}
                          label="Video Duration"
                          notched
                          id="outlined-adornment-email"
                          value={duration}
                          onChange={(e) => handleDurtion(e)}
                          error={errors?.duration?.message}
                        />
                        <FormHelperText error>
                          {errors?.duration?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Allocated Stars</InputLabel>
                        <OutlinedInput
                          {...register("points")}
                          label="Allocated Stars"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.points?.message}
                          defaultValue={result.points}
                        />
                        <FormHelperText error>
                          {errors?.points?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Category</InputLabel>
                        <Select
                          {...register("category")}
                          label="Category"
                          notched
                          id="outlined-adornment-category"
                          value={cat ? cat : null}
                          error={errors?.points?.message}
                          defaultValue={result.category}
                          onChange={(e) => setCat(e.target.value)}
                        >
                          {categories?.result?.map(({ id, title }, index) => (
                            <MenuItem value={title} key={`${index}-${id}`}>
                              {title}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error>
                          {errors?.category?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>Meta Tag</InputLabel>
                        <OutlinedInput
                          label="meta_tag"
                          id="outlined-adornment-email"
                          onChange={(e) => {
                            if (e.target.value.trim().length > 0) {
                              setMeta(e.target.value);
                            } else {
                              setMeta(null);
                              ref.current.value = null;
                            }
                          }}
                          defaultValue={meta}
                          inputRef={ref}
                          endAdornment={
                            <Button
                              variant="contained"
                              className="btn3"
                              disabled={
                                ref?.current?.value.trim().length > 0
                                  ? false
                                  : true
                              }
                              onClick={() => handleChipAdd()}
                            >
                              Add
                            </Button>
                          }
                        />
                        <FormHelperText error></FormHelperText>
                        <Stack
                          direction="row"
                          spacing={1}
                          style={{ display: " inline" }}
                        >
                          {metaTag.map((i, element) => (
                            <>
                              <Chip
                                label={i}
                                variant="outlined"
                                style={{ marginTop: "5px" }}
                                className=""
                                onDelete={() => handleChipDelete(i)}
                              />
                            </>
                          ))}
                        </Stack>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Subject
                        </InputLabel>
                        <Controller
                          name="subjects"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              multiple
                              value={watch('subjects', [])}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                // setValue('subjects', typeof value === 'string' ? value.split(',') : value);
                                field.onChange(e);
                              }}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={subjectList.find(obj => obj.id === value)?.title || ""} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {subjectList.map((obj) => (
                                <MenuItem
                                  key={obj.title}
                                  value={obj.id}
                                  style={getStyles(obj.title, watch('subjects', []), theme)}
                                >
                                  {obj.title}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.subjects && <span>{errors.subjects.message}</span>}
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Class
                        </InputLabel>
                        <Controller
                          name="classes"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              multiple
                              value={watch('classes', [])}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                // setValue('classes', typeof value === 'string' ? value.split(',') : value);
                                field.onChange(e);
                              }}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={classList.find(obj => obj.id === value)?.title || ""} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {classList.map((obj) => (
                                <MenuItem
                                  key={obj.title}
                                  value={obj.id}
                                  style={getStyles(obj.title, watch('classes', []), theme)}
                                >
                                  {obj.title}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.classes && <span>{errors.classes.message}</span>}
                        <FormHelperText error>
                          {errors?.classes?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Topics
                        </InputLabel>
                        <Controller
                          name="topics"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              multiple
                              value={watch('topics', [])}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                setValue('topics', typeof value === 'string' ? value.split(',') : value);
                                field.onChange(e);
                              }}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={topicsList.find(obj => obj.id === value)?.title || ""} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {topicsList.map((obj) => (
                                <MenuItem
                                  key={obj.title}
                                  value={obj.id}
                                  style={getStyles(obj.title, watch('topics', []), theme)}
                                >
                                  {obj.title}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        <FormHelperText error>
                          {errors?.topics?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Free
                        </InputLabel>
                        <Select
                          {...register("is_free")}
                          label="Free"
                          // notched={orderNum ? true : false}
                          notched
                          id="outlined-adornment-category"
                          // defaultValue={visible ? visible : null}
                          error={!!errors?.is_free?.message}
                          value={is_free != null ? is_free : null}
                          onChange={(e) => setFree(e.target.value)}
                        >
                          <MenuItem value={"true"}>True</MenuItem>
                          <MenuItem value={"false"}>False</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.is_free?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Language
                        </InputLabel>
                        <Select
                          {...register("language")}
                          label="Language"
                          notched
                          id="outlined-adornment-category"
                          error={!!errors?.language?.message}
                          value={language ? language : null}
                          onChange={(e) => setLanguage(e.target.value)}
                        >
                          <MenuItem value={"hindi"}>Hindi</MenuItem>
                          <MenuItem value={"english"}>English</MenuItem>
                          <MenuItem value={"both"}>Both</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.language?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>

                    <InputLabel>Thumbnail Image</InputLabel>
                    {imageLink ? (
                      <div className="image-item">
                        <img src={imageLink} alt="" width="320" height="180" />
                        <div className="image-item__btn-wrapper">
                          <Button
                            onClick={() => setImageLink(null)}
                            variant="contained"
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <ImageUploading

                        acceptType={['png', 'jpeg', 'jpg']}
                        value={images}
                        onChange={onUploadImage}
                        maxNumber={1}

                        dataURLKey="data_url"

                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                          errors,
                        }) => (
                          <>
                            <div className="upload__image-wrapper" style={{ marginTop: "2%", marginBottom: "2%" }}>
                              {imageList.length > 0 ? null : (
                                <Button
                                  type="button"
                                  variant="contained"
                                  style={
                                    isDragging ? { color: "red" } : undefined
                                  }
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  Click or Drop here
                                </Button>
                              )}

                              {errors && (
                                <div>
                                  {errors.maxNumber && (
                                    <span>
                                      Number of selected images exceed maxNumber
                                    </span>
                                  )}
                                  {errors.acceptType && (
                                    <span>
                                      Your selected file type is not allow
                                    </span>
                                  )}
                                  {errors.maxFileSize && (
                                    <span>
                                      Selected file size exceed maxFileSize
                                    </span>
                                  )}
                                  {errors.resolution && (
                                    <span>
                                      Selected file is not match your desired
                                      resolution
                                    </span>
                                  )}
                                </div>
                              )}
                              {imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                  <img
                                    src={image["data_url"]}
                                    alt=""
                                    width="320" height="180"
                                  />
                                  <div className="image-item__btn-wrapper">
                                    <Button
                                      onClick={() => onImageUpdate(index)}
                                      variant="contained"
                                      sx={{ mr: 2 }}
                                    >
                                      Update
                                    </Button>
                                    <Button
                                      onClick={() => onImageRemove(index)}
                                      variant="contained"
                                    >
                                      Remove
                                    </Button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </ImageUploading>
                    )}
                    {/* </Stack> */}
                  </>
                  {/* } */}
                </Stack>
              </Card>
              <Button
                type="button"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
      <BackdropUI open={fileUploadLoader} />
    </>
  );
}
