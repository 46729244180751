import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NodataImg from "../../assets/images/noData.png";
import { courseApi } from "../../store/courses/coursesList";
import { courseDeleteApi } from "../../store/courses/deleteCourse";

export default function PublishCourse({ open, setOpen, id, selectedRow }) {


  const coursesDelete = useSelector((state) => state?.coursesDelete);


  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleClose = () => setOpen(false);
  const deleteCourseConfirm = async () => {
    await dispatch(
      courseDeleteApi(selectedRow)
    );

    if (!coursesDelete?.loading) {
      dispatch(
        courseApi()
      );
      setOpen(false);
      navigate("/courses");
    }
  };
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card className="modal" sx={{ width: "30%" }}>
          <Stack alignItems="flex-end">
            <IconButton onClick={handleClose}>
              <HighlightOffIcon />
            </IconButton>
          </Stack>
          <Stack alignItems="center" my={2}>
            <Typography variant="h5" fontWeight={600}>
              Confirm {selectedRow?.published ? "Unpublish" : "Publish"} !!
            </Typography>
          </Stack>


          <Stack sx={{ mt: 2 }} justifyContent="center" direction="row">
            <Button variant="outlined" onClick={() => {
              deleteCourseConfirm()
            }} sx={{ mr: 2 }}>
              Yes
            </Button>
            <Stack direction="row" spacing={1}>
              <Button variant="contained" onClick={() =>
                setOpen(false)
              }>
                No
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>
    </>
  );
}

PublishCourse.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  selectedRow: PropTypes.any,
  id: PropTypes.any,
};
