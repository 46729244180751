import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import PropTypes from "prop-types";
import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import { teachingVideoApi } from "../../store/teachingVideo/teachingVideoList";
import { uploadBulkTeachingVideoApi } from "../../store/teachingVideo/bulkTeachingVideo";

export default function BulkTeachingVideo({ open, setOpen, id }) {
  const bulkPodcast = useSelector((state) => state?.uploadBulkTeachingVideo,
);
  const { result, loading } = bulkPodcast;
  const [selectedFile, setSelectedFile] = useState(null);
  const deletePodcast = useSelector((state) => state?.deletePodcast);

  const [uploadProgress, setUploadProgress] = useState(null);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
  };

  const uploadConfirm = async () => {
    console.log("data", selectedFile);
    let formData = new FormData();
    formData.append("file", selectedFile);
    await dispatch(uploadBulkTeachingVideoApi(formData));

    if (!bulkPodcast?.loading) {
      dispatch(teachingVideoApi());
      setOpen(false);
      setSelectedFile(null);
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card className="modal" sx={{ width: "30%" }}>
          <Stack alignItems="flex-end">
            <IconButton onClick={handleClose}>
              <HighlightOffIcon />
            </IconButton>
          </Stack>
          <Stack alignItems="center" my={2}>
            <Typography variant="h5" fontWeight={600}>
              Please Select a file to upload ?
            </Typography>
          </Stack>
          <Stack alignItems="center" my={2}>
            {selectedFile ? (
              <Typography variant="h5" fontWeight={600}>
                {selectedFile.name}
                <IconButton onClick={() => setSelectedFile(null)}>
                  <ClearIcon />
                </IconButton>
              </Typography>
            ) : (
              <>
                <input
                  accept=".csv,.xlxs,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  ref={ref}
                  onChange={(e) => setSelectedFile(e.target?.files[0])}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => ref.current.click()}
                >
                  Upload File
                </Button>
              </>
            )}
            {loading ? (
              <Box sx={{ width: "60%" }}>
                <LinearProgress />
              </Box>
            ) : null}
          </Stack>

          <Stack sx={{ mt: 2 }} justifyContent="center" direction="row">
            <Button
              variant="outlined"
              onClick={() => handleClose()}
              sx={{ mr: 2 }}
            >
              Back
            </Button>
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                onClick={() => uploadConfirm()}
                disabled={!selectedFile ? true : false}
              >
                Confirm
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>
    </>
  );
}

BulkTeachingVideo.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  id: PropTypes.any,
};
