import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const getCourseSectionApi = createAsyncThunk(
    'courses/get-section',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.courseSecAction(`${data.id}`, 0))
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const intialState = { result: [], loading: false, error: null, message: "" }
const getCourseSectionSlice = createSlice({
    name: 'get-section',
    initialState: intialState,
    reducers: {
        resetSection: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getCourseSectionApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getCourseSectionApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(getCourseSectionApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { resetSection } = getCourseSectionSlice.actions;
export const getCourseSectionReducer = getCourseSectionSlice.reducer