import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  Box,
  Chip
} from "@mui/material";
import dayjs from "dayjs";
import { useParams, useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ImageUploading from "react-images-uploading";
import ImageUploader from 'react-images-uploading';
import DefaultProfileImg from "../../assets/images/defaultProfileImg.jpg";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Drawer from "../../components/Drawer";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Style from "../../styles/profile.module.scss";
import { TimeField } from "@mui/x-date-pickers";
import { toast } from "react-hot-toast";
import { uploadImageApi } from "../../store/upload/uploadImage";
import { quizAddApi } from "../../store/quizzes/addQuiz";
import { quizEditApi } from "../../store/quizzes/editQuiz";
import { quizDetailsApi } from "../../store/quizzes/quizDetail";
import { classGlobalApi } from "../../store/class/classListGlobal";
import { subjectGlobalApi } from "../../store/subject/subjectListGlobal";
import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const schema = yup
  .object({
    title: yup.string().required("Please Provide title").trim(),
    instructions: yup.string().required("Please Provide instructions").trim(),
    description: yup.string().required("Please Provide description").trim(),
    duration: yup.string().required("Please Provide Duration").trim(),
    language: yup.string().required("Please Select Language "),
    allocated_stars: yup
      .number()
      .required("Please Provide Number Of Stars ")
      .min(1, "Minimum stars should be 1")
      .max(1000, "Max Stars Can Be 1000")
      .typeError("Please provide a number"),
    // date: yup.string().required("Please Provide Date "),

    // password: yup.string(),
  })
  .required();

export default function AddQuiz() {
  const theme = useTheme();
  const { result: classList, loading: classLoading } = useSelector((state) => state?.classGlobal);
  const { result: subjectList, loading: subjectLoading } = useSelector((state) => state?.subjectGlobal);
  const quizEdit = useSelector((state) => state?.quizEdit);
  const quizAdd = useSelector((state) => state?.quizAdd);
  const quizDetails = useSelector((state) => state?.quizDetails);
  const uploadImage = useSelector((state) => state?.uploadImage);
  const [images, setImages] = React.useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [imageLink, setImageLink] = useState();
  const [questionBlocks, setQuestionBlocks] = useState([]);
  const ref = useRef(null);
  const [timeValue, setTimeValue] = useState('0:00');
  const { result, loiding } = quizDetails;
  const [language, setLanguage] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const {
    result: {
      title,
      description,
      instructions,
      allocated_stars,
    },
  } = quizDetails;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    control
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });

  const onUploadImage = async (imageList, addUpdateIndex) => {
    if (imageList.length > 0) {
      let formData = new FormData();
      formData.append("file", imageList[0]?.file);
      dispatch(uploadImageApi(formData)).then((action) => {
        imageList[0].resourceurl = action.payload.result
        setImages(imageList);
      })
    }

  };

  const handleUpdateForm = async (data) => {
    let payload;
    if (imageLink) {
      payload = { ...data, banner: imageLink };
    }
    else if (images.length > 0 && !uploadImage?.loading && uploadImage.result) {
      payload = { ...data, banner: images[0].resourceurl };
    }
    else {
      toast.error("Please Upload An Image", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      return;
    }

    if (startDate) {
      payload = { ...payload, start_date: startDate };
    }
    else {
      toast.error("Please select start date", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      return;
    }

    if (endDate) {
      payload = { ...payload, end_date: endDate };
    }
    else {
      toast.error("Please select end date", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      return;
    }

    if (params.id) {
      payload = { ...payload, id: params.id };
      const res = await dispatch(quizEditApi(payload));
      if (!quizEdit?.loading) {
        if (res?.payload?.error === false) {
          navigate("/quizzes");
        }
      }
    } else {
      const isValid = isAllQuestionBlocksValid()
      if (isValid && questionBlocks.length > 0) {
        payload = { ...payload, qns_ans: questionBlocks }
        payload = { ...payload, question_count: questionBlocks.length }
        if (!quizAdd?.loading) {
          const res = await dispatch(quizAddApi(payload));
          if (res?.payload?.error === false) {
            navigate("/quizzes");
          }
        }
      }
      else {
        toast.error("Please fill out all the details of question section", {
          id: "toast",
          duration: 1000,
          position: "top-center",
        });
        return;
      }
    }
  };
  useEffect(() => {
    dispatch(classGlobalApi());
    dispatch(subjectGlobalApi());
    if (params.id && result) {
      dispatch(quizDetailsApi(params.id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      setStartDate(result.start_date);
      setEndDate(result.end_date);
      setQuestionBlocks(result.questions);
      setLanguage(result?.language)
      setImageLink(
        result?.banner
      );
    }
  }, [quizDetails]);

  useEffect(() => {
    let defalutValues = {};
    defalutValues["title"] = result?.title;
    defalutValues["allocated_stars"] = result?.allocated_stars;
    defalutValues["duration"] = result?.duration;
    defalutValues["instructions"] = result?.instructions;
    defalutValues["description"] = result?.description;
    defalutValues["banner"] = result?.banner;
    defalutValues["subjects"] = result?.subjects?.map(o => o?.id);
    defalutValues["classes"] = result?.classes?.map(o => o?.id);
    reset(defalutValues);
  }, [result]);

  const getSecondsFromHHMMSS = (value) => {
    const [str1, str2, str3] = value.split(":");

    const val1 = Number(str1);
    const val2 = Number(str2);
    const val3 = Number(str3);

    if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
      return val1;
    }

    if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
      return val1 * 60 + val2;
    }

    if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
      return val1 * 60 * 60 + val2 * 60 + val3;
    }

    return 0;
  };

  const toHHMMSS = (secs) => {
    const secNum = parseInt(secs.toString(), 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor(secNum / 60) % 60;
    const seconds = secNum % 60;

    return [hours, minutes, seconds]
      .map((val) => (val < 10 ? `0${val}` : val))
      .filter((val, index) => val !== "00" || index > 0)
      .join(":")
      .replace(/^0/, "");
  };

  const handleDurtion = (e) => {
    const value1 = e.target.value;
    const seconds = Math.max(0, getSecondsFromHHMMSS(value1));
    const time = toHHMMSS(seconds);
    setTimeValue(time);
  }

  // Logic for Adding New Questions

  const addQuestion = () => {

    const isValid = isAllQuestionBlocksValid()
    if (isValid) {
      setQuestionBlocks([...questionBlocks, { ans_options: ['', '', '', ''], correct_answer: '', image: '' }]);
    }
    else {
      toast.error("Please fill out all the details of current question", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
    }
  };

  const handleQuestionChange = (index, event) => {
    const updatedQuestionBlocks = [...questionBlocks];
    updatedQuestionBlocks[index].question = event.target.value;
    setQuestionBlocks(updatedQuestionBlocks);
  };


  // const handleQuestionImageChange = (index) => async(imageList, addUpdateIndex) => {
  //   if (imageList.length > 0) {
  //     let formData = new FormData();
  //     // console.log(imageList, imageList);
  //     formData.append("file", imageList[0]?.file);
  //     const res = await dispatch(uploadImageApi(formData));
  //     const updatedQuestionBlocks = [...questionBlocks];
  //     updatedQuestionBlocks[index].image = res.payload.result;
  //     setQuestionBlocks(updatedQuestionBlocks);
  //     console.log(imageList,"Dd" ,index)
  //   }
  //   // setImages(imageList);
  // };

  const handleImageUpload = async (index, imageList) => {
    let formData = new FormData();
    // console.log(imageList, imageList);
    formData.append("file", imageList[0]?.file);
    const res = await dispatch(uploadImageApi(formData));
    const updatedQuestionBlocks = [...questionBlocks];
    updatedQuestionBlocks[index].image = res?.payload?.result;
    setQuestionBlocks(updatedQuestionBlocks);
  };

  const handleImageRemove = (index) => {
    const updatedQuestionBlocks = [...questionBlocks];
    updatedQuestionBlocks[index].image = "";
    setQuestionBlocks(updatedQuestionBlocks);
  };

  const handleOptionChange = (questionIndex, optionIndex, event) => {
    const updatedQuestionBlocks = [...questionBlocks];
    updatedQuestionBlocks[questionIndex].ans_options[optionIndex] = event.target.value;
    setQuestionBlocks(updatedQuestionBlocks);
  };

  const handleCorrectAnswerChange = (questionIndex, event) => {
    const updatedQuestionBlocks = [...questionBlocks];
    updatedQuestionBlocks[questionIndex].correct_answer = event.target.value;
    setQuestionBlocks(updatedQuestionBlocks);
  };

  const removeQuestion = (index) => {
    const updatedQuestionBlocks = [...questionBlocks];
    updatedQuestionBlocks.splice(index, 1);
    setQuestionBlocks(updatedQuestionBlocks);
  }

  const isQuestionBlockValid = (questionBlock) => {
    if (questionBlock.question) {
      const updatedQuestionBlocks = [...questionBlocks];
      let index = updatedQuestionBlocks.findIndex(ques => ques.question === questionBlock.question)
      updatedQuestionBlocks[index].ans_options = updatedQuestionBlocks[index].ans_options.map(a => a.trim())
      updatedQuestionBlocks[index].correct_answer = updatedQuestionBlocks[index].correct_answer.trim()
      setQuestionBlocks(updatedQuestionBlocks)
      return questionBlock.question.trim() !== '' && questionBlock.ans_options.every(option => option.trim() !== '') && questionBlock.correct_answer.trim() !== '';
    }
    else return false
  };

  const isAllQuestionBlocksValid = () => {
    return questionBlocks.every(isQuestionBlockValid);
  };

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Stack spacing={2} my={2}>
                  {/* {accountToggle == "accountToggle" && */}
                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Quiz Title*</InputLabel>
                        <OutlinedInput
                          {...register("title")}
                          label="Quiz Title*"
                          notched
                          error={errors?.title?.message}
                          defaultValue={title}
                        />
                        <FormHelperText error>
                          {errors?.title?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Quiz Duration*</InputLabel>
                        <OutlinedInput
                          {...register("duration")}
                          label="Quiz Duration"
                          name="duration"
                          id="outlined-adornment-email"
                          notched
                          value={timeValue}
                          onChange={(e) => handleDurtion(e)}
                          error={errors?.duration?.message}
                        />
                        <FormHelperText error>
                          {errors?.duration?.message}
                        </FormHelperText>

                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Quiz Description*</InputLabel>
                        <OutlinedInput
                          {...register("description")}
                          label="Quiz Description*"
                          notched
                          multiline
                          error={errors?.description?.message}
                          defaultValue={description}
                        />
                        <FormHelperText error>
                          {errors?.description?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Language
                        </InputLabel>
                        <Select
                          {...register("language")}
                          label="Language"
                          notched
                          id="outlined-adornment-category"
                          error={!!errors?.language?.message}
                          value={language ? language : null}
                          onChange={(e) => setLanguage(e.target.value)}
                        >
                          <MenuItem value={"hindi"}>Hindi</MenuItem>
                          <MenuItem value={"english"}>English</MenuItem>
                          <MenuItem value={"both"}>Both</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.language?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Allocated Stars*</InputLabel>
                        <OutlinedInput
                          {...register("allocated_stars")}
                          label="Allocated Stars*"
                          notched
                          error={errors?.allocated_stars?.message}
                          defaultValue={allocated_stars}
                        />
                        <FormHelperText error>
                          {errors?.allocated_stars?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Subject
                        </InputLabel>
                        <Controller
                          name="subjects"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              multiple
                              value={watch('subjects', [])}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                setValue('subjects', typeof value === 'string' ? value.split(',') : value);
                                field.onChange(e);
                              }}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={subjectList.find(obj => obj.id === value)?.title || ""} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {subjectList.map((obj) => (
                                <MenuItem
                                  key={obj.title}
                                  value={obj.id}
                                  style={getStyles(obj.title, watch('subjects', []), theme)}
                                >
                                  {obj.title}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.subjects && <span>{errors.subjects.message}</span>}
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Class
                        </InputLabel>
                        <Controller
                          name="classes"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              multiple
                              value={watch('classes', [])}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                setValue('classes', typeof value === 'string' ? value.split(',') : value);
                                field.onChange(e);
                              }}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={classList.find(obj => obj.id === value)?.title || ""} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {classList.map((obj) => (
                                <MenuItem
                                  key={obj.title}
                                  value={obj.id}
                                  style={getStyles(obj.title, watch('classes', []), theme)}
                                >
                                  {obj.title}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.classes && <span>{errors.classes.message}</span>}
                        <FormHelperText error>
                          {errors?.classes?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        {/* <InputLabel >
                        Date & Time
                        </InputLabel> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            {...register("start_date")}
                            label="Start Date"
                            name="date"
                            disabled={params.id && new Date().toJSON() > result?.start_date ? true : false}
                            error={errors?.start_date?.message}
                            value={startDate ? dayjs(startDate) : null}
                            onChange={(newValue) => {
                              setStartDate(dayjs(newValue).toJSON());
                            }}
                          />
                        </LocalizationProvider>
                        <FormHelperText error>
                          {errors?.start_date?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        {/* <InputLabel >
                        Date & Time
                        </InputLabel> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            {...register("end_date")}
                            label="End Date"
                            name="date"
                            error={errors?.end_date?.message}
                            value={endDate ? dayjs(endDate) : null}
                            onChange={(newValue) => {
                              setEndDate(dayjs(newValue).toJSON());
                            }}
                          />
                        </LocalizationProvider>
                        <FormHelperText error>
                          {errors?.end_date?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  </>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel shrink>Instructions*</InputLabel>
                    <OutlinedInput
                      {...register("instructions")}
                      label="Instructions*"
                      notched
                      multiline
                      error={errors?.instructions?.message}
                      defaultValue={instructions}
                    />
                    <FormHelperText error>
                      {errors?.instructions?.message}
                    </FormHelperText>
                  </FormControl>
                </Stack>
                {!params.id ?
                  <Stack direction="row" spacing={2} marginBottom={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <Button variant="contained" color="primary" onClick={addQuestion}>Add Question</Button>
                    <InputLabel variant="body2">Questions Count : {questionBlocks.length}</InputLabel>
                  </Stack>
                  : <InputLabel variant="body2">Questions Count : {questionBlocks?.length}</InputLabel>
                }

                {questionBlocks?.map((questionBlock, index) => (
                  <Card sx={{ background: '#e1f2f2', mt: 2, mb: 3, py: 2 }} key={index}>
                    <Stack direction="row" spacing={2} marginBottom={1}>
                      <FormControl variant="outlined" fullWidth >
                        <TextField
                          disabled={params.id}
                          label="Enter Question"
                          value={questionBlock.question}
                          onChange={(e) => handleQuestionChange(index, e)}
                        />
                      </FormControl>
                    </Stack>

                    <Stack direction="row" spacing={2} marginBottom={1}>
                      {params.id ?
                        <div style={{ margin: "10px 0" }}>
                          <Stack spacing={2} marginBottom={1}>
                            <img src={questionBlock.image} alt="" style={{ maxWidth: '230px', maxHeight: '115px' }} />
                          </Stack>
                        </div> :
                        <ImageUploading
                          multiple={false}
                          value={questionBlock.image ? [questionBlock.image] : []}
                          onChange={(imageList, addUpdateIndex) => handleImageUpload(index, imageList)}
                          maxFileSize={1024 * 1024}
                        >
                          {({ imageList, onImageUpload, onImageRemoveAll }) => (
                            <div style={{ margin: "10px 0" }}>
                              {imageList.length === 0 ? <Button variant="contained" color="primary" onClick={onImageUpload}>Add Image</Button> : null}
                              {imageList.map((image, imageIndex) => (
                                <Stack spacing={2} marginBottom={1} key={imageIndex}>
                                  <img src={image} alt="" style={{ maxWidth: '230px', maxHeight: '115px' }} />
                                  {!params.id && <Button variant="contained" color="error" onClick={() => handleImageRemove(index)}>Remove Image</Button>}
                                </Stack>
                              ))}
                            </div>
                          )}
                        </ImageUploading>
                      }
                    </Stack>

                    <Stack direction="row" spacing={2} marginBottom={1}>
                      {questionBlock?.ans_options.map((option, optionIndex) => (
                        // <div key={optionIndex}>
                        <FormControl variant="outlined" fullWidth key={optionIndex}>
                          <TextField
                            notched
                            label={`Option ${optionIndex + 1}`}
                            value={option}
                            disabled={params.id}
                            // multiline
                            onChange={(e) => handleOptionChange(index, optionIndex, e)}
                          />
                        </FormControl>
                        // </div>
                      ))}
                    </Stack>
                    <Stack direction="row" spacing={2} marginY={2}>
                      {params.id ?
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            label="Correct Answer"
                            notched
                            value={questionBlock?.correct_answer}
                            disabled
                          />
                        </FormControl>
                        :
                        <FormControl fullWidth>
                          <InputLabel disabled={params.id} shrink>Correct Answer</InputLabel>
                          <Select
                            label="Correct Answer"
                            notched
                            disabled={params.id}
                            value={questionBlock?.correct_answer}
                            onChange={(e) => handleCorrectAnswerChange(index, e)}
                          >
                            {questionBlock.ans_options.map((option, optionIndex) => (
                              <MenuItem key={optionIndex} value={option}>{`Option ${optionIndex + 1}`}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      }
                    </Stack>
                    {!params.id ?
                      <FormControl>
                        <Button variant="contained" color="error" onClick={() => removeQuestion(index)}>Remove Question</Button>
                      </FormControl>
                      : <></>
                    }
                  </Card>
                ))}

                {/* <Stack direction="row" spacing={2}> */}
                <InputLabel>Quiz Image*</InputLabel>
                {imageLink ? (
                  <div className="image-item">
                    <img src={imageLink} alt="" width="330" height="150" />
                    <div className="image-item__btn-wrapper">
                      <Button
                        onClick={() => setImageLink(null)}
                        variant="contained"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                ) : (
                  <ImageUploading
                    // multiple
                    value={images}
                    onChange={onUploadImage}
                    maxNumber={1}
                    resolutionType="ratio"
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                      errors,
                    }) => (
                      <>
                        <div className="upload__image-wrapper" style={{ marginTop: "2%", marginBottom: "2%" }}>
                          {imageList.length > 0 ? null : (
                            <Button
                              type="button"
                              variant="contained"
                              style={isDragging ? { color: "red" } : undefined}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Click or Drop here
                            </Button>
                          )}

                          {errors && (
                            <div>
                              {errors.maxNumber && (
                                <span>
                                  Number of selected images exceed maxNumber
                                </span>
                              )}
                              {errors.acceptType && (
                                <span>
                                  Your selected file type is not allow
                                </span>
                              )}
                              {errors.maxFileSize && (
                                <span>
                                  Selected file size exceed maxFileSize
                                </span>
                              )}
                              {errors.resolution && (
                                <span>
                                  Selected file is not match your desired
                                  resolution
                                </span>
                              )}
                            </div>
                          )}
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img
                                src={image["data_url"]}
                                alt=""
                                width="330"
                                height="150"
                              />
                              <div className="image-item__btn-wrapper">
                                <Button
                                  onClick={() => onImageUpdate(index)}
                                  variant="contained"
                                  sx={{ mr: 2 }}
                                >
                                  Update
                                </Button>
                                <Button
                                  onClick={() => onImageRemove(index)}
                                  variant="contained"
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </ImageUploading>
                )}
                {/* </Stack> */}
              </Card>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
    </>
  );
}
