import { Box, Card, Stack, Typography } from "@mui/material";
import { HiTemplate } from "react-icons/hi";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { getUnitListApi } from "../../store/courses/unit/getUnitList";
import { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NodataImg from "../../assets/images/noData.png";



export default function UnitList() {
    const getUnitListData = useSelector((state) => state?.getUnitList);
    const { result, loading } = getUnitListData;

    const location = useLocation();
    const dispatch = useDispatch();

    const course_id = new URLSearchParams(location.search).get('course_id');
    const unit_block_id = new URLSearchParams(location.search).get('unit_block_id');

    useEffect(() => {
        dispatch(getUnitListApi({ course_id: course_id.replaceAll(" ", "+"), unit_block_id }))
    }, [dispatch])


    return (
        <>
            <Appbar />
            <Drawer />
            <Body>
                <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
                    <HiTemplate size={30} />
                    <Typography>Units List</Typography>
                </Stack>

                {result?.length
                    ? result?.map((item, idx) => {
                        return (
                            <Fragment key={idx}>
                                <Card my={2}>
                                    <Typography variant="h5">Title : {item?.display_name}</Typography>
                                    <Typography variant="h5">URL : {item?.youtube_url}</Typography>
                                </Card>
                            </Fragment>
                        )
                    })
                    : < Box className="noData">
                        <img src={NodataImg} alt="no data found" />
                    </Box>}
            </Body >
        </>
    )
}


