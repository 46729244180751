import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const entryExitDeleteApi = createAsyncThunk(
    'auth/entryExits/delete',
    async (id, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.delete(apiRoutes.entryExitAction(`${id}`, 1))
            return response.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const entryExitDeleteSlice = createSlice({
    name: 'entryExit delete',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(entryExitDeleteApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(entryExitDeleteApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(entryExitDeleteApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const entryExitDeleteReducer = entryExitDeleteSlice.reducer