import {  Button, Grid,  Stack,  Typography } from '@mui/material'
import { default as React, useEffect } from 'react'
import { AiOutlineDashboard } from "react-icons/ai"
import { GoPackageDependents } from "react-icons/go"
import { useNavigate } from 'react-router-dom'
import Appbar from '../../components/Appbar'
import Body from '../../components/Body'
import Drawer from '../../components/Drawer'
import ReportSection from './ReportSection'
import NotificationSection from './notifications/NotificationSection'
import UserSection from './cards/user'


export default function Home() {

  const navigate = useNavigate()

  const handleOrderNow = () => {
    navigate('/courses')
  }

  useEffect(() => {
  }, [])

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>

        <Grid container spacing={2}>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Stack spacing={2}>
              <Grid container alignItems='center'>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <AiOutlineDashboard size={30} />
                    <Typography>Dashboard</Typography>
                  </Stack>
                </Grid>
              </Grid>
              {/* <DealorShip /> */}
              <NotificationSection />
              <UserSection />
              {/* <OrderSections /> */}
            </Stack>
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Stack spacing={2}>
              <Button startIcon={<GoPackageDependents />} size='medium' onClick={handleOrderNow} variant='outlined' sx={{ bgcolor: '#fff' }}>Go To Course</Button>
              <ReportSection />
            </Stack>
          </Grid>
        </Grid>
      </Body>
    </>
  )
}
