import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  Tabs,
  Tab,
  Card,
  Grid,
  CardActions,
  CardContent,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
  OutlinedInput,
  Chip,
  Select
} from "@mui/material";
import { DataGrid, getGridStringOperators, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { useNavigate, useParams } from "react-router-dom";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { resetfaq } from "../../store/faq/faqDetails";
import { analiticsFilterApi } from "../../store/analytics/filters";
import { analyticsGraphApi } from "../../store/analytics/analyticsGraphDetail";
import { analyticsTableApi } from "../../store/analytics/analyticsList";
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import HealthCheck from "./HealthCheck";
import ContentConsumptionNonGraded from "./ContentConsumptionNonGraded";
import ContentConsumptionGraded from "./ContentConsumptionGraded";
import CommunityEngagement from "./CommunityEngagement";
import AppErrors from "./AppErrors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { classGlobalApi } from "../../store/class/classListGlobal";
import { subjectGlobalApi } from "../../store/subject/subjectListGlobal";
import apiRoutes from "../../store/apiConfigs";

const data = [
  {
    name: "January",
    uv: 4000,
    pv: 2400,
    nv: 2400,
    amt: 2400,
  },
  {
    name: "Fabruary",
    uv: 3000,
    pv: 1398,
    nv: 2400,
    amt: 2210,
  },
  {
    name: "March",
    uv: 2000,
    pv: 9800,
    nv: 4040,
    amt: 2290,
  },
  {
    name: "April",
    uv: 2780,
    pv: 3908,
    nv: 2400,
    amt: 2000,
  },
  {
    name: "May",
    uv: 1890,
    pv: 4800,
    nv: 2400,
    amt: 2181,
  },
  {
    name: "June",
    uv: 2390,
    pv: 3800,
    nv: 9090,
    amt: 2500,
  },
  {
    name: "July",
    uv: 3490,
    pv: 4300,
    nv: 2400,
    amt: 2100,
  },
  {
    name: "august",
    uv: 3490,
    pv: 4300,
    nv: 2400,
    amt: 2100,
  }, {
    name: "September",
    uv: 3490,
    pv: 4300,
    nv: 2400,
    amt: 2100,
  }, {
    name: "October",
    uv: 3490,
    pv: 4300,
    nv: 2400,
    amt: 2100,
  }, {
    name: "November",
    uv: 3490,
    pv: 4300,
    nv: 2400,
    amt: 2100,
  }, {
    name: "December",
    uv: 3490,
    pv: 4300,
    nv: 2400,
    amt: 2100,
  },
];
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const modules = [
  { name: "Learning Material", value: "learningmaterial" },
  { name: "Teaching Videos", value: "teachingvideos" },
  { name: "Project Based", value: "projectbased" },
  { name: "Entry Exit Question", value: "entryexitquestions" },
  { name: "Homework", value: "homework" },
]
const nonGradedModules = [
  { name: "Impact Stories", value: "impact_stories" },
  { name: "PodCast", value: "podcast" },
  { name: "Sunday Polls", value: "sunday_polls" },
  { name: "Short Videos", value: "short_videos" },
  { name: "Quiz", value: "quiz" },
  { name: "Webinar", value: "webinar" },
  { name: "School Processes", value: "school_process" },
  { name: "Courses", value: "course" },
  { name: "Teaching Videos", value: "teaching_videos" },
  { name: "Learning material", value: "learning_material" },
  { name: "Project Based", value: "project_based" },
  { name: "Entry Exit", value: "entry_exit_questions" },
  { name: "Home Work", value: "home_work" },
]
const communityModules = [
  { name: "Impact Stories", value: "impact_stories" },
  { name: "PodCast", value: "podcast" },
  { name: "Sunday Polls", value: "sunday_polls" },
  { name: "Short Videos", value: "short_videos" },
  { name: "Quiz", value: "quiz" },
  { name: "Webinar", value: "webinar" },
  { name: "School Processes", value: "school_process" },
  { name: "Courses", value: "course" },
  { name: "Teaching Videos", value: "teaching_videos" },
  { name: "Learning material", value: "learning_material" },
  { name: "Project Based", value: "project_based" },
  { name: "Entry Exit", value: "entry_exit_questions" },
  { name: "Home Work", value: "home_work" },
]
const reportTypes = [
  { name: "User report", value: "user_report" },
  { name: "Content report", value: "content_report" },
  { name: "Content based user report", value: "user_content_report" },
]
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AnalyticsListing() {
  const analyticsFilter = useSelector((state) => state?.analyticsFilter);
  const { result: filterResult, filterLoading } = analyticsFilter;
  const analyticsGraph = useSelector((state) => state?.analyticsGraph);
  const { result: graphResult, graphLoading } = analyticsGraph;

  const [error, setError] = useState(null);
  const { result: classList, loading: classLoading } = useSelector((state) => state?.classGlobal);
  const { result: subjectList, loading: subjectLoading } = useSelector((state) => state?.subjectGlobal);

  const schema = yup
    .object({})
    .required();
  const schemaEdit = yup
    .object({})
    .required();
  const params = useParams();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(params.id ? schemaEdit : schema),
    defaultValues: {},
  });
  const [value, setValue] = useState(0);
  const [cat, setCat] = useState();
  const [classs, setClasss] = useState();
  const [subject, setSubject] = useState();
  const [state, setState] = useState();
  const [module, setModule] = useState("learningmaterial");
  const [nonGradedModule, setNonGradedModule] = useState("short_videos");
  const [communityModule, setCommunityModule] = useState("short_videos");
  const [isDownload, setIsDownload] = useState(false)
  const [reportType, setReportType] = useState("user_report");
  const [monthDaily, setMonthDaily] = useState("monthly");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [region, setRegion] = useState();
  const categories = useSelector((state) => state?.categories);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (value === 3) {
      dispatch(analiticsFilterApi({ type: "city" }));
    } else {
      dispatch(analiticsFilterApi({
        type: "filters", params: {
          start_date: startDate,
          end_date: endDate
        }
      }));

    }
  }, [value])

  useEffect(() => {
    switch (value) {
      case 0:
        dispatch(analyticsGraphApi({
          type: "health",
          params: {
            analytics_type: "health_check",
            city: region,
            region: state,
            frequency: monthDaily,
            start_date: startDate,
            end_date: endDate
          }
        }));
        break;
      case 1:
        // dispatch(analyticsGraphApi({
        //   type: "non-graded",
        //   params: {
        //     frequency: monthDaily,
        //     module: nonGradedModule,
        //     class: classs,
        //     subject: subject,
        //     start_date: startDate,
        //     end_date: endDate
        //   }
        // }));
        break;
      case 2:
        dispatch(analyticsGraphApi({
          type: "health",
          params: {
            analytics_type: "content_consumption_graded",
            module: module,
            class: classs,
            subject: subject,
            start_date: startDate,
            end_date: endDate
          }
        }));
        break;
      case 3:
        dispatch(analyticsGraphApi({
          type: "community",
          params: {
            analytics_type: "google_analytics",
            module: communityModule,
            city: region,
            region: state,
            frequency: monthDaily,
            start_date: startDate,
            end_date: endDate
          }
        }));
        break;
      default:
        dispatch(analyticsGraphApi({
          type: "health",
          params: {
            analytics_type: "health_check",
            city: region,
            region: state,
            frequency: monthDaily
          }
        }));
        dispatch(analyticsTableApi({
          type: "health",
          params: {
            analytics_type: "registration_stats",
            city: region,
            region: state,
            frequency: monthDaily
          }
        }));
    }

  }, [dispatch,
    cat,
    state,
    monthDaily,
    region,
    value,
    module,
    classs,
    subject,
    startDate,
    endDate,
    nonGradedModule,
    communityModule
  ]);
  const handleClear = async () => {
    setClasss()
    setSubject()
    setState()
    setModule("learningmaterial")
    setMonthDaily("monthly")
    setStartDate()
    setEndDate()
    setRegion()
    setCommunityModule("short_videos")
    setNonGradedModule("impact_stories")
  };
  const handleApiCall = async () => {
    try {
      let query = {
        report: reportType
      };
      const params = new URLSearchParams(query);
      const queryString = params.toString();
      let apiUrl = `${process.env.REACT_APP_BASE_URL}${apiRoutes.adminAction("report-download", 1)}?${queryString}`
      setIsDownload(true)
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        setIsDownload(false)
        throw new Error('Network response was not ok');
      }

      const blob = await response.json();
      // Create a URL for the file
      const url = blob.result;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `batchreport_${new Date().getTime()}.csv`); // Specify the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsDownload(false)
    } catch (error) {
      console.error('Error exporting data:', error);
      setIsDownload(false)
      alert('Failed to export data.');
    }
  };
  useEffect(() => {
    dispatch(classGlobalApi());
    dispatch(subjectGlobalApi());
  }, [dispatch])
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>

        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Analytics Dashboard</Typography>
        </Stack>

        <Paper elevation={0}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="analytics tabs" variant="scrollable" scrollButtons
                allowScrollButtonsMobile >
                <Tab label="Health Check" {...a11yProps(0)} value={0} />
                <Tab label="Content Consumption" {...a11yProps(1)} value={1} />
                <Tab label="Community Engagement" {...a11yProps(2)} value={2} />
                <Tab label="Batch Reports" {...a11yProps(4)} value={4} />
                {/* <Tab label="App Errors" {...a11yProps(3)} /> */}
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <form id="outer_form_health_check">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel shrink>Monthly/Daily</InputLabel>
                          <Select
                            {...register("monthDaily")}
                            label="Monthly/Daily"
                            id="outlined-adornment-monthDaily"
                            value={monthDaily ? monthDaily : null}
                            notched
                            error={errors?.monthDaily?.message}
                            onChange={(e) => setMonthDaily(e.target.value)}
                          >
                            {["monthly", "daily"]?.map((title, index) => (
                              <MenuItem value={title} key={index} sx={{ textTransform: "capitalize" }}>
                                {title}
                              </MenuItem>
                            ))}
                          </Select>

                          <FormHelperText error>
                            {errors?.monthDaily?.message}
                          </FormHelperText>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel shrink>State</InputLabel>
                          <Select
                            {...register("state")}
                            label="State"
                            id="outlined-adornment-state"
                            value={state ? state : null}
                            notched
                            error={errors?.state?.message}
                            onChange={(e) => setState(e.target.value)}
                          >
                            {Object.keys(filterResult)?.map((title, index) => (
                              <MenuItem value={title} key={index}>
                                {title}
                              </MenuItem>
                            ))}
                          </Select>

                          <FormHelperText error>
                            {errors?.state?.message}
                          </FormHelperText>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel shrink>Region*</InputLabel>
                          <Select
                            {...register("region")}
                            label="Category"
                            id="outlined-adornment-category"
                            value={region ? region : null}
                            notched
                            error={errors?.region?.message}
                            onChange={(e) => setRegion(e.target.value)}
                          >
                            {state && Object.values(filterResult[state]).sort()?.map((title, index) => (
                              <MenuItem value={title} key={index}>
                                {title}
                              </MenuItem>
                            ))}
                          </Select>

                          <FormHelperText error>
                            {errors?.region?.message}
                          </FormHelperText>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              {...register("startTime")}
                              label="From*"
                              name="startTime"
                              id="outlined-adornment-email"
                              onError={(newError) => setError(newError)}
                              maxDateTime={endDate ? dayjs(endDate) : null}
                              placeholder="From"
                              error={errors?.startTime?.message}
                              value={startDate ? dayjs(startDate) : null}
                              onChange={(newValue) => {
                                setStartDate(dayjs(newValue).format("YYYY-MM-DD"));
                              }}
                            />
                          </LocalizationProvider>
                          <FormHelperText error>
                            {errors?.startTime?.message}
                          </FormHelperText>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              {...register("endDate")}
                              label="To*"
                              name="endDate"
                              id="outlined-adornment-email"
                              minDate={startDate ? dayjs(startDate) : null}
                              onError={(newError) => setError(newError)}
                              maxDateTime={endDate ? dayjs(endDate) : null}
                              disableFuture={true}
                              placeholder="To"
                              error={errors?.startTime?.message}
                              value={endDate ? dayjs(endDate) : null}
                              onChange={(newValue) => {
                                setEndDate(dayjs(newValue).format("YYYY-MM-DD"));
                              }}
                            />
                          </LocalizationProvider>
                          <FormHelperText error>
                            {errors?.startTime?.message}
                          </FormHelperText>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <Button sx={{
                            minHeight: "41px"
                          }} type="button" onClick={() => handleClear()} variant="outlined">Clear</Button>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                </Grid>
              </form>
              <HealthCheck graphData={graphResult} type={monthDaily} monthDaily={monthDaily} startDate={startDate} endDate={endDate} region={region} state={state} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <form id="outer_form_contect_consumption_nongraded">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel shrink>Resources</InputLabel>
                          <Select
                            {...register("module")}
                            label="Resources"
                            id="outlined-adornment-module"
                            value={nonGradedModule ? nonGradedModule : null}
                            notched
                            defaultValue={nonGradedModules[0].value}
                            onChange={(e) => setNonGradedModule(e.target.value)}
                          >
                            {nonGradedModules?.map(({ name, value }, index) => (
                              <MenuItem value={value} key={index}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>

                          <FormHelperText error>
                            {errors?.category?.message}
                          </FormHelperText>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel shrink>State</InputLabel>
                          <Select
                            {...register("state")}
                            label="State"
                            id="outlined-adornment-state"
                            value={state ? state : null}
                            notched
                            error={errors?.state?.message}
                            onChange={(e) => setState(e.target.value)}
                          >
                            {Object.keys(filterResult)?.map((title, index) => (
                              <MenuItem value={title} key={index}>
                                {title}
                              </MenuItem>
                            ))}
                          </Select>

                          <FormHelperText error>
                            {errors?.state?.message}
                          </FormHelperText>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel shrink>Region*</InputLabel>
                          <Select
                            {...register("region")}
                            label="Category"
                            id="outlined-adornment-category"
                            value={region ? region : null}
                            notched
                            error={errors?.region?.message}
                            onChange={(e) => setRegion(e.target.value)}
                          >
                            {state && Object.values(filterResult[state]).sort()?.map((title, index) => (
                              <MenuItem value={title} key={index}>
                                {title}
                              </MenuItem>
                            ))}
                          </Select>

                          <FormHelperText error>
                            {errors?.region?.message}
                          </FormHelperText>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              {...register("startTime")}
                              label="From*"
                              name="startTime"
                              id="outlined-adornment-email"
                              onError={(newError) => setError(newError)}
                              maxDateTime={endDate ? dayjs(endDate) : null}
                              placeholder="From"
                              error={errors?.startTime?.message}
                              value={startDate ? dayjs(startDate) : null}
                              onChange={(newValue) => {
                                setStartDate(dayjs(newValue).format("YYYY-MM-DD"));
                              }}
                            />
                          </LocalizationProvider>
                          <FormHelperText error>
                            {errors?.startTime?.message}
                          </FormHelperText>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              {...register("endDate")}
                              label="To*"
                              name="endDate"
                              id="outlined-adornment-email"
                              minDate={startDate ? dayjs(startDate) : null}
                              onError={(newError) => setError(newError)}
                              maxDateTime={endDate ? dayjs(endDate) : null}
                              disableFuture={true}
                              placeholder="To"
                              error={errors?.startTime?.message}
                              value={endDate ? dayjs(endDate) : null}
                              onChange={(newValue) => {
                                setEndDate(dayjs(newValue).format("YYYY-MM-DD"));
                              }}
                            />
                          </LocalizationProvider>
                          <FormHelperText error>
                            {errors?.startTime?.message}
                          </FormHelperText>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <Button sx={{
                            minHeight: "41px"
                          }} type="button" onClick={() => handleClear()} variant="outlined">Clear</Button>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                </Grid>
              </form>
              <ContentConsumptionNonGraded graphData={graphResult} type={monthDaily} module={nonGradedModule} startDate={startDate} endDate={endDate} region={region} state={state} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <form id="outer_form_community_engagement">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel shrink>Resources</InputLabel>
                          <Select
                            {...register("module")}
                            label="Resources"
                            id="outlined-adornment-module"
                            value={communityModule ? communityModule : null}
                            notched
                            defaultValue={communityModules[0].value}
                            onChange={(e) => setCommunityModule(e.target.value)}
                          >
                            {communityModules?.map(({ name, value }, index) => (
                              <MenuItem value={value} key={index}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>

                          <FormHelperText error>
                            {errors?.category?.message}
                          </FormHelperText>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel shrink>State</InputLabel>
                          <Select
                            {...register("state")}
                            label="State"
                            id="outlined-adornment-state"
                            value={state ? state : null}
                            notched
                            error={errors?.state?.message}
                            onChange={(e) => setState(e.target.value)}
                          >
                            {Object.keys(filterResult)?.map((title, index) => (
                              <MenuItem value={title} key={index}>
                                {title}
                              </MenuItem>
                            ))}
                          </Select>

                          <FormHelperText error>
                            {errors?.state?.message}
                          </FormHelperText>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel shrink>Region*</InputLabel>
                          <Select
                            {...register("region")}
                            label="Category"
                            id="outlined-adornment-category"
                            value={region ? region : null}
                            notched
                            error={errors?.region?.message}
                            onChange={(e) => setRegion(e.target.value)}
                          >
                            {state && Object.values(filterResult[state]).sort()?.map((title, index) => (
                              <MenuItem value={title} key={index}>
                                {title}
                              </MenuItem>
                            ))}
                          </Select>

                          <FormHelperText error>
                            {errors?.region?.message}
                          </FormHelperText>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <Button sx={{
                            minHeight: "41px"
                          }} type="button" onClick={() => handleClear()} variant="outlined">Clear</Button>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                </Grid>
              </form>
              <CommunityEngagement graphData={graphResult} type={monthDaily} monthDaily={monthDaily} module={communityModule} region={region} state={state} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <form id="outer_form_app_errors">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel shrink>Module</InputLabel>
                          <Select
                            {...register("category")}
                            label="Category"
                            id="outlined-adornment-category"
                            value={cat ? cat : null}
                            notched
                            error={errors?.stars?.message}
                            defaultValue={categories.result[0]?.category}
                            onChange={(e) => setCat(e.target.value)}
                          >
                            {categories?.result?.map(({ id, title }, index) => (
                              <MenuItem value={title} key={index}>
                                {title}
                              </MenuItem>
                            ))}
                          </Select>

                          <FormHelperText error>
                            {errors?.category?.message}
                          </FormHelperText>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                </Grid>
              </form>
              <AppErrors data={data} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <form id="outer_form_batch_report">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel shrink>Report Type</InputLabel>
                          <Select
                            {...register("reportType")}
                            label="Report Type"
                            id="outlined-adornment-module"
                            value={reportType ? reportType : null}
                            notched
                            defaultValue={reportTypes[0].value}
                            onChange={(e) => setReportType(e.target.value)}
                          >
                            {reportTypes?.map(({ name, value }, index) => (
                              <MenuItem value={value} key={index}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>

                          <FormHelperText error>
                            {errors?.reportType?.message}
                          </FormHelperText>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card>
                      <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined" fullWidth>
                          <Button sx={{
                            minHeight: "41px"
                          }} type="button" onClick={() => handleApiCall()} variant="outlined">Download Report</Button>
                        </FormControl>
                      </Stack>
                    </Card>
                  </Grid>
                </Grid>
              </form>
            </CustomTabPanel>
          </Box>
        </Paper>

      </Body>
    </>
  );
}
