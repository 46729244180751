import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const topicsGlobalApi = createAsyncThunk(
    'auth/topics/list/global',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.publicapiAction("topics", 1))
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const topicsGlobalSlice = createSlice({
    name: 'auth/topics/list/global',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(topicsGlobalApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(topicsGlobalApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(topicsGlobalApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const topicsGlobalReducer = topicsGlobalSlice.reducer