import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Button,
  Card,
  IconButton,
  Modal,
  Stack,
  Typography
} from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteQnsBankcApi } from "../../store/question-bank/delete";
import { sundayPollApi } from "../../store/sundayPolls/sundayPollsList";
import { qnsBankListApi } from "../../store/question-bank/list";

export default function DeleteQnsBank({ open, setOpen, id }) {
  const deleteSundayPoll = useSelector((state) => state?.sundayPollDelete);


  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleClose = () => setOpen(false);

  const deleteCourseConfirm = async () => {
    await dispatch(
      deleteQnsBankcApi(id)
    );
    setOpen(false);
    if (!deleteSundayPoll?.loading) {
      dispatch(
        qnsBankListApi()
      );
      navigate("/question-bank");
    }

  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card className="modal" sx={{ width: "30%" }}>
          <Stack alignItems="flex-end">
            <IconButton onClick={handleClose}>
              <HighlightOffIcon />
            </IconButton>
          </Stack>
          <Stack alignItems="center" my={2}>
            <Typography variant="h5" fontWeight={600}>
              Are you sure you want to delete this question ?
            </Typography>
            <Typography variant="h5" fontWeight={600}>
              Please click confirm
            </Typography>
          </Stack>


          <Stack sx={{ mt: 2 }} justifyContent="center" direction="row">
            <Button variant="outlined" onClick={() => setOpen(false)} sx={{ mr: 2 }}>
              Back
            </Button>
            <Stack direction="row" spacing={1}>
              {/* <Button variant='outlined' onClick={notRequiredOrderHandler}>Not Required</Button> */}
              <Button variant="contained" onClick={deleteCourseConfirm}>
                Confirm
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>
    </>
  );
}

DeleteQnsBank.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  id: PropTypes.any,
};
