import { Box, Button, Chip, Paper, Stack, Typography } from "@mui/material";
import { DataGrid, getGridStringOperators, GridToolbar } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useEffect, useState, useCallback, useContext } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import NodataImg from "../../assets/images/noData.png";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { useNavigate } from "react-router-dom";
import { quizListApi } from "../../store/quizzes/quizList";
import DeleteQuiz from "./deleteQuiz";
import { resetQuiz } from "../../store/quizzes/quizDetail";
import BulkQuiz from "./BulkQuiz";
import { ThemeContext } from '../../context/ThemeContext';
import CustomGridToolbar from "../../components/CustomGridToolbar";
import apiRoutes from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";
export default function ManageQuiz() {
  const quizList = useSelector((state) => state?.quizList);
  const { result, order_category, loading, total } = quizList;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDownload, setIsDownload] = useState(false)
  const [selectedRow, setSelectedRow] = useState([]);
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [bulkQuiz, setBulkQuiz] = useState(false);
  const { paginationModel, setPaginationModel } = useContext(ThemeContext)
  const [queryOptions, setQueryOptions] = useState({});

  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "Quiz Id",
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "title",
      headerName: "Title",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 350,
    },

    {
      field: "duration",
      headerName: "Duration",
      filterable: false,
      sortable: false,
      width: 100,
    },
    {
      field: "banner",
      headerName: "Quiz Image",
      filterable: false,
      sortable: false,
      width: 250,
      renderCell: (params) => (
        <Box sx={{ py: 1 }}><img width={200} height={100} src={params.row.banner} alt='banner image' /></Box>)
    },
    {
      headerName: "Action",
      filterable: false,
      sortable: false,
      width: 200,
      disableExport: true,
      renderCell: (row) => (
        <>
          {
            <>
              <Button color="success" label="Available" variant="outlined"
                onClick={() => navigate(`/edit-quiz/${row.id}`)}
              >
                Edit
              </Button>
              <Button
                color="success"
                label="Available"
                variant="outlined"
                style={{ marginLeft: "5px" }}
                onClick={() => {
                  setNotSelectedProductModel(true)
                  setDeleteId(row.id)
                }}
              >
                Delete
              </Button>
            </>
          }
        </>
      ),
    },
  ];

  const addToCartHandler = async () => {

    navigate("/add-quiz");

  };

  useEffect(() => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    const query = {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
    };
    dispatch(quizListApi(query));
    dispatch(resetQuiz())
  }, [dispatch, queryOptions, paginationModel]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>


        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Quizzes List</Typography>
        </Stack>
        <Stack sx={{ my: 2 }} justifyContent="space-between" direction="row">
          <Stack direction="row" spacing={1}>

            <Button
              onClick={addToCartHandler}
              variant="contained"
            >
              Create New Quiz
            </Button>
            <Button
              onClick={() => setBulkQuiz(true)}
              variant="contained"
            >
              Add Bulk Quizzes
            </Button>
          </Stack>
        </Stack>

        <Paper elevation={0}>
          <DataGrid
            loading={loading}
            rowCount={total}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            pageSize={paginationModel?.pageSize}
            onPageSizeChange={(newPageSize) => {
              setPaginationModel({
                ...paginationModel,
                pageSize: newPageSize,
              })
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pagination
            paginationMode="server"
            sx={{ height: 500 }}
            getRowId={(row) => row.id}
            rows={result}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            filterMode="server"
            onFilterModelChange={onFilterChange}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: (props) =>
                (CustomGridToolbar({ ...props, moduleType: "quizManagement", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.quizList })),
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Paper>
      </Body>

      <DeleteQuiz
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        id={deleteId}
      />
      <BulkQuiz
        open={bulkQuiz}
        setOpen={setBulkQuiz}

      />
      <BackdropUI open={isDownload} />
    </>
  );
}
