import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs";

export const impactStoriesApi = createAsyncThunk(
    'auth/impactStoriess',
    async (data, { rejectWithValue }) => {
        try {
            const paramsData = new URLSearchParams(data);

            const response = await AuthAxios.get(apiRoutes.impactStoryList, { params: data }
            )
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const impactStoriesSlice = createSlice({
    name: 'impactStories',
    initialState: { result: [], loading: false, error: null, message: "", total: 0 },
    extraReducers: (builder) => {
        builder.addCase(impactStoriesApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(impactStoriesApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
            state.total = action?.payload?.count
        })
        builder.addCase(impactStoriesApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const impactStoriesReducer = impactStoriesSlice.reducer