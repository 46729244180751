import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../../config/authAxios"
import apiRoutes from "../../apiConfigs"

export const getUnitListApi = createAsyncThunk(
    'courses/get-unit-list',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.courseUnitUploadVideoAction(`${data.course_id}`, 0), {
                params: {
                    unit_block_id: data.unit_block_id
                }
            })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "" }
const getUnitListSlice = createSlice({
    name: 'get-unit-list',
    initialState: intialState,
    reducers: {
        reset: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getUnitListApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getUnitListApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(getUnitListApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const { reset } = getUnitListSlice.actions;
export const getUnitListReducer = getUnitListSlice.reducer