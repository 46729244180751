import { Backdrop, CircularProgress } from '@mui/material';
import * as React from 'react';


export default function BackdropUI({ open }: any): React.JSX.Element {

    return (
        <Backdrop sx={{ color: '#fff' }} open={open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}


