import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const analyticsTableApi = createAsyncThunk(
    'auth/analytics/table',
    async (requestData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.userList, { params: requestData })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const analyticsTableSlice = createSlice({
    name: 'analitics/table',
    initialState: { result: [], loading: false, error: null, message: "", total: 0 },
    extraReducers: (builder) => {
        builder.addCase(analyticsTableApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(analyticsTableApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
            state.total = action?.payload?.count
        })
        builder.addCase(analyticsTableApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const analyticsTableReducer = analyticsTableSlice.reducer