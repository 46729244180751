import { createSlice } from "@reduxjs/toolkit";


const intialState = { data: [], }

const courseSectionDetailsAddSlice = createSlice({
    name: 'add-section-details',
    initialState: intialState,
    reducers: {
        addSectionDetails: (state, action) => {
            state.data = action?.payload?.data
        },
    },

})
export const { addSectionDetails } = courseSectionDetailsAddSlice.actions;
export const courseSectionDetailsAddReducer = courseSectionDetailsAddSlice.reducer