import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotRoute from "../view/404Route";
import ForgetPassword from "../view/auth/ForgetPassword";
import Login from "../view/auth/Login";
import CategoryEdit from "../view/category/categoryEdit";
import CategoryList from "../view/category/categoryList";
import AddSplashScreen from "../view/cms/AddSplashScreen";
import EditPage from "../view/cms/EditPage";
import ManageCms from "../view/cms/ManageCms";
import ManageSplashScreen from "../view/cms/ManageSplashScreen";
import AddCourse from "../view/courses/AddCourse";
import AddCourseSubSection from "../view/courses/AddCourseSubSection";
import AddCourseUnit from "../view/courses/AddCourseUnit";
import Courses from "../view/courses/ManageCourses";
import UnitList from "../view/courses/UnitList";
import EditEntryExit from "../view/entry-exit-question/edit-entry-exit-question";
import EditTlm from "../view/tlm/editTlm";
import EditPbl from "../view/pbl/editPbl";
import EditHolidayHomework from "../view/holiday-homework/editHolidayHomework";
import UploadCertificate from "../view/entry-exit-question/uploadCertificate";

import ManageEntryExit from "../view/entry-exit-question/entry-exit-question";
import AddFaq from "../view/faq/editFaq";
import FaqListing from "../view/faq/faqListing";
import AnalyticsListing from "../view/analytics/AnalyticsListing";
import Managehhw from "../view/holiday-homework/manageHoliday";
import Home from "../view/home";
import EditImpact from "../view/impact/edit-impact-stories";
import ManageImpact from "../view/impact/impact-stories-management";
import AddPartner from "../view/partner/AddPartner";
import ManagePartners from "../view/partner/ManagePartner";
import ManagePbl from "../view/pbl/managePbl";
import AddPodcast from "../view/podcast/AddPodcast";
import ManagePodcast from "../view/podcast/ManagePodcast";
import AddQuiz from "../view/quizzes/addQuiz";
import ManageQuiz from "../view/quizzes/manageQuiz";
import AddSchoolResource from "../view/school-resources/AddSchoolResource";
import ManageSchoolResource from "../view/school-resources/ManageSchoolResource";
import AddShortVideo from "../view/shortVideos/AddShortVideos";
import ManageShortVideo from "../view/shortVideos/ManageShortVideo";
import SundayPollAnalytics from "../view/sundayPoll/Analytics";
import EditPoll from "../view/sundayPoll/EditPoll";
import ManagePoll from "../view/sundayPoll/managePoll";
import SupportListing from "../view/support/supportListing";
import AddTeachingVideo from "../view/teaching-video/AddTeachingVideo";
import ManageTeachingVideo from "../view/teaching-video/ManageTeachingVideo";
import ManageTlm from "../view/tlm/manageTlm";
import ManageUser from "../view/user/ManageUser";
import AddWebinars from "../view/webinars/AddWebinars";
import ManageWebinar from "../view/webinars/ManageWebinar";
import ProtectedRoute from "./ProtectedRoute";
import ManageQnsBank from "../view/question-bank/ManageQnsBank";
import AddQnsBank from "../view/question-bank/AddQnsBank";
import DeleteQnsBank from "../view/question-bank/DeleteQnsBank";
import ManageNotifications from "../view/notifications/notification-list";
import PushNotifications from "../view/notifications/push-notification";
import ClassEdit from "../view/class/ClassEdit";
import ClassList from "../view/class/ClassList";
import SettingsList from "../view/settings/SettingsList";
import SettingsEdit from "../view/settings/SettingsEdit";
import SubjectEdit from "../view/subject/SubjectEdit";
import SubjectList from "../view/subject/SubjectList";
import TopicsEdit from "../view/topics/TopicsEdit";
import TopicsList from "../view/topics/TopicsList";
import DifficultyLevelEdit from "../view/difficultyLevel/DifficultyLevelEdit";
import DifficultyLevelList from "../view/difficultyLevel/DifficultyLevelList";
import InterestedUser from "../view/webinars/InterestedUser";
import ManageNotificationCohort from "../view/notification-cohort/notification-cohort-history";
import ManageCohortUser from "../view/notification-cohort/user-list";
import AddCohortNotifications from "../view/notification-cohort/add-cohort";
export default function MainRoutes() {
  return (
    <BrowserRouter>
      <Routes>
      <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        {/* user  */}
        <Route
          path="/user-management"
          element={
            <ProtectedRoute>
              <ManageUser />
            </ProtectedRoute>
          }
        />

        {/* CMS  */}
        <Route
          path="/manage-pages"
          element={
            <ProtectedRoute>
              <ManageCms />
            </ProtectedRoute>
          }
        />
        <Route
          path="/handle-splash-screen"
          element={
            <ProtectedRoute>
              <AddSplashScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manage-splash-screen"
          element={
            <ProtectedRoute>
              <ManageSplashScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-page/:name"
          element={
            <ProtectedRoute>
              <EditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-splash-screen/:id"
          element={
            <ProtectedRoute>
              <AddSplashScreen />
            </ProtectedRoute>
          }
        />

        {/* courses */}
        <Route
          path="/courses"
          element={
            <ProtectedRoute>
              <Courses />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-course/:id"
          element={
            <ProtectedRoute>
              <AddCourse />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-courses"
          element={
            <ProtectedRoute>
              <AddCourse />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-course/sub-section"
          element={
            <ProtectedRoute>
              <AddCourseSubSection />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-course/sub-section/units"
          element={
            <ProtectedRoute>
              <AddCourseUnit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-course/sub-section/units/list"
          element={
            <ProtectedRoute>
              <UnitList />
            </ProtectedRoute>
          }
        />

        {/* podcast */}
        <Route
          path="/podcasts"
          element={
            <ProtectedRoute>
              <ManagePodcast />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-podcast"
          element={
            <ProtectedRoute>
              <AddPodcast />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-podcast/:id"
          element={
            <ProtectedRoute>
              <AddPodcast />
            </ProtectedRoute>
          }
        />

        {/* webinar */}
        <Route
          path="/webinars"
          element={
            <ProtectedRoute>
              <ManageWebinar />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-webinar"
          element={
            <ProtectedRoute>
              <AddWebinars />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-webinar/:id"
          element={
            <ProtectedRoute>
              <AddWebinars />
            </ProtectedRoute>
          }
        />
        <Route
          path="/webinar-interested-users/:id"
          element={
            <ProtectedRoute>
              <InterestedUser />
            </ProtectedRoute>
          }
        />

        {/* short Videos */}
        <Route
          path="/short-videos"
          element={
            <ProtectedRoute>
              <ManageShortVideo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-short-video"
          element={
            <ProtectedRoute>
              <AddShortVideo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-short-video/:id"
          element={
            <ProtectedRoute>
              <AddShortVideo />
            </ProtectedRoute>
          }
        />

        {/* teaching  Videos */}
        <Route
          path="/teaching-videos"
          element={
            <ProtectedRoute>
              <ManageTeachingVideo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-teaching-video"
          element={
            <ProtectedRoute>
              <AddTeachingVideo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-teaching-video/:id"
          element={
            <ProtectedRoute>
              <AddTeachingVideo />
            </ProtectedRoute>
          }
        />

        {/* school resource */}
        <Route
          path="/school-resource"
          element={
            <ProtectedRoute>
              <ManageSchoolResource />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-school-resource"
          element={
            <ProtectedRoute>
              <AddSchoolResource />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-school-resource/:id"
          element={
            <ProtectedRoute>
              <AddSchoolResource />
            </ProtectedRoute>
          }
        />

        {/* faq */}
        <Route
          path="/faq"
          element={
            <ProtectedRoute>
              <FaqListing />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-faq"
          element={
            <ProtectedRoute>
              <AddFaq />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-faq/:id"
          element={
            <ProtectedRoute>
              <AddFaq />
            </ProtectedRoute>
          }
        />

        {/* support */}
        <Route
          path="/support-management"
          element={
            <ProtectedRoute>
              <SupportListing />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-support"
          element={
            <ProtectedRoute>
              <AddPodcast />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-support/:id"
          element={
            <ProtectedRoute>
              <AddPodcast />
            </ProtectedRoute>
          }
        />

        {/* category */}
        <Route
          path="/category-management"
          element={
            <ProtectedRoute>
              <CategoryList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-category"
          element={
            <ProtectedRoute>
              <CategoryEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-category/:id"
          element={
            <ProtectedRoute>
              <CategoryEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/banner"
          element={
            <ProtectedRoute>
              <ManageSplashScreen />
            </ProtectedRoute>
          }
        />

        {/* Poll */}
        <Route
          path="/sunday-polls"
          element={
            <ProtectedRoute>
              <ManagePoll />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-sunday-poll"
          element={
            <ProtectedRoute>
              <EditPoll />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-sunday-poll/:id"
          element={
            <ProtectedRoute>
              <EditPoll />
            </ProtectedRoute>
          }
        />
        <Route
          path="/results/:id"
          element={
            <ProtectedRoute>
              <SundayPollAnalytics />
            </ProtectedRoute>
          }
        />

        {/* Impact Stories */}
        <Route
          path="/impact-stories-management"
          element={
            <ProtectedRoute>
              <ManageImpact />
            </ProtectedRoute>
          }
        />
        <Route
          path="/impact-story-details/:id"
          element={
            <ProtectedRoute>
              <EditImpact />
            </ProtectedRoute>
          }
        />
        {/* Entry Exit Question */}
        <Route
          path="/entry-exit-management"
          element={
            <ProtectedRoute>
              <ManageEntryExit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/entry-exit-details/:id"
          element={
            <ProtectedRoute>
              <EditEntryExit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-entry-exit-details"
          element={
            <ProtectedRoute>
              <EditEntryExit />
            </ProtectedRoute>
          }
        />
        {/* Partners*/}
        <Route
          path="/partners-management"
          element={
            <ProtectedRoute>
              <ManagePartners />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-partners"
          element={
            <ProtectedRoute>
              <AddPartner />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-partners/:id"
          element={
            <ProtectedRoute>
              <AddPartner />
            </ProtectedRoute>
          }
        />

        {/* Teaching Kit*/}
        <Route
          path="/pbl-management"
          element={
            <ProtectedRoute>
              <ManagePbl />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-pbl-management"
          element={
            <ProtectedRoute>
              <EditPbl />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-pbl-management/:id"
          element={
            <ProtectedRoute>
              <EditPbl />
            </ProtectedRoute>
          }
        />
        <Route
          path="/holiday-homework-management"
          element={
            <ProtectedRoute>
              <Managehhw />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-holiday-homework-management"
          element={
            <ProtectedRoute>
              <EditHolidayHomework />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-holiday-homework-management/:id"
          element={
            <ProtectedRoute>
              <EditHolidayHomework />
            </ProtectedRoute>
          }
        />

        <Route
          path="/tlm-management"
          element={
            <ProtectedRoute>
              <ManageTlm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-tlm-management"
          element={
            <ProtectedRoute>
              <EditTlm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-tlm-management/:id"
          element={
            <ProtectedRoute>
              <EditTlm />
            </ProtectedRoute>
          }
        />

        {/* Poll */}
        <Route
          path="/question-bank"
          element={
            <ProtectedRoute>
              <ManageQnsBank />
            </ProtectedRoute>
          }
        />
        <Route
          path="/question-bank/add"
          element={
            <ProtectedRoute>
              <AddQnsBank />
            </ProtectedRoute>
          }
        />
        <Route
          path="/question-bank/edit/:id"
          element={
            <ProtectedRoute>
              <AddQnsBank />
            </ProtectedRoute>
          }
        />
        <Route
          path="/question-bank/delete/:id"
          element={
            <ProtectedRoute>
              <DeleteQnsBank />
            </ProtectedRoute>
          }
        />

        {/* Quizzes */}
        <Route
          path="/quizzes"
          element={
            <ProtectedRoute>
              <ManageQuiz />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-quiz"
          element={
            <ProtectedRoute>
              <AddQuiz />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-quiz/:id"
          element={
            <ProtectedRoute>
              <AddQuiz />
            </ProtectedRoute>
          }
        />

        {/* Notifications */}
        <Route path="/notifications" element={<ManageNotifications />} />
        <Route path="/push-notification" element={<PushNotifications />} />

        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="*" element={<NotRoute />} />

        {/* Analytics */}
        <Route
          path="/get-analytics"
          element={
            <ProtectedRoute>
              <AnalyticsListing />
            </ProtectedRoute>
          }
        />

        {/* subject */}
        <Route
          path="/subject-management"
          element={
            <ProtectedRoute>
              <SubjectList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-subject"
          element={
            <ProtectedRoute>
              <SubjectEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-subject/:id"
          element={
            <ProtectedRoute>
              <SubjectEdit />
            </ProtectedRoute>
          }
        />

        {/* class */}
        <Route
          path="/class-management"
          element={
            <ProtectedRoute>
              <ClassList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-class"
          element={
            <ProtectedRoute>
              <ClassEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-class/:id"
          element={
            <ProtectedRoute>
              <ClassEdit />
            </ProtectedRoute>
          }
        />

        {/* settings */}
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <SettingsList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-settings/:id"
          element={
            <ProtectedRoute>
              <SettingsEdit />
            </ProtectedRoute>
          }
        />

        {/* difficulty level */}
        <Route
          path="/difficulty-level-management"
          element={
            <ProtectedRoute>
              <DifficultyLevelList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-difficulty-level"
          element={
            <ProtectedRoute>
              <DifficultyLevelEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-difficulty-level/:id"
          element={
            <ProtectedRoute>
              <DifficultyLevelEdit />
            </ProtectedRoute>
          }
        />
         {/* difficulty level */}
         <Route
          path="/notification-cohort-management"
          element={
            <ProtectedRoute>
              <ManageNotificationCohort />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-notification-cohort"
          element={
            <ProtectedRoute>
              <AddCohortNotifications />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-notification-cohort/:id"
          element={
            <ProtectedRoute>
              <AddCohortNotifications />
            </ProtectedRoute>
          }
        />
          <Route
          path="/notification-cohort/:id/user-list"
          element={
            <ProtectedRoute>
              <ManageCohortUser />
            </ProtectedRoute>
          }
        />
        {/* topics */}
        <Route
          path="/topics-management"
          element={
            <ProtectedRoute>
              <TopicsList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-topics"
          element={
            <ProtectedRoute>
              <TopicsEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-topics/:id"
          element={
            <ProtectedRoute>
              <TopicsEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload-certificate/:id"
          element={
            <ProtectedRoute>
              <UploadCertificate />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
