import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";
export const splashScreenEditApi = createAsyncThunk(
  "auth/podcasts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.patch(
        apiRoutes.splashScreenAction(data.id, 1), data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const splashScreenEditSlice = createSlice({
  name: "splashScreen Edit",
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(splashScreenEditApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(splashScreenEditApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(splashScreenEditApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const splashScreenEditReducer = splashScreenEditSlice.reducer;
