import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const userStatusApi = createAsyncThunk(
    'auth/podcasts/delete',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.patch(apiRoutes.adminAction(`status/${data.userName}`, 1), data)
            return response.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const userStatusSlice = createSlice({
    name: 'podcast delete',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(userStatusApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(userStatusApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(userStatusApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const userStatusReducer = userStatusSlice.reducer