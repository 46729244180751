import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const getScromApi = createAsyncThunk(
  "scrom_get/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.get(apiRoutes.courseScormAction(`${data.course_id}`, 0), {
        params: {
          unit_block_id: data.unit_block_id
        }
      })
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const intialState = { result: [], loading: false, error: null, message: "" }
const getScromSlice = createSlice({
  name: "scrom_get",
  initialState: { result: [], loading: false, error: null, message: "" },
  reducers: {
    resetPreviewUnit: () => intialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getScromApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getScromApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(getScromApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetPreviewUnit } = getScromSlice.actions;
export const getScromReducer = getScromSlice.reducer;
