import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Paper,
    Stack,
    Typography,
    Tabs,
    Tab,
    Card,
    Grid,
    CardActions,
    CardContent,
    MenuItem,
    FormHelperText,
    FormControl,
    InputLabel,
    OutlinedInput,
    Chip,
    Select
} from "@mui/material";
import { DataGrid, getGridStringOperators, GridToolbar } from "@mui/x-data-grid";
import Chart from "../../components/Chart"

export default function AppErrors({ data }) {
    return (<>
        <Stack direction="row" spacing={1} sx={{ my: 2, padding: "10px" }} alignItems="center">
            <Typography variant="h3">App Errors</Typography>
        </Stack>
        <Box display={"flex"} justifyContent={"space-around"} flexDirection={"column"}>
            <Box>
                <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
                    <Grid container spacing={1}>
                        <Grid item xl={3} lg={3} xs={12}>
                            <Card sx={{
                                minHeight: "100px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <CardContent>
                                    <Typography gutterBottom variant="h4" component="div"># no of sessions</Typography>
                                </CardContent>
                            </Card>

                        </Grid>
                        <Grid item xl={3} lg={3} xs={12}>
                            <Card sx={{
                                minHeight: "100px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <CardContent>
                                    <Typography gutterBottom variant="h4" component="div"># no of events</Typography>
                                </CardContent>
                            </Card>

                        </Grid>
                        <Grid item xl={3} lg={3} xs={12}>
                            <Card sx={{
                                minHeight: "100px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <CardContent>
                                    <Typography gutterBottom variant="h4" component="div"># errors</Typography>
                                </CardContent>
                            </Card>

                        </Grid>
                        <Grid item xl={3} lg={3} xs={12}>
                            <Card sx={{
                                minHeight: "100px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <CardContent>
                                    <Typography gutterBottom variant="h4" component="div"># errors/ session</Typography>
                                </CardContent>
                            </Card>

                        </Grid>
                    </Grid>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center" justifyContent={"center"}>
                    <Chart type={"line"} height={500} width={700} />
                </Stack>
            </Box>
            <Box>
                <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center" justifyContent={"center"}>
                    <Chart type={"pie"} />
                </Stack>
            </Box>
        </Box>
    </>)
}