import { Box, Card, Grid, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { BsSpeedometer2 } from 'react-icons/bs'
import { useDispatch } from 'react-redux'





export default function ReportSection() {

    const dispatch = useDispatch()

    useEffect(() => {
    }, [dispatch])

    return (
        <Stack spacing={2}>
            <Card>
                {/* <Typography fontWeight={600} variant='h5' my={1}>Quick Links</Typography> */}
                <Grid container rowGap={2}>
                    {/* <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Link to='/project/my-project-plan' className='link'>
                            <Box className='customCard5'>
                                <Stack spacing={1} sx={{ p: 2, width: '100%' }} alignItems='center' justifyContent='center'>
                                    <VscOpenPreview size={20} />
                                    <Typography variant='h6' textAlign='center'>My Project Plan</Typography>
                                </Stack>
                            </Box>
                        </Link>
                    </Grid> */}
                    {/* <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box className='customCard5'>
                            <Stack spacing={1} sx={{ p: 2, width: '100%' }} alignItems='center' justifyContent='center'>
                                <SiTextpattern size={20} />
                                <Typography variant='h6' textAlign='center'>Approved Milestone</Typography>
                            </Stack>
                        </Box>
                    </Grid> */}
                    {/* <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box className='customCard6'>
                            <Stack spacing={1} sx={{ p: 2, width: '100%' }} alignItems='center' justifyContent='center'>
                                <SiAltiumdesigner size={20} />
                                <Typography variant='h6' textAlign='center'>Layout & Designing Plan</Typography>
                            </Stack>
                        </Box>
                    </Grid> */}
                    {/* <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box className='customCard6'>
                            <Stack spacing={1} sx={{ p: 2, width: '100%' }} alignItems='center' justifyContent='center'>
                                <MdOutlineTrackChanges size={20} />
                                <Typography variant='h6' textAlign='center'>Site Progress Tracker</Typography>
                            </Stack>
                        </Box>
                    </Grid> */}
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box className='customCard5'>
                            <Stack spacing={1} sx={{ p: 2, width: '100%' }} alignItems='center' justifyContent='center'>
                                <BsSpeedometer2 size={20} />
                                <Typography variant='h6' textAlign='center'>Reports & Dashboard</Typography>
                            </Stack>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box className='customCard6'>
                            <Stack spacing={1} sx={{ p: 2, width: '100%' }} alignItems='center' justifyContent='center'>
                                <VscPreview size={25} />
                                <Typography variant='h6' textAlign='center'>Action Plan Review</Typography>
                            </Stack>
                        </Box>
                    </Grid> */}
                </Grid>
            </Card>
        </Stack>
    )
}
