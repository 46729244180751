import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const hhwApproveApi = createAsyncThunk(
    'auth/hhws/Approve',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(apiRoutes.holidayHomeWorkAction("approved", 0), data)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const hhwApproveSlice = createSlice({
    name: 'hhw Approve',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(hhwApproveApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(hhwApproveApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(hhwApproveApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const hhwApproveReducer = hhwApproveSlice.reducer