import { yupResolver } from "@hookform/resolvers/yup";
import EmailIcon from '@mui/icons-material/Email';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Card, FormControl, FormHelperText, IconButton, InputLabel, Modal, OutlinedInput, Stack, Typography } from '@mui/material';
import { default as React, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { forgetPasswordViaEmailApi } from '../../store/auth/forgetPasswordViaEmail';
import ResetViaEmailOTP from './ResetViaEmailOTP';
import { authOtpViaEmailApi } from "../../store/auth/authOtpViaEmail";
import BackdropUI from "../../ui/Backdrop";
import PropTypes from 'prop-types'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'


const schema = yup.object({
    email: yup.string().email("Invalid email!").required('Email is required.'),
}).required()


export default function ForgetPasswordEmailConf({ open, setOpen, back }) {

    const forgetPasswordViaEmailSelector = useSelector(state => state?.forgetPasswordViaEmail)
    const { loading } = forgetPasswordViaEmailSelector

    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(schema) })

    const dispatch = useDispatch()

    const [emailVerifyModal, setEmailVerifyModal] = useState(false)
    const [backdrop, setBackDrop] = useState(false)

    const handleClose = () => {
        setOpen(false)
        reset()
    }

    const handleBack = () => {
        back(true)
    setOpen(false)
    }

    const handleSendVierifyEmail = async (data) => {
        const passData = await dispatch(forgetPasswordViaEmailApi(data))
        if (!passData?.payload?.error) {
            localStorage.setItem("access_token", passData?.payload?.result?.access_token)
            localStorage.setItem("email", passData?.payload?.result?.email)
            dispatch(authOtpViaEmailApi())
            setEmailVerifyModal(true)
            setOpen(false)
        }
    }

    useEffect(() => {
        if (loading) setBackDrop(true)
        else setBackDrop(false)
    }, [backdrop, loading])

    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Card className='modal'>
                    <form onSubmit={handleSubmit(handleSendVierifyEmail)}>
                        <Stack spacing={2}>
                            <Stack direction='row' alignContent='center' justifyContent='space-between'>
                                <IconButton onClick={handleBack}><MdOutlineKeyboardBackspace /></IconButton>
                                <IconButton onClick={handleClose}><HighlightOffIcon /></IconButton>
                            </Stack>
                            <Stack alignItems='center'>
                                <Typography variant='h5' fontWeight={600}>Email Verification</Typography>
                                <Typography>Please enter your registered email address to complete your verification.</Typography>
                            </Stack>
                            <Stack alignItems='center'>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
                                    <OutlinedInput
                                        {...register("email")}
                                        error={errors?.email?.message}
                                        label='Email'
                                        id="outlined-adornment-email"
                                        startAdornment={<EmailIcon />}
                                    />
                                    <FormHelperText error>{errors?.email?.message}</FormHelperText>
                                </FormControl>
                            </Stack>
                            <Button variant='contained' type="submit">Send</Button>
                        </Stack>
                    </form>
                </Card>
            </Modal>


            <ResetViaEmailOTP open={emailVerifyModal} setOpen={setEmailVerifyModal} />
            <BackdropUI open={backdrop} />
        </>
    )
}

ForgetPasswordEmailConf.propTypes = {
    open: PropTypes.any,
    setOpen: PropTypes.any,
    back: PropTypes.bool,
};