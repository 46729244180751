import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const splashScreenDetailsApi = createAsyncThunk(
    'auth/splashScreens/details',
    async (id, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.splashScreenAction(`${id}`, 1))
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "" }
const splashScreenDetailsSlice = createSlice({
    name: 'splashScreen details',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {
        resetSplash: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(splashScreenDetailsApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(splashScreenDetailsApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(splashScreenDetailsApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { resetSplash } = splashScreenDetailsSlice.actions;
export const splashScreenDetailsReducer = splashScreenDetailsSlice.reducer