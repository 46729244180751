import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const courseSectionAddApi = createAsyncThunk(
    'courses/add-section',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(apiRoutes.courseSecAction(`${data.id}`,0), data)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "" }
const courseSectionAddSlice = createSlice({
    name: 'add-section',
    initialState: intialState,
    reducers: {
        reset: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(courseSectionAddApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(courseSectionAddApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(courseSectionAddApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { reset } = courseSectionAddSlice.actions;
export const courseSectionAddReducer = courseSectionAddSlice.reducer