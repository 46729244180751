import React, { useState } from 'react'
import PropTypes from 'prop-types'


export const ThemeContext = React.createContext();

export default function ThemeContextProvider({ children }) {

    const [drawerShow, setDrawerShow] = useState(true)
    const [darkTheme, setDarkTheme] = useState(false)
    // drawer menu
    const [listoggle, setListToggle] = useState({})
    const [sublistoggle, setSubListToggle] = useState({})
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
      });
    return (
        <ThemeContext.Provider
            value={{
                drawerShow,
                setDrawerShow,
                darkTheme,
                setDarkTheme,
                listoggle,
                setListToggle,
                sublistoggle,
                setSubListToggle,paginationModel,setPaginationModel
            }}
        >
            {children}
        </ThemeContext.Provider>
    )
}


ThemeContextProvider.propTypes = {
    children: PropTypes.any,
};