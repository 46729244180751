import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const bulkUploadApi = createAsyncThunk(
  "qns-bnk/upload",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.post(apiRoutes.questionBankAction("upload", 1), data, { headers: { "Content-Type": "multipart/form-data" } })
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const intialState = { result: [], loading: false, error: null, message: "" }

const bulkUploadSlice = createSlice({
  name: "qns-bnk/upload",
  initialState: { result: [], loading: false, error: null, message: "" },
  reducers: {
    resetImage: () => intialState,
  },
  extraReducers: (builder) => {
    builder.addCase(bulkUploadApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(bulkUploadApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(bulkUploadApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetImage } = bulkUploadSlice.actions;
export const bulkUploadReducer = bulkUploadSlice.reducer;
