import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const webinarInterestedApi = createAsyncThunk(
  "auth/webinars-interested-user",
  async (data, { rejectWithValue }) => {
    try {
      const { id, ...rest } = data;
      const response = await AuthAxios.get(apiRoutes.webinarsAction(`interested-users/${id}`, 0), {
        params: rest
      });
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const webinarInterestedUserSlice = createSlice({
  name: "webinar-interested-user",
  initialState: {
    result: [],
    loading: false,
    error: null,
    message: "",
    total: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(webinarInterestedApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(webinarInterestedApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
      state.total = action?.payload?.count;
    });
    builder.addCase(webinarInterestedApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const webinarInterestedUserReducer = webinarInterestedUserSlice.reducer;
