import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ImageUploading from "react-images-uploading";
import DefaultProfileImg from "../../assets/images/defaultProfileImg.jpg";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import Chip from "@mui/material/Chip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { analiticsFilterApi } from "../../store/analytics/filters";
import { classGlobalApi } from "../../store/class/classListGlobal";
import { subjectGlobalApi } from "../../store/subject/subjectListGlobal";
import { useTheme } from "@mui/material/styles";
import { addcohortApi } from "../../store/notification-cohort/creatCohort";
import { cohortDetailsApi } from "../../store/notification-cohort/notificationById";
import { schoolTypeApi } from "../../store/school/schoolTypeList";

const schema = yup
  .object({
    title: yup.string().required("Please Provide Title").trim(),
    message: yup.string().required("Please Provide Message").trim(),
  })
  .required();
const communityModules = [
  { name: "PodCast", value: "podcast" },
  { name: "Short Videos", value: "short_video" },
  { name: "Profile", value: "profile" },
  { name: "Polls", value: "polls" },
  { name: "Quiz", value: "quiz" },
  { name: "Webinar", value: "webinar" },
  { name: "School Processes", value: "school_process" },
  { name: "Courses", value: "course" },
  { name: "Teaching Videos", value: "teaching_video" },
  { name: "Learning material", value: "learning_material" },
  { name: "Project Based", value: "project_based" },
  { name: "Entry Exit", value: "entry_exit" },
  { name: "Home Work", value: "home_work" },
];
const account_type = [
  { name: "Educator", value: 1 },
  { name: "Parent", value: 2 },
  { name: "Teacher", value: 3 },
];
const access = [

  { name: "Accessed", value: 1, },
  { name: "Not Accessed", value: 2 },
  //   { name: "Pending", value: "3" },
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function AddCohortNotifications() {
  const theme = useTheme();
  const pushNotification = useSelector((state) => state?.addcohort);
  const [state, setState] = useState();
  const analyticsFilter = useSelector((state) => state?.analyticsFilter);
  const cohortDetails = useSelector((state) => state?.cohortDetails);
  const schools = useSelector((state) => state?.schoolType);
  const { result: filterResult, filterLoading } = analyticsFilter;
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [region, setRegion] = useState();
  const [communityModule, setCommunityModule] = useState("profile");
  const [school, setSchool] = useState();
  const [accountType, setAccountType] = useState();
  const [accessType, setAccessType] = useState();
  const [notificationType, setNotificationType] = useState("");
  const { result: classList, loading: classLoading } = useSelector(
    (state) => state?.classGlobal
  );
  
  const { result: subjectList, loading: subjectLoading } = useSelector(
    (state) => state?.subjectGlobal
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
    reset,
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });

  const params = useParams();
  const navigate = useNavigate();
  const handleUpdateForm = async (data, message) => {
    var payload = {
      id: params.id || null,
      title: data.title,
      message: data.message,
      redirection_link: data.redirection_link,
      start_date: startDate,
      end_date: endDate,
      school_type: school,
      access_type: accessType,
      modules: communityModule,
      classes: watch('classes', []),
      subjects: watch('subjects', []),
      state: state,
      city: region,
      account_types: accountType,
      notification_type: notificationType
    }

    dispatch(addcohortApi(payload)).then((respo) => {
      if (respo.payload.success) {
        navigate("/notification-cohort-management");
      }
    })

  };

  useEffect(() => {
    dispatch(analiticsFilterApi({ type: "city" }));
    dispatch(classGlobalApi());
    dispatch(subjectGlobalApi());
    dispatch(schoolTypeApi());
    if (params.id) {

      dispatch(cohortDetailsApi(params.id));
    }
  }, []);
  useEffect(() => {
    setStartDate(cohortDetails.result?.start_date)
    setEndDate(cohortDetails.result?.end_date)
    setRegion(cohortDetails.result?.cohort_type?.city)
    setCommunityModule(cohortDetails.result?.modules)
    setSchool(cohortDetails.result?.cohort_type?.school_type)
    setAccountType(cohortDetails.result?.cohort_type?.account_types)
    setAccessType(cohortDetails.result?.cohort_type?.access_type)
    setState(cohortDetails.result?.cohort_type?.state)
    let defalutValues = {};
    defalutValues["title"] = cohortDetails.result?.title;
    defalutValues["message"] = cohortDetails.result?.message;
    defalutValues["redirection_link"] = cohortDetails.result?.redirection_link;
    defalutValues["subjects"] = cohortDetails.result.cohort_type?.subjects?.map(o => o?.id);
    defalutValues["classes"] = cohortDetails.result.cohort_type?.classes?.map(o => o?.id);
    reset(defalutValues);
  }, [cohortDetails]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Card>
                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          {...register("startTime")}
                          label="From"
                          name="startTime"
                          id="outlined-adornment-email"
                          onError={(newError) => setError(newError)}
                          maxDateTime={endDate ? dayjs(endDate) : null}
                          placeholder="From"
                          error={errors?.startTime?.message}
                          value={startDate ? dayjs(startDate) : null}
                          onChange={(newValue) => {
                            setStartDate(dayjs(newValue).format("YYYY-MM-DD"));
                          }}
                        />
                      </LocalizationProvider>
                      <FormHelperText error>
                        {errors?.startTime?.message}
                      </FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          {...register("endDate")}
                          label="To"
                          name="endDate"
                          id="outlined-adornment-email"
                          minDate={startDate ? dayjs(startDate) : null}
                          onError={(newError) => setError(newError)}
                          maxDateTime={endDate ? dayjs(endDate) : null}
                          disableFuture={true}
                          placeholder="To"
                          error={errors?.startTime?.message}
                          value={endDate ? dayjs(endDate) : null}
                          onChange={(newValue) => {
                            setEndDate(dayjs(newValue).format("YYYY-MM-DD"));
                          }}
                        />
                      </LocalizationProvider>
                      <FormHelperText error>
                        {errors?.startTime?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Card>

                <Card>
                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel shrink>State</InputLabel>
                      <Select
                        // {...register("state")}
                        label="State"
                        id="outlined-adornment-state"
                        value={state || []}
                        notched
                        multiple
                        error={errors?.state?.message}
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;
                          console.log(value)
                          setState(value)
                        }}
                      >
                        {Object.keys(filterResult)?.map((title, index) => (
                          <MenuItem value={title} key={index} disabled={title == '(not set)' ? true :false }>
                            {title}
                          </MenuItem>
                        ))}
                      </Select>

                      <FormHelperText error>
                        {errors?.state?.message}
                      </FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel shrink>Region</InputLabel>
                      <Select
                        {...register("region")}
                        label="Category"
                        id="outlined-adornment-category"
                        value={region || []}
                        notched
                        multiple
                        error={errors?.region?.message}
                        onChange={(e) => setRegion(e.target.value)}
                      >
                        {state &&
                          state.flatMap(state => filterResult[state] || [])
                            .sort()
                            ?.map((title, index) => (
                              <MenuItem value={title} key={index} disabled={title === '(not set)' ? true :false }>
                                {title}
                              </MenuItem>
                            ))}

                      </Select>

                      <FormHelperText error>
                        {errors?.region?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Card>

                <Card>
                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel shrink>Resources</InputLabel>
                      <Select
                        {...register("module")}
                        label="Resources"
                        id="outlined-adornment-module"
                        value={communityModule ? communityModule : null}
                        notched
                        defaultValue={communityModules[0].value}
                        onChange={(e) => setCommunityModule(e.target.value)}
                      >
                        {communityModules?.map(({ name, value }, index) => (
                          <MenuItem value={value} key={index}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>

                      <FormHelperText error>
                        {errors?.category?.message}
                      </FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel shrink>School</InputLabel>
                      <Select
                        {...register("school")}
                        label="Schools"
                        id="outlined-adornment-module"
                        value={school || []}
                        notched
                        multiple
                        defaultValue={ schools?.result.length ? schools.result[0]?.id  :[]}
                        onChange={(e) => setSchool(e.target.value)}
                      >
                        {schools?.result.map((data, index) => (
                          <MenuItem value={data.id} key={index}>
                            {data.title}
                          </MenuItem>
                        ))}
                      </Select>

                      <FormHelperText error>
                        {errors?.category?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Card>

                <Card>
                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel shrink>Account Type</InputLabel>
                      <Select
                        {...register("account_type")}
                        label="Account Type"
                        id="outlined-adornment-module"
                        value={accountType || []}
                        notched
                        multiple
                        defaultValue={account_type[0].value || []}
                        onChange={(e) => setAccountType(e.target.value)}
                      >
                        {account_type?.map(({ name, value }, index) => (
                          <MenuItem value={value} key={index}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>

                      <FormHelperText error>
                        {errors?.category?.message}
                      </FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel shrink>Access Type</InputLabel>
                      <Select
                        {...register("access_type")}
                        label="Access Type"
                        id="outlined-adornment-module"
                        value={accessType || []}
                        notched
                        multiple
                        defaultValue={access[0].value}
                        onChange={(e) => setAccessType(e.target.value)}
                      >
                        {access?.map(({ name, value }, index) => (
                          <MenuItem value={value} key={index} disabled={name == 'Not Accessed' && communityModule == 'courses'} >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>

                      <FormHelperText error>
                        {errors?.category?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Card>


                <Card>
                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-email" shrink>
                        Subjects
                      </InputLabel>
                      <Controller
                        name="subjects"
                        control={control}
                        defaultValue={[]}
                        notched

                        render={({ field }) => (
                          <Select
                            {...field}
                            multiple
                            notched
                            label="subjects"
                            value={watch("subjects", [])}
                            onChange={(e) => {
                              const {
                                target: { value },
                              } = e;
                              setValue(
                                "subjects",
                                typeof value === "string"
                                  ? value.split(",")
                                  : value
                              );
                              field.onChange(e);
                            }}
                            input={
                              <OutlinedInput
                                id="select-multiple-chip"
                                label="subjects"
                              />
                            }
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip
                                    key={value}
                                    label={
                                      subjectList.find(
                                        (obj) => obj.id === value
                                      )?.title || ""
                                    }
                                  />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {subjectList.map((obj) => (
                              <MenuItem
                                key={obj.title}
                                value={obj.id}
                                style={getStyles(
                                  obj.title,
                                  watch("subjects", []),
                                  theme
                                )}
                              >
                                {obj.title}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.subjects && (
                        <span>{errors.subjects.message}</span>
                      )}
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-email" shrink>
                        Class
                      </InputLabel>
                      <Controller
                        name="classes"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                          <Select
                            {...field}
                            multiple
                            notched
                            value={watch("classes", [])}
                            onChange={(e) => {
                              const {
                                target: { value },
                              } = e;
                              setValue(
                                "classes",
                                typeof value === "string"
                                  ? value.split(",")
                                  : value
                              );
                              field.onChange(e);
                            }}
                            input={
                              <OutlinedInput
                                id="select-multiple-chip"
                                label="Class"
                              />
                            }
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip
                                    key={value}
                                    label={
                                      classList.find((obj) => obj.id === value)
                                        ?.title || ""
                                    }
                                  />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {classList.map((obj) => (
                              <MenuItem
                                key={obj.title}
                                value={obj.id}
                                style={getStyles(
                                  obj.title,
                                  watch("classes", []),
                                  theme
                                )}
                              >
                                {obj.title}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.classes && <span>{errors.classes.message}</span>}
                      <FormHelperText error>
                        {errors?.classes?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Card>
                <Stack spacing={2} my={2}>
                  {/* <Divider /> */}

                  {/* {accountToggle == "accountToggle" && */}
                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Title*</InputLabel>
                        <OutlinedInput
                          {...register("title")}
                          label="title"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.title?.message}
                        // defaultValue={result.title}
                        />
                        <FormHelperText error>
                          {errors?.title?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Message*</InputLabel>
                        <OutlinedInput
                          {...register("message")}
                          label="message"
                          notched
                          multiline
                          id="outlined-adornment-email"
                          error={errors?.message?.message}
                        // defaultValue={result.message}
                        />
                        <FormHelperText error>
                          {errors?.message?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Redirection Link</InputLabel>
                        <OutlinedInput
                          {...register("redirection_link")}
                          label="redirection_link"
                          notched
                          multiline
                          id="outlined-adornment-email"
                          error={errors?.redirection_link?.message}
                        />
                        <FormHelperText error>
                          {errors?.redirection_link?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  </>
                </Stack>
              </Card>
              <Button
                type="button"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
                onClick={() => setNotificationType('send')}
              >
                {"Send"}
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2, mr: 2 }}
                variant="contained"
                onClick={() => setNotificationType('save')}
              >
                {"Save"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
    </>
  );
}
