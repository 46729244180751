import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from "prop-types";
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import NodataImg from "../../assets/images/noData.png";
import ImageUploading from "react-images-uploading";
import { podcastDeleteApi } from "../../store/podcast/deletePodcast";
import { podcastApi } from "../../store/podcast/podcastList";
import { useLocation } from "react-router-dom";
import { getUnitApi } from "../../store/courses/unit/getUnit";
import { uploadScromApi } from "../../store/courses/scromUpload";
import { toast } from "react-hot-toast";
import { uploadImageApi } from "../../store/upload/uploadImage";
import { re_uploadScromApi } from "../../store/courses/re_updateCourse";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { resetPreviewUnit } from "../../store/courses/getScrom";

const schema = yup.object({
  scorm_title: yup.string().required("Please Provide Content Name"),
  has_score: yup.string().required("Please select one option"),
  weight: yup.number().typeError("Weight must be a number").integer("Weight cannot be decimal point")
})


export default function ScromUpload({ open, setOpen, unit_block_id, sub_section_block_id }) {
  const bulkPodcast = useSelector((state) => state?.bulkPodcast);
  const { result, loading } = bulkPodcast;
  const courseSectionDetailsAddData = useSelector((state) => state.courseSectionDetailsAdd);
  const { result: section_details } = courseSectionDetailsAddData
  const [duration, setDuration] = useState('0:00');
  const [selectedFile, setSelectedFile] = useState(null);
  const [loader, setLoder] = useState(false);
  const [images, setImages] = useState([]);
  const [imageLink, setImageLink] = useState(null);
  const [scromUrl, setScromUrl] = useState(null);
  const deletePodcast = useSelector((state) => state?.deletePodcast);
  const uploadImage = useSelector((state) => state?.uploadImage);
  const [uploadProgress, setUploadProgress] = useState(null);
  const ref = useRef(null);
  const location = useLocation();
  const course_id = new URLSearchParams(location.search).get('course_id');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scromU = useSelector((state) => state.scrom);
  
  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const getSecondsFromHHMMSS = (value) => {
    const [str1, str2, str3] = value.split(":");

    const val1 = Number(str1);
    const val2 = Number(str2);
    const val3 = Number(str3);

    if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
      return val1;
    }

    if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
      return val1 * 60 + val2;
    }

    if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
      return val1 * 60 * 60 + val2 * 60 + val3;
    }

    return 0;
  };

  const toHHMMSS = (secs) => {
    const secNum = parseInt(secs.toString(), 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor(secNum / 60) % 60;
    const seconds = secNum % 60;

    return [hours, minutes, seconds]
      .map((val) => (val < 10 ? `0${val}` : val))
      .filter((val, index) => val !== "00" || index > 0)
      .join(":")
      .replace(/^0/, "");
  };
  const handleDurtion = (e) => {
    //console.log(e.target.value)
    const value1 = e.target.value;
    const seconds = Math.max(0, getSecondsFromHHMMSS(value1));

    const time = toHHMMSS(seconds);
    //console.log(time)
    setDuration(time);
  }

  const uploadConfirm = async (data) => {
    dispatch(resetPreviewUnit())
    let formData = new FormData();
    formData.append("unit_block_id", unit_block_id);
    formData.append("scorm_title", data.scorm_title);
    formData.append("has_score", data.has_score);
    formData.append("weight", data.weight);
    formData.append("duration", data.duration);//hh:mm:ss
    if (selectedFile !== null && selectedFile?.size) {
      formData.append("file", selectedFile);
    }
    if (
      !uploadImage?.loading &&
      uploadImage.result && uploadImage.result.length > 0
    ) {
      formData.append("banner", uploadImage.result);
    } else {
      formData.append("banner", imageLink);
    }
    var obj = {
      course_id,
      file: formData,
      Scorm_title: section_details?.section_title
    }
    setLoder(true)
    let upload;

    if (scromU.result.length === 0) {//condition if there is no scrom uploaded yet so we need to call post API
      upload = await dispatch(uploadScromApi(obj));
    } else {//condition if there is already scrom uploaded so we need to call patch API
      upload = await dispatch(re_uploadScromApi(obj));
    }

    if (!upload?.payload?.error) {
      setLoder(false)
      toast.success(upload?.payload?.message, { id: 'toast', duration: 5000, position: 'top-center' })
      dispatch(getUnitApi({ course_id: course_id.replaceAll(" ", "+"), sub_section_block_id: sub_section_block_id }))
      setOpen(false);
      setSelectedFile(null);

    }
  };
  const onUploadImage = async (imageList, addUpdateIndex) => {
    if (imageList.length > 0) {
      let formData = new FormData();
      formData.append("file", imageList[0]?.file);
      await dispatch(uploadImageApi(formData));
    }
    setImages(imageList);
  };

  useEffect(() => {
    setImageLink(scromU.result.banner)
    if (scromU?.result?.scorm_url?.length) {
      setSelectedFile({
        name: "Scrom File",
      });
    }
    let defalutValues = {};
    defalutValues["scorm_title"] = scromU?.result?.scorm_title;
    defalutValues["has_score"] = scromU?.result?.has_score;
    defalutValues["weight"] = scromU?.result?.weight;
    defalutValues["duration"] = scromU?.result?.duration;
    reset(defalutValues);
  }, [scromU])

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}

      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal open={open} onClose={handleClose}>
        <Card className="modal" sx={{ width: "30%" }}>
          <Stack alignItems="flex-end">
            <IconButton onClick={handleClose}>
              <HighlightOffIcon />
            </IconButton>
          </Stack>
          <form id="outer_form" onSubmit={handleSubmit(uploadConfirm)}>
            <Stack alignItems="center" spacing={2}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel shrink>Title*</InputLabel>
                <OutlinedInput
                  {...register("scorm_title")}
                  label="Title"
                  notched
                  id="outlined-adornment-email"
                  error={errors?.scorm_title?.message}
                />
                <FormHelperText error>
                  {errors?.scorm_title?.message}
                </FormHelperText>
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <InputLabel shrink>Course Duration*</InputLabel>
                <OutlinedInput
                  // views={["minutes", "seconds"]}
                  {...register("duration")}
                  label="Course Duration"
                  name="duration"
                  id="outlined-adornment-email"
                  notched
                  value={duration}
                  onChange={(e) => handleDurtion(e)}
                  error={errors?.duration?.message}
                  defaultValue={
                    section_details?.duration
                  }
                />
                <FormHelperText error>
                  {errors?.duration?.message}
                </FormHelperText>

              </FormControl>
            </Stack>
            <Stack alignItems="center" spacing={2} marginY={1}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel shrink>Has Score*</InputLabel>
                <Select
                  {...register("has_score")}
                  label="Has Score*"
                  id="outlined-adornment-category"
                  value={watch("has_score")}
                  error={errors?.has_score?.message}
                  notched
                // defaultValue={}
                // onChange={(e) => setPacing(e.target.value)}
                >
                  <MenuItem value={1}>
                    Yes
                  </MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                  {/* <MenuItem value={"30"}>Thirty</MenuItem> */}
                </Select>

                <FormHelperText error>
                  {errors?.has_score?.message}
                </FormHelperText>
              </FormControl>
            </Stack>
            <Stack alignItems="center" spacing={2} marginY={1}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel shrink>Weight*</InputLabel>
                <OutlinedInput
                  {...register("weight")}
                  label="Weight*"
                  notched
                  id="outlined-adornment-email"
                  error={errors?.weight?.message}
                // defaultValue={coursesDetails.result[0]?.name}
                />
                <FormHelperText error>
                  {errors?.weight?.message}
                </FormHelperText>
              </FormControl>
            </Stack>

            <Stack alignItems="center" my={2} marginY={1}>
              <Typography variant="h5" fontWeight={600}>
                Please Select a file to upload ?
              </Typography>
            </Stack>
            <Stack alignItems="center" my={2}>
              {selectedFile ? (
                <>
                <Typography variant="h5" fontWeight={600}>
                  {selectedFile.name}
                  <IconButton onClick={() => setSelectedFile(null)}>
                    <ClearIcon />
                  </IconButton>
                </Typography>
              </>) : (
                <>
                  <input
                    accept=".zip"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    ref={ref}
                    onChange={(e) => setSelectedFile(e.target?.files[0])}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => ref.current.click()}
                  >
                    Upload File
                  </Button>
                </>
              )}
              {loading ? (
                <Box sx={{ width: "60%" }}>
                  <LinearProgress />
                </Box>
              ) : null}
            </Stack>
            <Stack alignItems="center" my={2}>
              {imageLink ? (
                <div className="image-item">
                  <img src={imageLink} alt="" width="320" height="180" />
                  <div className="image-item__btn-wrapper">
                    <Button
                      onClick={() => setImageLink(null)}
                      variant="contained"
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              ) : (
                <ImageUploading
                  // multiple
                  acceptType={['png', 'jpeg', 'jpg']}
                  value={images}
                  onChange={onUploadImage}
                  maxNumber={1}
                  // resolutionType="ratio"
                  dataURLKey="data_url"

                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    errors,
                  }) => (
                    <>
                      <div
                        className="upload__image-wrapper"
                        style={{ marginTop: "2%", marginBottom: "2%" }}
                      >
                        {imageList.length > 0 ? null : (
                          <Button
                            type="button"
                            variant="contained"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            Click or Drop Banner
                          </Button>
                        )}

                        {errors && (
                          <div>
                            {errors.maxNumber && (
                              <span>
                                Number of selected images exceed maxNumber
                              </span>
                            )}
                            {errors.acceptType && (
                              <span>
                                Your selected file type is not allow
                              </span>
                            )}
                            {errors.maxFileSize && (
                              <span>
                                Selected file size exceed maxFileSize
                              </span>
                            )}
                            {errors.resolution && (
                              <span>
                                Selected file is not match your desired
                                resolution i.e (328*150)
                              </span>
                            )}
                          </div>
                        )}
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <img
                              src={image["data_url"]}
                              alt=""
                              width="320" height="180"
                            />
                            <div className="image-item__btn-wrapper" style={{ display: "flex", justifyContent: "center" }}>

                              <Button
                                onClick={() => onImageUpdate(index)}
                                variant="contained"
                                sx={{ mr: 2 }}
                              >
                                Update
                              </Button>
                              <Button
                                onClick={() => onImageRemove(index)}
                                variant="contained"
                              >
                                Remove
                              </Button>

                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </ImageUploading>
              )}
            </Stack>
            <Stack sx={{ mt: 2 }} justifyContent="center" direction="row">
              <Button
                variant="outlined"
                onClick={() => handleClose()}
                sx={{ mr: 2 }}
              >
                Back
              </Button>
              <Stack direction="row" spacing={1}>
                <Button
                  variant="contained"
                  type="submit"
                  // onClick={() => handleUpdateForm()}
                  disabled={((!imageLink && images.length === 0) || (!selectedFile && !imageLink && uploadImage.result.length === 0)) ? true : false}
                >
                  Confirm
                </Button>
              </Stack>
            </Stack>
          </form>
        </Card>
      </Modal>
    </>
  );
}

ScromUpload.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  id: PropTypes.any,
  unit_block_id: PropTypes.any,
  sub_section_block_id: PropTypes.any,
};
