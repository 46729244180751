import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const teachingVideoDetailsApi = createAsyncThunk(
    'auth/teachingVideos/detail',
    async (id, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.teachingVideosAction(`${id}`, 1))
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "" }
const teachingVideoDetailsSlice = createSlice({
    name: 'teachingVideo detail',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {
        resetteachingVideo: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(teachingVideoDetailsApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(teachingVideoDetailsApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(teachingVideoDetailsApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { resetteachingVideo } = teachingVideoDetailsSlice.actions;
export const teachingVideoDetailsReducer = teachingVideoDetailsSlice.reducer