import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const categoryDetailsApi = createAsyncThunk(
    'auth/categorys/details',
    async (id, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.categoryAction(`${id}`, 1))
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "" }
const categoryDetailsSlice = createSlice({
    name: 'category details',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {
        resetCategory: () => intialState,
      },
    extraReducers: (builder) => {
        builder.addCase(categoryDetailsApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(categoryDetailsApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(categoryDetailsApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const {  resetCategory } = categoryDetailsSlice.actions;
export const categoryDetailsReducer = categoryDetailsSlice.reducer