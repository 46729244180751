import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const getParticularTLMAPI = createAsyncThunk(
  "auth/tlm/getPaticular",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.get(
        apiRoutes.learningMaterialAction(`${id}`, 1)
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const intialState = { result: [], loading: false, error: null, message: "" };
const getparticularDetailsSlice = createSlice({
  name: "get particular",
  initialState: { result: [], loading: false, error: null, message: "" },
  reducers: {
    resetgetparticular: () => intialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getParticularTLMAPI.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getParticularTLMAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(getParticularTLMAPI.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetgetparticular } = getparticularDetailsSlice.actions;
export const getparticularDetailsReducer = getparticularDetailsSlice.reducer;
