import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const courseDetailsApi = createAsyncThunk(
    'auth/courses/details',
    async (id, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.courseAction("", 0), { params: { course_id: id } })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "" }
const courseDetailsSlice = createSlice({
    name: 'course_Details',
    initialState: intialState,
    reducers: {
        reset: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(courseDetailsApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(courseDetailsApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(courseDetailsApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { reset } = courseDetailsSlice.actions;
export const courseDetailsReducer = courseDetailsSlice.reducer