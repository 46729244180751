import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const userCountApi = createAsyncThunk(
    'auth/userCounts',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.usersAction("count", 1))
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const userCountSlice = createSlice({
    name: 'userCount',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(userCountApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(userCountApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(userCountApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const userCountReducer = userCountSlice.reducer