import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxiosForm";
import apiRoutes from "../apiConfigs";

export const courseAddApi = createAsyncThunk(
  "auth/courses/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.post(
        apiRoutes.courseAction("add", 1),
        data
      )
      return response?.data;
    } catch (error) {

      return rejectWithValue(error?.response?.data);
    }
  }
);

const courseAddSlice = createSlice({
  name: "course_Add",
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(courseAddApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(courseAddApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(courseAddApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const courseAddReducer = courseAddSlice.reducer;
