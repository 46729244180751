import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const getCertificateParticularApi = createAsyncThunk(
  "auth/setting/particularcertificateGet",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.get(apiRoutes.courseCertificateAction(`${id}`, 0));
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const settingSlice = createSlice({
  name: "particularcertificate",
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(getCertificateParticularApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCertificateParticularApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(getCertificateParticularApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const particularcertificateReducer = settingSlice.reducer;
