import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ImageUploading from "react-images-uploading";
import DefaultProfileImg from "../../assets/images/defaultProfileImg.jpg";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import Chip from "@mui/material/Chip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Style from "../../styles/profile.module.scss";
import { TimeField } from "@mui/x-date-pickers";
import { toast } from "react-hot-toast";
import { shortVideoEditApi } from "../../store/shortVideo/editShortVideo";
import { shortVideoAddApi } from "../../store/shortVideo/addShortVideo";
import { categoryApi } from "../../store/categoryDetails/categoryList";
import { shortVideoDetailsApi } from "../../store/shortVideo/shortVideoDetail";
import { uploadImageApi } from "../../store/upload/uploadImage";
import RichTextEditor from "react-rte";
import { pushNotificationApi } from "../../store/notifications/pushNotification";



const schema = yup
  .object({
    title: yup.string().required("Please Provide Content Name").trim(),
    message: yup.string().required("Please Provide Video Link").trim(),
    // language: yup.string().required("Please Select Language "),
  })
  .required();

export default function PushNotifications() {
  const pushNotification = useSelector((state) => state?.pushNotification);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });


  const params = useParams();
  const navigate = useNavigate();
  const handleUpdateForm = async (data) => {
    dispatch(pushNotificationApi(data))
    if (!pushNotification?.loading) {
      navigate("/notifications");
    }
  };




  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                {/* <Button variant={accountToggle == "accountToggle" && 'contained'} onClick={() => handleAccountSettingToggle('accountToggle')}>Account Setting</Button>
                                <Button variant={accountToggle == "passwordToggle" && 'contained'} onClick={() => handleAccountSettingToggle('passwordToggle')}>Change Password</Button> */}
                {/* <Typography>Short Video Details</Typography> */}
                <Stack spacing={2} my={2}>
                  {/* <Divider /> */}

                  {/* {accountToggle == "accountToggle" && */}
                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Title</InputLabel>
                        <OutlinedInput
                          {...register("title")}
                          label="title"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.title?.message}
                        // defaultValue={result.title}
                        />
                        <FormHelperText error>
                          {errors?.title?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Message</InputLabel>
                        <OutlinedInput
                          {...register("message")}
                          label="message"
                          notched
                          multiline
                          id="outlined-adornment-email"
                          error={errors?.message?.message}
                        // defaultValue={result.message}
                        />
                        <FormHelperText error>
                          {errors?.message?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Redirection Link</InputLabel>
                        <OutlinedInput
                          {...register("redirection_link")}
                          label="redirection_link"
                          notched
                          multiline
                          id="outlined-adornment-email"
                          error={errors?.redirection_link?.message}
                        />
                        <FormHelperText error>
                          {errors?.redirection_link?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  </>

                </Stack>
              </Card>
              <Button
                type="button"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Send"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
    </>
  );
}
