import { addQnsBankApi } from "../../store/question-bank/add";
import { editQnsBankApi } from "../../store/question-bank/edit";
import { detailsQnsBankApi } from "../../store/question-bank/detail";

import styles from "../../styles/question-bank.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { InputLabel, Box, Button, Card, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Menu, MenuItem, Radio, RadioGroup, Stack, TextField, TextareaAutosize, Chip, OutlinedInput, Select } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { classGlobalApi } from "../../store/class/classListGlobal";
import { subjectGlobalApi } from "../../store/subject/subjectListGlobal";
import { topicsGlobalApi } from "../../store/topics/topicsListGlobal";
import { difficultyLevelApi } from "../../store/difficulty/difficultyList";
import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const schema = yup.object({
  language: yup.string().required(),
  board: yup.string().required('board is required'),
  marks: yup.number().required('Mark is required'),
  // topics: yup.array()
  // .of(yup.string())
  // .min(1, 'At least one topic is required')
  // .required(),
  sub_topics: yup.string().required('Sub-topic is required'),
  bloom: yup.string().required(),
  // difficulty_level: yup.string().required('Difficulty level is required'),
  question_type: yup.string().required(),
  classes: yup.array()
    .of(yup.string())
    .min(1, 'At least one class is required')
    .required(),
  subjects: yup.array()
    .of(yup.string())
    .min(1, 'At least one subject is required')
    .required(),
  topics: yup.array()
    .of(yup.string())
    .min(1, 'At least one topics is required')
    .required(),
  difficulty_level: yup.array()
    .of(yup.string())
    .min(1, 'At least one difficulty level is required')
    .required(),
  questions: yup.string().required('Question is required'),
  ans_options: yup.mixed()
    .test('is-string-or-array', 'Answer must be a string or an array of strings', function (value) {
      if (typeof value === 'string') {
        return true;
      }
      if (Array.isArray(value) && value.every(item => typeof item === 'string')) {
        return true;
      }
      return false;
    })
    .required('Answer is required'),
}).required()

const marksList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export default function AddQnsBank() {
  const theme = useTheme();
  const { result: classList, loading: classLoading } = useSelector((state) => state?.classGlobal);
  const { result: subjectList, loading: subjectLoading } = useSelector((state) => state?.subjectGlobal);
  const { result: difficultyLevelList, loading: difficultyLevelLoading } = useSelector((state) => state?.difficultyLevel);
  const { result: topicsList, loading: topicsLoading } = useSelector((state) => state?.topicsGlobal);
  const navigate = useNavigate()
  const params = useParams();
  const detailsQnsBank = useSelector((state) => state?.detailsQnsBank);
  const editQnsBank = useSelector((state) => state?.editQnsBank);

  const { result, loiding } = detailsQnsBank;
  const [classFilterEl, setClassFilterEl] = useState(null);
  const classFilterOpen = Boolean(classFilterEl)

  const [markEl, setMarkEl] = useState(null);
  const markOpen = Boolean(markEl)

  const [subjectEl, setSubjectEl] = useState(null);
  const subjectOpen = Boolean(subjectEl)

  const [topicEl, setTopicEl] = useState(null);
  const topicOpen = Boolean(topicEl)

  const [difflevelEl, setDifflevelEl] = useState(null);
  const difflevelOpen = Boolean(difflevelEl)

  const dispatch = useDispatch()

  const { register, handleSubmit, formState: { errors }, reset, watch, setValue, control } = useForm({
    resolver: yupResolver(schema), defaultValues: {
      classes: [],
      subjects: [],
      language: '',
      board: '',
      marks: 0,
      topics: [],
      sub_topics: '',
      bloom: 'creating',
      difficulty_level: [],
      question_type: 'subjective',
      questions: '',
      ans_options: '',
      correct_answer: ''
    }
  })
  const [ans_optionsOBJ, setAns_options] = useState({ opt1: "", opt2: "", opt3: "", opt4: "" })

  const handleMarkCloseMenu = (evt) => {
    setValue('marks', evt?.target?.getAttribute('data-menu-val'))
    setMarkEl(null)
  }

  const handleSelectQnsType = (evt, opt) => {
    setValue('question_type', evt?.target?.value)
  }

  const handleSelectQnsTypeOptions = (evt, opt) => {
    if (watch('question_type') == 'objective') {
      setAns_options({ ...ans_optionsOBJ, [opt]: evt.target.value })
    }
  }

  const handleSubmitqnsAns = async (data) => {
    let payload;
    let ans_ibj = watch('question_type') === 'subjective' ? [] : data?.ans_options
    payload = {
      ...data, qns_ans: [{ id: result?.questions && result?.questions[0]?.id, question: data?.questions, ans_options: ans_ibj, correct_answer: watch('question_type') === 'subjective' ? data?.ans_options : data?.correct_answer }]
    }
    if (params.id) {
      payload = { ...payload, id: params.id };

      delete payload.ans_options
      const res = await dispatch(editQnsBankApi(payload));
      if (!editQnsBank?.loading) {
        if (res?.payload?.error === false) {
          navigate("/question-bank");
        }
      }
    } else {

      dispatch(addQnsBankApi(payload)).then((action) => {
        if (!action?.payload?.error) {
          navigate("/question-bank")
        }
      })
    }
  }

  useEffect(() => {
    dispatch(classGlobalApi());
    dispatch(subjectGlobalApi());
    dispatch(topicsGlobalApi());
    dispatch(difficultyLevelApi());
    if (params.id && result) {
      dispatch(detailsQnsBankApi(params.id));
    }
  }, [dispatch]);

  useEffect(() => {
    let defalutValues = {};
    if (!params.id) {
      reset(defalutValues);
    } else {
      defalutValues["classes"] = result?.classes?.map(o => o?.id);
      defalutValues["subjects"] = result?.subjects?.map(o => o?.id);
      defalutValues["language"] = result?.language;
      defalutValues["board"] = result?.board;
      defalutValues["marks"] = result?.marks;
      defalutValues["topics"] = result?.topics?.map(o => o?.id);
      defalutValues["sub_topics"] = result?.sub_topics;
      defalutValues["bloom"] = result?.bloom;
      defalutValues["difficulty_level"] = result?.difficulty_level;
      defalutValues["question_type"] = result?.question_type;
      defalutValues["questions"] = result?.questions && result?.questions[0]?.question;
      if (result.questions) {
        defalutValues.ans_options = result.questions[0].ans_options.length > 0
          ? result.questions[0].ans_options
          : result?.questions[0]?.correct_answer;
        defalutValues["correct_answer"] = result?.questions[0]?.correct_answer;
      }
      reset(defalutValues);
    }
  }, [result]);
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <Box className={styles['add-form']}>
          <Card className={styles['form-main']}>
            <form onSubmit={handleSubmit(handleSubmitqnsAns)} className={`${styles["login-block"]} h-fitcontent`}>
              <Grid container spacing={6}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack spacing={2}>
                    <Box className={styles['outer-field-section']}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Class
                        </InputLabel>
                        <Controller
                          name="classes"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              multiple
                              value={watch('classes', [])}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                setValue('classes', typeof value === 'string' ? value.split(',') : value);
                                field.onChange(e);
                              }}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={classList.find(obj => obj.id === value)?.title || ""} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {classList.map((obj) => (
                                <MenuItem
                                  key={obj.title}
                                  value={obj.id}
                                  style={getStyles(obj.title, watch('classes', []), theme)}
                                >
                                  {obj.title}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        <FormHelperText error>
                          {errors?.classes?.message}
                        </FormHelperText>
                      </FormControl>
                    </Box>

                    <Box className={styles['outer-field-section']}>
                      <FormControl>
                        <FormLabel>Language</FormLabel>
                        <RadioGroup defaultValue="hindi" value={watch("language")} onChange={(event) => setValue("language", event?.target?.value)}>
                          <Grid container>
                            <Grid item lg={4} md={4} sm={4} xs={4}>
                              <FormControlLabel value='hindi' control={<Radio />} label="Hindi" />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={4}>
                              <FormControlLabel value='english' control={<Radio />} label="English" />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={4}>
                              <FormControlLabel value='both' control={<Radio />} label="Both" />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                        <FormHelperText error>
                          {errors?.language?.message}
                        </FormHelperText>
                      </FormControl>
                    </Box>


                    <Box className={styles['outer-field-section']}>
                      <FormLabel>Marks</FormLabel>
                      <Button
                        className="drop-down1"
                        fullWidth
                        variant="contained"
                        endIcon={markOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        onClick={(event) => {
                          setMarkEl(event.currentTarget)
                        }}
                      >
                        {watch("marks") || "Selcte Mark"}
                      </Button>
                      <Menu
                        elevation={0}
                        anchorEl={markEl}
                        open={markOpen} onClose={() => setMarkEl(null)}>
                        {marksList.map((value, index) => (<MenuItem key={index} data-menu-val={value} onClick={handleMarkCloseMenu}>{`Mark ${value}`}</MenuItem>))}
                      </Menu>
                      <FormHelperText error>{errors?.marks?.message}</FormHelperText>
                    </Box>


                    <FormControl>
                      <FormLabel>Sub-Topic</FormLabel>
                      <TextField
                        {...register("sub_topics")}
                        fullWidth
                        placeholder="Type here..."
                      />
                      <FormHelperText error>{errors?.sub_topics?.message}</FormHelperText>
                    </FormControl>


                    <Box className={styles['outer-field-section']}>
                      <FormControl>
                        <FormLabel>Bloom’s Taxonomy</FormLabel>
                        <RadioGroup defaultValue="creating" value={watch("bloom")} onChange={(event) => setValue("bloom", event?.target?.value)}>
                          <Grid container>
                            <Grid item lg={4} md={4} sm={4} xs={4}>
                              <FormControlLabel value='creating' control={<Radio />} label="Creating" />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={4}>
                              <FormControlLabel value='understanding' control={<Radio />} label="Understanding" />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={4}>
                              <FormControlLabel value='analysing' control={<Radio />} label="Analysing" />
                            </Grid>

                            <Grid item lg={4} md={4} sm={4} xs={4}>
                              <FormControlLabel value='evaluating' control={<Radio />} label="Evaluating" />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={4}>
                              <FormControlLabel value='remembering' control={<Radio />} label="Remembering" />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={4}>
                              <FormControlLabel value='applying' control={<Radio />} label="Applying" />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <FormControl>
                      <FormLabel>Please submit your Question here</FormLabel>
                      <TextareaAutosize minRows={6} placeholder="Type here..." {...register("questions")} />
                      <FormHelperText error>{errors?.questions?.message}</FormHelperText>
                    </FormControl>
                  </Stack>
                </Grid>


                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack spacing={2}>
                    <Box className={styles['outer-field-section']}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Subject
                        </InputLabel>
                        <Controller
                          name="subjects"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              multiple
                              value={watch('subjects', [])}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                setValue('subjects', typeof value === 'string' ? value.split(',') : value);
                                field.onChange(e);
                              }}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={subjectList.find(obj => obj.id === value)?.title || ""} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {subjectList.map((obj) => (
                                <MenuItem
                                  key={obj.title}
                                  value={obj.id}
                                  style={getStyles(obj.title, watch('subjects', []), theme)}
                                >
                                  {obj.title}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        <FormHelperText error>
                          {errors?.subjects?.message}
                        </FormHelperText>
                      </FormControl>
                    </Box>

                    <FormControl>
                      <FormLabel>Board</FormLabel>
                      <TextField
                        {...register("board")}
                        fullWidth
                        placeholder="Type here..."
                      />
                      <FormHelperText error>{errors?.board?.message}</FormHelperText>
                    </FormControl>


                    {/* <Box className={styles['outer-field-section']}>
                      <FormLabel>Topic</FormLabel>
                      <Button
                        className="drop-down1"
                        fullWidth
                        variant="contained"
                        endIcon={topicOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        onClick={(event) => {
                          setTopicEl(event.currentTarget)
                        }}
                      >
                        {watch('topics') || 'Select Topic'}
                      </Button>
                      <Menu
                        elevation={0}
                        anchorEl={topicEl}
                        open={topicOpen} onClose={() => setTopicEl(null)}>
                        <MenuItem data-menu-val='test1' onClick={handleTopicCloseMenu}>Test 1</MenuItem>
                        <MenuItem data-menu-val='test2' onClick={handleTopicCloseMenu}>Test 2</MenuItem>
                        <MenuItem data-menu-val='test3' onClick={handleTopicCloseMenu}>Test 3</MenuItem>
                      </Menu>
                      <FormHelperText error>{errors?.topics?.message}</FormHelperText>
                    </Box> */}
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-email" shrink>
                        Topics
                      </InputLabel>
                      <Controller
                        name="topics"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                          <Select
                            {...field}
                            multiple
                            value={watch('topics', [])}
                            onChange={(e) => {
                              const {
                                target: { value },
                              } = e;
                              setValue('topics', typeof value === 'string' ? value.split(',') : value);
                              field.onChange(e);
                            }}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip key={value} label={topicsList.find(obj => obj.id === value)?.title || ""} />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {topicsList.map((obj) => (
                              <MenuItem
                                key={obj.title}
                                value={obj.id}
                                style={getStyles(obj.title, watch('topics', []), theme)}
                              >
                                {obj.title}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      <FormHelperText error>
                        {errors?.topics?.message}
                      </FormHelperText>
                    </FormControl>

                    {/* <Box className={styles['outer-field-section']}>
                      <FormLabel>Difficulty Level</FormLabel>
                      <Button
                        className="drop-down1"
                        fullWidth
                        variant="contained"
                        endIcon={difflevelOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        onClick={(event) => {
                          setDifflevelEl(event.currentTarget)
                        }}
                      >
                        {watch('difficulty_level') || 'Select difficulty level'}
                      </Button>
                      <Menu
                        elevation={0}
                        anchorEl={difflevelEl}
                        open={difflevelOpen} onClose={() => setDifflevelEl(null)}>
                        <MenuItem data-menu-val='test1' onClick={handleDifflevelCloseMenu}>Test 1</MenuItem>
                        <MenuItem data-menu-val='test2' onClick={handleDifflevelCloseMenu}>Test 2</MenuItem>
                        <MenuItem data-menu-val='test3' onClick={handleDifflevelCloseMenu}>Test 3</MenuItem>
                      </Menu>
                      <FormHelperText error>{errors?.difficulty_level?.message}</FormHelperText>
                    </Box> */}
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-email" shrink>
                        Difficulty Level
                      </InputLabel>
                      <Controller
                        name="difficulty_level"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                          <Select
                            {...field}
                            multiple
                            value={watch('difficulty_level', [])}
                            onChange={(e) => {
                              const {
                                target: { value },
                              } = e;
                              setValue('difficulty_level', typeof value === 'string' ? value.split(',') : value);
                              field.onChange(e);
                            }}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip key={value} label={difficultyLevelList.find(obj => obj.id === value)?.title || ""} />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {difficultyLevelList.map((obj) => (
                              <MenuItem
                                key={obj.title}
                                value={obj.id}
                                style={getStyles(obj.title, watch('difficulty_level', []), theme)}
                              >
                                {obj.title}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      <FormHelperText error>
                        {errors?.difficulty_level?.message}
                      </FormHelperText>
                    </FormControl>

                    <Box className={styles['outer-field-section']}>
                      <FormControl>
                        <FormLabel>Choose question type</FormLabel>
                        <RadioGroup value={watch("question_type")} defaultValue="subjective">
                          <FormControlLabel onClick={handleSelectQnsType} value='objective' control={<Radio />} label="Objective" />
                          <FormControlLabel onClick={handleSelectQnsType} value='subjective' control={<Radio />} label="Subjective" />
                        </RadioGroup>
                      </FormControl>

                      {watch("question_type") === "objective"
                        ?
                        <Box width={'100%'}>
                          <FormControl sx={{ width: "100%" }}>
                            {Object.keys(ans_optionsOBJ).map((elem, idx) => {
                              return (
                                <>
                                  <FormLabel>{`Option ${idx + 1}`}</FormLabel>
                                  <TextField
                                    fullWidth
                                    placeholder="Type here..."
                                    onChange={(evt) => handleSelectQnsTypeOptions(evt, `opt${idx + 1}`)}
                                    {...register(`ans_options.${idx}`)}
                                  />
                                </>
                              )
                            })}
                            <FormHelperText error>{errors?.ans_options?.message}</FormHelperText>
                          </FormControl>
                        </Box>
                        : <></>
                      }
                    </Box>
                    {watch("question_type") === "objective"
                      ?
                      <Box width={'100%'}>
                        <FormControl sx={{ width: "100%" }}>

                          <TextField
                            fullWidth
                            placeholder="Correct answer"
                            {...register(`correct_answer`)}
                          />

                          <FormHelperText error>{errors?.correct_answer?.message}</FormHelperText>
                        </FormControl>
                      </Box>
                      : <></>
                    }
                    {watch("question_type") === "subjective" ?
                      <FormControl>
                        <FormLabel>Please submit your answer here</FormLabel>
                        <TextareaAutosize minRows={6} placeholder="Type here..." {...register("ans_options")} />
                        <FormHelperText error>{errors?.ans_options?.message}</FormHelperText>
                      </FormControl>
                      : <></>
                    }

                  </Stack>
                </Grid>

              </Grid>

              <Stack alignItems='center' justifyContent='center' my={2}>
                <Button variant="contained" type='submit'>Submit entry</Button>
              </Stack>
            </form>
          </Card>
        </Box>
      </Body>
    </>
  )
}