import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../../config/authAxios"
import apiRoutes from "../../apiConfigs"

export const publishUnitApi = createAsyncThunk(
    'courses/publish-sub-section-unit',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.patch(apiRoutes.courseUnitPublishAction(`${data.course_id}`, 0), {
                unit_block_id: data.unit_block_id,
                unit_title: data.unit_title,
                published: true
            })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "" }
const publishUnitSlice = createSlice({
    name: 'publish-sub-section',
    initialState: intialState,
    reducers: {
        reset: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(publishUnitApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(publishUnitApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(publishUnitApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { reset } = publishUnitSlice.actions;
export const publishUnitReducer = publishUnitSlice.reducer