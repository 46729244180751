import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs"
export const uploadCertificateApi = createAsyncThunk(
  'auth/topics/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.post(apiRoutes.globalsAction(`upload/s3-file`, 0), data, { headers: { "Content-Type": "multipart/form-data" } })
      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
      console.log("err", error)
    }
  }
)
const intialState = { result: "", loading: false, error: null, message: "" };
const uploadHandoutsSlice = createSlice({
  name: "certificate_upload",
  initialState: { result: "", loading: false, error: null, message: "" },
  reducers: {
    resetCertificateUpload: () => intialState,
  },
  extraReducers: (builder) => {
    builder.addCase(uploadCertificateApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(uploadCertificateApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(uploadCertificateApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetCertificateUpload } = uploadHandoutsSlice.actions;
export const uploadCertificateReducer = uploadHandoutsSlice.reducer;
