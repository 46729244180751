import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Chip, Paper, Stack, Typography } from "@mui/material";
import { DataGrid, getGridStringOperators, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState, useContext, useCallback } from "react";
import { ThemeContext } from '../../context/ThemeContext';
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NodataImg from "../../assets/images/noData.png";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { resetSplash } from "../../store/cms/getSplashScreen";
import { manageSplashScreenApi } from "../../store/cms/manageSplashScreen";
import DeleteSplashScreen from "./DeleteSplashScreen";


export default function ManageSplashScreen() {
  const manageSplashScreen = useSelector((state) => state?.manageSplashScreen);
  const { result, order_category, loading, total } = manageSplashScreen;
  const { paginationModel, setPaginationModel } = useContext(ThemeContext)

  const [queryOptions, setQueryOptions] = useState({});

  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const order_deatils = JSON.parse(localStorage.getItem("order_deatils"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState(null);
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);

  const columns = [
    {
      field: "id",
      headerName: "Banner Id",
      width: 150,
      filterable: false,
      sortable: false,
    },
    {
      field: "order",
      headerName: "Order",
      width: 200,
      filterable: false,
      sortable: false,
    },
    {
      field: "page_key",
      headerName: "Page",
      width: 200,
      filterable: false,
      sortable: false,
    },
    {
      field: "image",
      headerName: "Banner Image",
      width: 250,
      filterable: false,
      sortable: false,
      renderCell: (params) => (<img width={200} height={100} src={params.row.image} alt='banner image' />)
    },
    {
      field: "is_active",
      headerName: "Status",
      width: 150,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <>
          {params?.row?.is_visible ? (
            <Chip color="success" label="Active" variant="outlined" />
          ) : (
            <Chip color="error" label="InActive" variant="outlined" />
          )}
        </>
      ),
    },
    {
      field: "update_banner",
      headerName: "Update banner",
      width: 150,
      filterable: false,
      sortable: false,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            color="info"
            endIcon={<EditIcon />}
            label="Available" variant="outlined"
            onClick={() => handleNavigation(row.id)}
          >
            Edit
          </Button>
        </>
      ),
    },
    {
      field: "delete_banner",
      headerName: "Delete banner",
      width: 150,
      filterable: false,
      sortable: false,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            endIcon={<DeleteOutlineIcon />}
            color="error"
            variant="outlined"
            style={{ marginLeft: "5px" }}
            onClick={() => {
              setNotSelectedProductModel(true)
              setDeleteId(row.id)
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];


  const handleNavigation = async (id) => {

    navigate(`/edit-splash-screen/${id}`)
  };
  const addToCartHandler = async () => {
    navigate("/handle-splash-screen");
  };

  const backToCatHandler = () => {
    navigate("/category");
  };

  useEffect(() => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    const query = {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
    };
    dispatch(manageSplashScreenApi(query));
    dispatch(resetSplash());
  }, [dispatch, queryOptions, paginationModel]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>


        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Manage Banner</Typography>
        </Stack>

        <Stack sx={{ my: 2 }} justifyContent="space-between" direction="row">
          <Stack direction="row" spacing={1}>
            <Button
              onClick={addToCartHandler}
              variant="contained"
            >
              Add New Banner
            </Button>
          </Stack>
        </Stack>

        <Paper elevation={0}>
          <DataGrid
            loading={loading}
            rowCount={total}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            pageSize={paginationModel?.pageSize}
            onPageSizeChange={(newPageSize) => {
              setPaginationModel({
                ...paginationModel,
                pageSize: newPageSize,
              })
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pagination
            paginationMode="server"
            sx={{ height: 500 }}
            getRowId={(row) => row.id}
            rows={result}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            filterMode="server"
            onFilterModelChange={onFilterChange}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}

          />
        </Paper>

      </Body>

      <DeleteSplashScreen
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        id={deleteId}
      />
    </>
  );
}
