import { Alert } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';


export default function SnakBar({ type, error }) {
    return (
        <Alert severity={type}>{error}</Alert>
    )
}

SnakBar.propTypes = {
    type: PropTypes.any,
    error: PropTypes.any,
};