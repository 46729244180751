import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import NotFoundImg from "../assets/images/notFoundImg.png"

export default function NotRoute() {

    const navigate = useNavigate()

    const handeBackTOHome = () => {
        navigate("/home")
    }
    const handeBackTOLogin = () => {
        localStorage.clear()
        navigate("/login")
    }

    return (
        <>
            <Stack alignItems='center' justifyContent='center' height={'100vh'}>
                <img src={NotFoundImg} alt='not found image' />
                <Stack spacing={2} alignItems='center'>
                    <Typography variant='h3' fontWeight={600}>Page Not Found</Typography>
                    <Box>
                        <Typography textAlign='center'>{`We're sorry the page you requested could not be`}</Typography>
                        <Typography textAlign='center'>found please go back to the homepage</Typography>
                    </Box>
                    <Stack direction='row' spacing={2}>
                        <Button startIcon={<KeyboardBackspaceIcon />} onClick={handeBackTOHome} variant='contained'>Back to Home</Button>
                        <Button startIcon={<KeyboardBackspaceIcon />} onClick={handeBackTOLogin} variant='contained'>Back to Login</Button>
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}
