import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const topicsDeleteApi = createAsyncThunk(
    'auth/topics/delete',
    async (id, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.delete(apiRoutes.topicsAction(`${id}`, 1))
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const topicsDeleteSlice = createSlice({
    name: 'topics delete',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(topicsDeleteApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(topicsDeleteApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(topicsDeleteApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const topicsDeleteReducer = topicsDeleteSlice.reducer