import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NodataImg from "../../assets/images/noData.png";
import { shortVideoApi } from "../../store/shortVideo/shortVideoList";
import { shortVideoDeleteApi } from "../../store/shortVideo/deleteShortVideo";
import { schoolResourceDeleteApi } from "../../store/schoolResources/deleteSchoolResource";
import { schoolResourceApi } from "../../store/schoolResources/schoolResourceList";

export default function DeleteSchoolResource({ open, setOpen,id }) {
  const shortVideoDelete = useSelector((state) => state?.schoolResourceDelete);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleClose = () => setOpen(false);

  const deleteCourseConfirm = async () => {
    await dispatch(
      schoolResourceDeleteApi(id)
    );
    setOpen(false);
    if (!shortVideoDelete?.loading) {
      dispatch(
        schoolResourceApi()
      );
      navigate("/school-resource");
    }

  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card className="modal" sx={{ width: "30%" }}>
          <Stack alignItems="flex-end">
            <IconButton onClick={handleClose}>
              <HighlightOffIcon />
            </IconButton>
          </Stack>
          <Stack alignItems="center" my={2}>
            <Typography variant="h5" fontWeight={600}>
              Are you sure you want to delete this school resource ?
            </Typography>
            <Typography variant="h5" fontWeight={600}>
              Please click confirm
            </Typography>
          </Stack>
          

          <Stack sx={{ mt: 2 }} justifyContent="center" direction="row">
            <Button variant="outlined" onClick={() => setOpen(false)} sx={{ mr: 2 }}>
              Back
            </Button>
            <Stack direction="row" spacing={1}>
              {/* <Button variant='outlined' onClick={notRequiredOrderHandler}>Not Required</Button> */}
              <Button variant="contained" onClick={deleteCourseConfirm}>
                Confirm
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>
    </>
  );
}

DeleteSchoolResource.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  id: PropTypes.any,
};

