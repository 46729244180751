import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ImageUploading from "react-images-uploading";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { SketchPicker } from "react-color";
import Style from "../../styles/profile.module.scss";
import { toast } from "react-hot-toast";
import { splashScreenEditApi } from "../../store/cms/editSplashScreen";
import { splashScreenAddApi } from "../../store/cms/addSplashScreen";
import { splashScreenDetailsApi } from "../../store/cms/getSplashScreen";
import { uploadImageApi } from "../../store/upload/uploadImage";

const schema = yup
  .object({
    order: yup.string().required("Please Provide Order "),
    page: yup.string().required("Please Provide Page "),
    status: yup.string().required("Please Provide Status "),
    language: yup.string().required("Please Select Language "),
    navigation: yup.string().required("Please Select Navigation "),
  })
  .required();

export default function AddSplashScreen() {
  const splashScreenEdit = useSelector((state) => state?.splashScreenEdit);
  const splashScreenAdd = useSelector((state) => state?.splashScreenAdd);
  const uploadImage = useSelector((state) => state?.uploadImage);
  const { result, loiding } = splashScreenEdit;
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });

  const [orderNum, setOrderNum] = useState();
  const [visible, setVisible] = useState();
  const [navigation, setNavigation] = useState();
  const [page, setPage] = useState();
  const [images, setImages] = React.useState([]);
  const [colour, setColour] = useState();
  const [imageLink, setImageLink] = useState();
  const [showColorPicker, setShowColorPicker] = useState();
  const ref = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const [language, setLanguage] = useState();
  const onUploadImage = async (imageList, addUpdateIndex) => {
    if (imageList.length > 0) {
      let formData = new FormData();
      //console.log(imageList, imageList);
      formData.append("file", imageList[0]?.file);
      await dispatch(uploadImageApi(formData));
    }
    setImages(imageList);
  };
  const pageEdit = useSelector((state) => state?.cmsEdit);
  const splashScreenDetails = useSelector(
    (state) => state?.splashScreenDetails
  );
  useEffect(() => {
    //console.log(params.id);
    if (params.id) {
      dispatch(splashScreenDetailsApi(params.id));
    }
  }, [dispatch]);
  const handleChangeComplete = (color) => {
    setColour(color.hex);
  };
  const handleUpdateForm = async (value) => {
    var newObj;
    if (page === "Splash") {
      if (colour) {
        newObj = { ...value, bg_color: colour,
          page_key: value.page,
          is_visible: value.status,
          order: value.order,
          navigation: value.navigation,
        };
      } else {
        toast.error("Please Provide Color", {
          id: "toast",
          duration: 1000,
          position: "top-center",
        });
        return;
      }
    }else {
       newObj = {
      ...value,
      page_key: value.page,
      is_visible: value.status,
      order: value.order,
      navigation: value.navigation,
    };
    }

   

    if (imageLink) {
      newObj = { ...newObj, image: imageLink };
    } else if (
      images.length > 0 &&
      !uploadImage?.loading &&
      uploadImage.result
    ) {
      newObj = { ...newObj, image: uploadImage.result };
    } else {
      toast.error("Please Upload An Image", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      return;
    }

    console.log("image", newObj);
    if (params.id) {
      newObj = { ...newObj, id: params.id };
      await dispatch(splashScreenEditApi(newObj));
      if (!splashScreenEdit?.loading) {
        navigate("/manage-splash-screen");
      }
    } else {
      await dispatch(splashScreenAddApi(newObj));
      if (!splashScreenAdd?.loading) {
        navigate("/manage-splash-screen");
      }
    }
  };
  useEffect(() => {
    let defalutValues = {};
    defalutValues["link"] = splashScreenDetails.result?.link;
    defalutValues["language"] = splashScreenDetails.result?.language;
    defalutValues["order"] = splashScreenDetails.result?.order;
    defalutValues["page"] = splashScreenDetails.result?.page_key;
    defalutValues["navigation_enum"] = splashScreenDetails.result?.navigation;
    defalutValues["status"] = splashScreenDetails.result?.is_visible;

    reset(defalutValues);
  }, [splashScreenDetails]);

  useEffect(() => {
    if (splashScreenDetails.result?.image) {
      setImageLink(splashScreenDetails.result?.image);
      setOrderNum(splashScreenDetails.result?.order);
      setPage(splashScreenDetails.result?.page_key);
      setVisible(splashScreenDetails.result?.is_visible);
      setLanguage(splashScreenDetails.result?.language);
      setNavigation(splashScreenDetails.result?.navigation);
    }
    if (splashScreenDetails.result?.bg_color != null) {
      setColour(splashScreenDetails.result?.bg_color);
    }

  }, [splashScreenDetails]);
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />

                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-order" shrink>
                          Order
                        </InputLabel>
                        <Select
                          {...register("order")}
                          label="Order"
                          notched
                          id="outlined-adornment-order"
                          value={orderNum ? orderNum : null}
                          error={!!errors?.order?.message}
                          onChange={(e) => setOrderNum(e.target.value)}
                          // defaultValue={orderNum}
                          //   onChange={handleChange}
                        >
                          <MenuItem value={"1"} key={1}>
                            1
                          </MenuItem>
                          <MenuItem value={"2"} key={2}>
                            2
                          </MenuItem>
                          <MenuItem value={"3"} key={3}>
                            3
                          </MenuItem>
                          <MenuItem value={"4"} key={4}>
                            4
                          </MenuItem>
                          <MenuItem value={"5"} key={5}>
                            5
                          </MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.order?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Status
                        </InputLabel>
                        <Select
                          {...register("status")}
                          label="Status"
                          // notched={orderNum ? true : false}
                          notched
                          id="outlined-adornment-category"
                          // defaultValue={visible ? visible : null}
                          error={!!errors?.status?.message}
                          value={visible ? visible : null}
                          onChange={(e) => setVisible(e.target.value)}
                        >
                          <MenuItem value={"true"}>Active</MenuItem>
                          <MenuItem value={"false"}>InActive</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.status?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Page
                        </InputLabel>
                        <Select
                          {...register("page")}
                          label="Page"
                          id="outlined-adornment-category"
                          notched
                          error={!!errors?.page?.message}
                          value={page ? page : null}
                          // defaultValue={page ? page : null}
                          onChange={(e) => setPage(e.target.value)}
                        >
                          <MenuItem value={"Partner"}>Partner</MenuItem>
                          <MenuItem value={"Podcast"}>Podcast</MenuItem>
                          <MenuItem value={"Courses"}>Courses</MenuItem>
                          <MenuItem value={"Short Video"}>Short Video</MenuItem>
                          <MenuItem value={"Splash"}>Splash</MenuItem>
                          <MenuItem value={"Home Web"}>Home Web</MenuItem>
                          <MenuItem value={"Home Mobile"}>Home Mobile</MenuItem>
                          <MenuItem value={"Sunday Polls"}>Sunday Polls</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.page?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Navigation
                        </InputLabel>
                        <Select
                          {...register("navigation")}
                          label="Navigation"
                          notched
                          id="outlined-adornment-category"
                          error={!!errors?.navigation?.message}
                          value={navigation ? navigation : null}
                          onChange={(e) => setNavigation(e.target.value)}
                        >
                          <MenuItem value={"inapp"}>In App</MenuItem>
                          <MenuItem value={"outside"}>Outside</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.navigation?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Link</InputLabel>
                        <OutlinedInput
                          {...register("link")}
                          label="Link"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.link?.message}
                          defaultValue={result.video_url}
                        />
                        <FormHelperText error>
                          {errors?.link?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Language
                        </InputLabel>
                        <Select
                          {...register("language")}
                          label="Language"
                          notched
                          id="outlined-adornment-category"
                          error={!!errors?.language?.message}
                          value={language ? language : null}
                          onChange={(e) => setLanguage(e.target.value)}
                        >
                          <MenuItem value={"hindi"}>Hindi</MenuItem>
                          <MenuItem value={"english"}>English</MenuItem>
                          <MenuItem value={"both"}>Both</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.language?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  </>
                  {page === "Splash" ? (
                    <>
                      <InputLabel> BackGround Color</InputLabel>

                      <Stack direction="row" spacing={2}>
                        {showColorPicker ? (
                          <>
                            <div style={{ width: "30%" }}>
                              <SketchPicker
                                color={colour}
                                onChange={(e) => handleChangeComplete(e)}
                              />
                            </div>
                          </>
                        ) : (
                          <div style={{ width: "100%" }}>
                            Bg Colour is {colour}
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: colour,
                                marginLeft: "2%",
                              }}
                              onClick={() => setShowColorPicker(true)}
                            >
                              {colour ? "Change" : "Select"}
                            </Button>
                          </div>
                        )}
                      </Stack>
                      {showColorPicker ? (
                        <div style={{ width: "30%" }}>
                          <Button
                            variant="contained"
                            onClick={() => setShowColorPicker(false)}
                          >
                            Set
                          </Button>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                  <InputLabel>
                    Banner Image 
                  </InputLabel>
                  {imageLink ? (
                    <div className="image-item">
                      <img
                        src={imageLink}
                        alt=""
                        width={page === "Splash" ? 200 : 320}
                        height={page === "Splash" ? 200 : 180}
                      />
                      <div className="image-item__btn-wrapper">
                        <Button
                          onClick={() => setImageLink(null)}
                          variant="contained"
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <ImageUploading
                      // multiple
                      acceptType={['png','jpeg','jpg']}
                      value={images}
                      onChange={onUploadImage}
                      maxNumber={1}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors,
                      }) => (
                        <>
                          <div className="upload__image-wrapper">
                            {imageList.length > 0 ? null : (
                              <Button
                                type="button"
                                variant="contained"
                                style={
                                  isDragging ? { color: "red" } : undefined
                                }
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                Click or Drop Image here
                              </Button>
                            )}

                            {errors && (
                              <div>
                                {errors.maxNumber && (
                                  <span>
                                    Number of selected images exceed maxNumber
                                  </span>
                                )}
                                {errors.acceptType && (
                                  <span>
                                    Your selected file type is not allow
                                  </span>
                                )}
                                {errors.maxFileSize && (
                                  <span>
                                    Selected file size exceed maxFileSize
                                  </span>
                                )}
                                {errors.resolution && (
                                  <span>
                                    Selected file is not match your desired
                                    resolution
                                  </span>
                                )}
                              </div>
                            )}
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width={page === "Splash" ? 200 : 320}
                                  height={page === "Splash" ? 200 : 180}
                                />
                                <div className="image-item__btn-wrapper">
                                  <Button
                                    onClick={() => onImageUpdate(index)}
                                    variant="contained"
                                    sx={{ mr: 2 }}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    onClick={() => onImageRemove(index)}
                                    variant="contained"
                                  >
                                    Remove
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </ImageUploading>
                  )}
                </Stack>
              </Card>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate("/banner")}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
    </>
  );
}
