import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs";

export const splashScreenAddApi = createAsyncThunk(
  "auth/splashScreens/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.post(apiRoutes.splashScreenList, data)
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const splashScreenAddSlice = createSlice({
  name: "splashScreen Add",
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(splashScreenAddApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(splashScreenAddApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(splashScreenAddApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const splashScreenAddReducer = splashScreenAddSlice.reducer;
