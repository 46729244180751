import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const addQnsBankApi = createAsyncThunk(
    'qns-bank/add',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(apiRoutes.questionBankAction("add", 0), data)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const addQnsBankSlice = createSlice({
    name: 'qns-bank/add',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(addQnsBankApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(addQnsBankApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(addQnsBankApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const addQnsBankReducer = addQnsBankSlice.reducer