import { Box, Button, Paper } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { default as React } from 'react'
import NodataImg from "../../../assets/images/noData.png"


export default function NotificationList() {

    const columns = [
        {
            field: 'notification',
            headerName: 'Notification',
            flex: 1
        },
        {
            field: 'details',
            headerName: 'View Details',
            width: 200,
        },
    ]


    return (
        <Paper elevation={0}>
            <DataGrid
                sx={{
                    '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
                        backgroundColor: "#85949F !important",
                        color: "white",
                        fontWeight: 700,
                        maxHeight: 500,
                    },
                }}
                getRowId={(row) => row.id}    
                rows={[]}
                columns={columns}
                disableRowSelectionOnClick
                slots={{
                    noRowsOverlay: () => <Box className='noData'><img src={NodataImg} alt='no data found image' /></Box>,
                }}
                pagination
                rowsPerPageOptions={[25, 50, 100]}
            />
        </Paper>
    )
}
