import { Box, Button, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { DataGrid, getGridStringOperators, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState, useCallback, useContext } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NodataImg from "../../assets/images/noData.png";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { approveQnsApi } from "../../store/question-bank/approve";
import { qnsBankListApi } from "../../store/question-bank/list";
import BulkQsnBank from "./BulkQsnBank";
import DeleteQnsBank from "./DeleteQnsBank";
import DoneIcon from '@mui/icons-material/Done';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ThemeContext } from '../../context/ThemeContext';
import CustomGridToolbar from "../../components/CustomGridToolbar";
import apiRoutes from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";

export default function ManageQnsBank() {

  const qnsBankList = useSelector((state) => state?.qnsBankList);
  const { result, loading, total } = qnsBankList;
  const [filteredResult, setRessult] = useState(result);
  const [qnsBnkBluk, setQnsBnkBulk] = useState(false);
  const [isDownload, setIsDownload] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (result && result?.length > 0) {
      let filteredResult = result.filter(o => o.questions.length > 0)
      setRessult(filteredResult)
    }
  }, [result])
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { paginationModel, setPaginationModel } = useContext(ThemeContext)
  const [queryOptions, setQueryOptions] = useState({});

  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "Question Id",
      filterable: false,
      sortable: false,
      width: 80,
    },
    {
      field: "subjects",
      headerName: "Subjects",
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (data) => (<>
        <Typography sx={{ textWrap: "wrap" }}>{Array.isArray(data?.row?.subjects) && data?.row?.subjects.map((obj, index) => (`${obj['title']}, `))}</Typography>
      </>)
    },
    {
      field: "topics",
      headerName: "Topics",
      filterable: false,
      sortable: false,
      width: 150,
      renderCell: (data) => (<>
        <Typography sx={{ textWrap: "wrap" }}>{Array.isArray(data?.row?.topics) && data?.row?.topics.map((obj, index) => (`${obj['title']}, `))}</Typography>
      </>)
    },
    {
      field: "classes",
      headerName: "Classes",
      filterable: false,
      sortable: false,
      width: 70,
      renderCell: (data) => (<>
        <Typography sx={{ textWrap: "wrap" }}>{Array.isArray(data?.row?.classes) && data?.row?.classes.map((obj, index) => (`${obj['title']}, `))}</Typography>
      </>)
    },
    {
      field: "questions",
      headerName: "Questions & Answers",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 1200,
      disableExport: true,
      renderCell: (params) => (
        <>
          <Grid container rowSpacing={1}>
            {params?.row?.questions?.map((elm, idx) => {
              return (
                <>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Typography sx={{ overflowWrap: "break-word" }}>{elm.question}</Typography>
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    {params?.row?.question_type === "subjective" ? (<Typography sx={{ overflowWrap: "break-word", textWrap: "pretty" }}>{elm?.correct_answer}</Typography>) : (<Typography sx={{ overflowWrap: "break-word", textWrap: "pretty" }}>{elm.ans_options?.join(",")}</Typography>)}

                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Button color="success" label="Available" variant="outlined"
                      onClick={() => navigate(`/question-bank/edit/${params?.row.id}`)}
                    >
                      Edit
                    </Button>&nbsp;&nbsp;
                    <Button
                      color="success"
                      variant="outlined"
                      disabled={elm?.is_approved}
                      endIcon={elm.is_approved ? <DoneIcon /> : <ArrowForwardIosIcon />}
                      onClick={() => {
                        approveQnsHandler(elm)
                      }}
                    >
                      {elm.is_approved ? 'Approved' : 'Approve'}
                    </Button>&nbsp;&nbsp;
                    <Button
                      color="error"
                      variant="outlined"
                      onClick={() => {
                        setNotSelectedProductModel(true)
                        setDeleteId(elm.id)
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                </>
              )
            })}
          </Grid>
        </>
      ),
    },
  ];

  const addQnsHandler = async () => {
    navigate("/question-bank/add");
  };


  const approveQnsHandler = async (elem) => {
    const aproveQns = await dispatch(approveQnsApi({ question_id: elem?.id, is_approved: true }))
    if (!aproveQns?.payload?.error) {
      dispatch(qnsBankListApi())
    }
  }
  const getRowSpacing = useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);


  useEffect(() => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    const query = {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || "questions" || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
    };
    dispatch(qnsBankListApi(query))
  }, [dispatch, queryOptions, paginationModel]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>


        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Question Bank List</Typography>
        </Stack>
        <Stack sx={{ my: 2 }} justifyContent="space-between" direction="row">
          <Stack direction="row" spacing={1}>

            <Button
              onClick={addQnsHandler}
              variant="contained"
            >
              New Question
            </Button>
            <Button
              onClick={() => setQnsBnkBulk(true)}
              variant="contained"
            >
              Add Bulk Question Bank Questions
            </Button>
          </Stack>
        </Stack>

        <Paper elevation={0}>
          <DataGrid
            loading={loading}
            rowCount={total}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            pageSize={paginationModel?.pageSize}
            onPageSizeChange={(newPageSize) => {
              setPaginationModel({
                ...paginationModel,
                pageSize: newPageSize,
              })
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pagination
            paginationMode="server"
            sx={{ height: 500 }}
            getRowId={(row) => row.id}
            rows={result}
            getRowHeight={() => 'auto'}
            getRowSpacing={getRowSpacing}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            filterMode="server"
            onFilterModelChange={onFilterChange}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: (props) =>
                (CustomGridToolbar({ ...props, moduleType: "questionBankManagement", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.questionBankAction("add", 0) })),
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Paper>
      </Body>

      <DeleteQnsBank
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        id={deleteId}
      />

      <BulkQsnBank
        open={qnsBnkBluk}
        setOpen={setQnsBnkBulk}

      />
      <BackdropUI open={isDownload} />
    </>
  );
}
