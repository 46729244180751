import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { SketchPicker } from "react-color";
import ImageUploading from "react-images-uploading";
import DefaultProfileImg from "../../assets/images/defaultProfileImg.jpg";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import Chip from "@mui/material/Chip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimeField } from "@mui/x-date-pickers";
import { toast } from "react-hot-toast";
import { subjectEditApi } from "../../store/subject/subjectEdit";
import { subjectAddApi } from "../../store/subject/subjectAdd";
import { subjectDetailsApi } from "../../store/subject/subjectDetail";
const schema = yup
  .object({
    // heading: yup.string().required("Please Provide title Name"),

    title: yup.string().required("Please Provide A category ").trim(),
  })
  .required();

export default function SubjectEdit() {
  const subjectDetails = useSelector((state) => state?.subjectDetails);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const params = useParams();
  const navigate = useNavigate();
  const subjectAdd = useSelector((state) => state?.subjectAdd);
  const subjectEdit = useSelector((state) => state?.subjectEdit);
  useEffect(() => {
    if (params.id) {
      dispatch(subjectDetailsApi(params.id));
    }
  }, [dispatch]);

  const handleUpdateForm = async (value) => {
    var newObj;
    if (value.title_hn === '')
      delete value.title_hn
    if (params.id) {
      newObj = { ...value, id: params.id };
      await dispatch(subjectEditApi(newObj));
      if (!subjectEdit?.loading) {
        navigate("/subject-management");
      }
    } else {
      await dispatch(subjectAddApi(value));
      if (!subjectAdd?.loading) {
        navigate("/subject-management");
      }
    }
  };

  useEffect(() => {
    let defalutValues = {};
    if (params.id) {
      defalutValues["title"] = subjectDetails.result?.title;
      defalutValues["title_hn"] = subjectDetails.result?.title_hn;
    }
    reset(defalutValues);
  }, [subjectDetails]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Subject Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />

                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-category" shrink>
                        Title
                      </InputLabel>
                      <OutlinedInput
                        {...register("title")}
                        label="title"
                        notched
                        id="outlined-adornment-category"
                        error={errors?.title?.message}
                      />
                      <FormHelperText error>
                        {errors?.title?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-category" shrink>
                        Title (Hindi)
                      </InputLabel>
                      <OutlinedInput
                        {...register("title_hn")}
                        label="title_hn"
                        notched
                        id="outlined-adornment-category"
                        error={errors?.title_hn?.message}
                      />
                      <FormHelperText error>
                        {errors?.title_hn?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Stack>
              </Card>
              <Button
                type="button"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
    </>
  );
}
