import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";

export default function LineChartComponent({ type, module, data, height, width, lineState }) {


    return (
        <ResponsiveContainer width={"100%"} height={height}>

            <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                <YAxis />
                <Tooltip />
                <Legend />
                {module === "teacherRegister" && (<>
                    {lineState?.l1 &&
                        <Line
                            type="monotone"
                            dataKey="Teachers"
                            stroke="#8884d8"
                            activeDot={{ r: 8 }}
                        />
                    }
                    {lineState?.l2 &&
                        <Line
                            type="monotone"
                            dataKey="New user"
                            stroke="#f55b00" />
                    }
                    {lineState?.l3 &&
                        <Line
                            type="monotone"
                            dataKey="Parents"
                            stroke="#2c95d2" />
                    }
                    {lineState?.l4 &&
                        <Line
                            type="monotone"
                            dataKey="Educators"
                            stroke="#a52cd2" />
                    }

                </>)}
                {!module && (<>
                    <Line
                        type="monotone"
                        dataKey="pv"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                    />
                    <Line
                        type="monotone"
                        dataKey="uv"
                        stroke="#82ca9d" />
                    <Line
                        type="monotone"
                        dataKey="nv"
                        stroke="#f55b00" />
                </>)}
                {module === "activevsavgtimespent1" && (<>
                    {lineState?.l1 &&
                        <Line
                            type="monotone"
                            dataKey="Average Time Spent"
                            stroke="#8884d8"
                            activeDot={{ r: 8 }}
                        />
                    }
                </>)}
                {module === "activevsavgtimespent2" && (<>
                    {lineState?.l2 &&
                        <Line
                            type="monotone"
                            dataKey="Active user"
                            stroke="#8884d8"
                            activeDot={{ r: 8 }}
                        />
                    }
                </>)}
                {module === "consumption_graded" && (<>
                    {lineState?.l1 &&
                        <Line
                            type="monotone"
                            dataKey="NoOfResources"
                            stroke="#8884d8"
                            activeDot={{ r: 8 }}
                        />
                    }
                </>)}
                {module === "consumption_non_graded" && (<>
                    {lineState?.l1 &&
                        <Line
                            type="monotone"
                            dataKey="live"
                            stroke="#8884d8"
                            activeDot={{ r: 8 }}
                        />
                    }
                    {lineState?.l2 &&
                        <Line
                            type="monotone"
                            dataKey="completed"
                            stroke="#f55b00"
                            activeDot={{ r: 8 }}
                        />
                    }
                </>)}
                {module === "community_consumption" && (<>
                    {lineState?.l1 &&
                        <Line
                            type="monotone"
                            dataKey="ActiveUsers"
                            stroke="#8884d8"
                            activeDot={{ r: 8 }}
                        />
                    }
                    {lineState?.l2 &&
                        <Line
                            type="monotone"
                            dataKey="AverageSessionDuration"
                            stroke="#f55b00" />
                    }
                    {lineState?.l3 &&
                        <Line
                            type="monotone"
                            dataKey="ScreenPageViews"
                            stroke="#2c95d2" />
                    }
                    {lineState?.l4 &&
                        <Line
                            type="monotone"
                            dataKey="Sessions"
                            stroke="#a52cd2" />
                    }

                </>)}
            </LineChart>
        </ResponsiveContainer>
    )
}