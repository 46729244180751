import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../../config/authAxios"
import apiRoutes from "../../apiConfigs"

export const getUnitApi = createAsyncThunk(
    'courses/get-sub-section-unit',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.courseUnitAction(`${data.course_id}`, 0),
                {
                    params: {
                        sub_section_block_id: data.sub_section_block_id,
                    }
                })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)


const intialState = { result: [], loading: false, error: null, message: "" }
const getUnitSlice = createSlice({
    name: 'get-sub-section',
    initialState: intialState,
    reducers: {
        resetPreview: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getUnitApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getUnitApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(getUnitApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { resetPreview } = getUnitSlice.actions;
export const getUnitReducer = getUnitSlice.reducer