import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../../config/authAxios"
import apiRoutes from "../../apiConfigs"

export const updateSubSectionApi = createAsyncThunk(
    'courses/update-sub-section',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.patch(apiRoutes.courseSubSecAction(`${data.course_id}`, 0), {
                sub_section_title: data.title,
                sub_section_block_id: data.subsection_block_id,
                published: data.published
            })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "" }
const updateSubSectionSlice = createSlice({
    name: 'update-sub-section',
    initialState: intialState,
    reducers: {
        reset: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(updateSubSectionApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(updateSubSectionApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(updateSubSectionApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { reset } = updateSubSectionSlice.actions;
export const updateSubSectionReducer = updateSubSectionSlice.reducer