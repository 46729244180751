import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const schoolResourceAddApi = createAsyncThunk(
    'auth/schoolResources/add',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(apiRoutes.schoolProcessList, data)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const schoolResourceAddSlice = createSlice({
    name: 'schoolResource Add',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(schoolResourceAddApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(schoolResourceAddApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(schoolResourceAddApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const schoolResourceAddReducer = schoolResourceAddSlice.reducer