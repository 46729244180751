import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const podcastDeleteApi = createAsyncThunk(
    'auth/podcasts/delete',
    async (id, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.delete(apiRoutes.podcastsAction(`${id}`, 1),


            )
            return response.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const podcastDeleteSlice = createSlice({
    name: 'podcast delete',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(podcastDeleteApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(podcastDeleteApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(podcastDeleteApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const podcastDeleteReducer = podcastDeleteSlice.reducer