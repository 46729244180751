import { Box } from '@mui/material'
import React, { useContext } from 'react'
import Style from "../styles/body.module.scss"
import { ThemeContext } from '../context/ThemeContext'
import PropTypes from 'prop-types'


export default function Body({ children }) {

    const { drawerShow } = useContext(ThemeContext)

    return (
        <Box className={drawerShow ? Style.open : Style.close}>{children}</Box>
    )
}



Body.propTypes = {
    children: PropTypes.any,
};