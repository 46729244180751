import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const faqDetailsApi = createAsyncThunk(
    'auth/faqs/detail',
    async (id, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.faqAction(`single/${id}`, 0))
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "" }
const faqDetailsSlice = createSlice({
    name: 'faq detail',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {
        resetfaq: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(faqDetailsApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(faqDetailsApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(faqDetailsApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { resetfaq } = faqDetailsSlice.actions;
export const faqDetailsReducer = faqDetailsSlice.reducer