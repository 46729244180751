import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { categoryApi } from "../../store/categoryDetails/categoryList";
import { faqAddApi } from "../../store/faq/faqAdd";
import { faqDetailsApi } from "../../store/faq/faqDetails";
import { faqEditApi } from "../../store/faq/faqEdit";
const schema = yup
  .object({
    question: yup.string().required("Please Provide Question").trim(),
    answers: yup.string().required("Please Provide answers").trim(),
    category: yup.string().required("Please Provide A Catergory "),
    qns_order: yup.string().required("Please Provide Order "),
  })
  .required();

export default function AddFaq() {
  const faqAdd = useSelector((state) => state?.faqAdd);
  const faqEdit = useSelector((state) => state?.faqEdit);
  const faqDetail = useSelector((state) => state?.faqDetail);
  const { result, loiding } = faqDetail;
  const {
    result: {
      question,
      video_url,
      mobile_number,
      duration,
      points,
      meta_tags,
      category,
    },
  } = faqDetail;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });

  const [meta, setMeta] = useState("");
  const [metaTag, setMetaTag] = useState([]);
  const [images, setImages] = React.useState([]);
  const [value, setValue] = useState();
  const [cat, setCat] = useState();
  const [orderNum, setOrderNum] = useState();
  const [imageLink, setImageLink] = useState();
  const [language, setLanguage] = useState();
  const ref = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const categories = useSelector((state) => state?.categories);
  useEffect(() => {
    dispatch(categoryApi());
    if (params.id) {
      dispatch(faqDetailsApi(params.id));
    }
  }, []);

  const onUploadImage = (imageList, addUpdateIndex) => {
    //console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const handleUpdateForm = async (data) => {
    var newObj;
    newObj = { ...data };
    if (params.id) {
      newObj = { ...newObj, id: params.id };
      await dispatch(faqEditApi(newObj));
      if (!faqEdit?.loading && !faqEdit?.error) {
        navigate("/faq");

      }
    } else {
      await dispatch(faqAddApi(newObj));
      if (!faqAdd?.loading && !faqAdd?.error) {
        navigate("/faq");

      }
    }

  };

  const handleChipDelete = (element) => {
    //console.log(element);
    // metaTag.remove(value);
    var local = metaTag;
    setMetaTag(local.filter((item) => item !== element));
  };
  const handleChipAdd = (value) => {
    setMetaTag([...metaTag, meta]);
    setMeta(null);
    ref.current.value = null;
    //console.log(ref);
  };
  useEffect(() => {
    setOrderNum(faqDetail.result?.qns_order);
    setCat(faqDetail.result.category);
    setLanguage(faqDetail.result?.language)
  }, [faqDetail.result]);
  useEffect(() => {
    let defalutValues = {};
    defalutValues["question"] = result?.question;
    defalutValues["answers"] = result?.answers;
    defalutValues["points"] = result?.points;
    defalutValues["qns_order"] = result?.qns_order;
    defalutValues["category"] = result?.category;
    defalutValues["language"] = result?.language;

    reset(defalutValues);
  }, [result]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                {/* <Button variant={accountToggle == "accountToggle" && 'contained'} onClick={() => handleAccountSettingToggle('accountToggle')}>Account Setting</Button>
                                <Button variant={accountToggle == "passwordToggle" && 'contained'} onClick={() => handleAccountSettingToggle('passwordToggle')}>Change Password</Button> */}
                <Typography>FAQ Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />

                  {/* {accountToggle == "accountToggle" && */}
                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Question</InputLabel>
                        <OutlinedInput
                          {...register("question")}
                          label="question"
                          notched
                          multiline
                          id="outlined-adornment-email"
                          error={errors?.question?.message}
                          defaultValue={result.question}
                        />
                        <FormHelperText error>
                          {errors?.question?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>

                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Category</InputLabel>
                        <Select
                          {...register("category")}
                          label="Category"
                          notched
                          id="outlined-adornment-category"
                          value={cat ? cat : null}
                          error={errors?.points?.message}
                          defaultValue={result.category}
                          onChange={(e) => setCat(e.target.value)}
                        >
                          <MenuItem value={"courses"} key={1}>
                            {"courses"}
                          </MenuItem>
                          <MenuItem value={"short_videos"} key={2}>
                            {"short videos"}
                          </MenuItem>
                          <MenuItem value={"podcasts"} key={3}>
                            {"podcasts"}
                          </MenuItem>
                          <MenuItem value={"webinars"} key={4}>
                            {"webinars"}
                          </MenuItem>
                          <MenuItem value={"staffroom"} key={5}>
                            {"staffroom"}
                          </MenuItem>
                          <MenuItem value={"sunday_polls"} key={6}>
                            {"sunday polls"}
                          </MenuItem>
                          <MenuItem value={"quiz"} key={7}>
                            {"quiz"}
                          </MenuItem>
                          <MenuItem value={"teaching_kits"} key={8}>
                            {"teaching kits"}
                          </MenuItem>
                          <MenuItem value={"impact_story"} key={9}>
                            {"impact story"}
                          </MenuItem>
                          <MenuItem value={"events"} key={10}>
                            {"events"}
                          </MenuItem>
                          <MenuItem value={"reward_recognition"} key={11}>
                            {"reward & recognition"}
                          </MenuItem>
                          <MenuItem value={"partner"} key={12}>
                            {"partner"}
                          </MenuItem>
                          <MenuItem value={"Scores_leaderboards"} key={13}>
                            {"Scores & leaderboards"}
                          </MenuItem>
                        </Select>


                        <FormHelperText error>
                          {errors?.category?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-order" shrink>
                          Order
                        </InputLabel>
                        <Select
                          {...register("qns_order")}
                          label="Order"
                          notched
                          id="outlined-adornment-order"
                          value={orderNum ? orderNum : null}
                          error={!!errors?.qns_order?.message}
                          onChange={(e) => setOrderNum(e.target.value)}
                        // defaultValue={orderNum}
                        //   onChange={handleChange}
                        >
                          <MenuItem value={"1"} key={1}>
                            1
                          </MenuItem>
                          <MenuItem value={"2"} key={2}>
                            2
                          </MenuItem>
                          <MenuItem value={"3"} key={3}>
                            3
                          </MenuItem>
                          <MenuItem value={"4"} key={4}>
                            4
                          </MenuItem>
                          <MenuItem value={"5"} key={5}>
                            5
                          </MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.qns_order?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Language
                        </InputLabel>
                        <Select
                          {...register("language")}
                          label="Language"
                          notched
                          id="outlined-adornment-category"
                          error={!!errors?.language?.message}
                          value={language ? language : null}
                          onChange={(e) => setLanguage(e.target.value)}
                        >
                          <MenuItem value={"hindi"}>Hindi</MenuItem>
                          <MenuItem value={"english"}>English</MenuItem>
                          <MenuItem value={"both"}>Both</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.language?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Answers</InputLabel>
                        <OutlinedInput
                          {...register("answers")}
                          label="answers"
                          notched
                          multiline
                          id="outlined-adornment-email"
                          error={errors?.answers?.message}
                          defaultValue={result?.answers}
                        />
                        <FormHelperText error>
                          {errors?.answers?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  </>
                  {/* } */}
                </Stack>
              </Card>
              <Button
                type="button"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
    </>
  );
}
