import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const deleteSectionApi = createAsyncThunk(
    'courses/delete-section',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.delete(apiRoutes.courseSecAction(`${data.course_id}`, 0), {
                params: {
                    section_block_id: data?.section_block_id
                }
            })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const intialState = { result: [], loading: false, error: null, message: "" }
const deleteSectionSlice = createSlice({
    name: 'delete-section',
    initialState: intialState,
    reducers: {
        reset: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(deleteSectionApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteSectionApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(deleteSectionApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { reset } = deleteSectionSlice.actions;
export const deleteSectionReducer = deleteSectionSlice.reducer