import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const quizEditApi = createAsyncThunk(
    'auth/quiz/edit',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.patch(apiRoutes.quizAction(`${data.id}`, 0), data)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const quizEditSlice = createSlice({
    name: 'quiz Edit',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(quizEditApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(quizEditApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(quizEditApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const quizEditReducer = quizEditSlice.reducer