import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ImageUploading from "react-images-uploading";
import DefaultProfileImg from "../../assets/images/defaultProfileImg.jpg";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import Chip from "@mui/material/Chip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Style from "../../styles/profile.module.scss";
import RichTextEditor from "react-rte";
import { toast } from "react-hot-toast";
import { pageEditApi } from "../../store/cms/editPage";
import { pageDetailsApi } from "../../store/cms/getAboutUs";
const schema = yup
  .object({
    heading: yup.string().required("Please Provide Content Name").trim(),
    about_language: yup.string().required("Please Provide language"),

  })
  .required();

export default function EditPage() {


  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [accountToggle, setAccountToggle] = useState("accountToggle");
  const [meta, setMeta] = useState("");
  const [metaTag, setMetaTag] = useState([]);
  const [images, setImages] = React.useState([]);
  const [value, setValue] = useState('');
  const [about_language, setAbout_language] = useState("english");
  const ref = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const [editorValue, setEditorValue] = React.useState(
    RichTextEditor.createValueFromString(value, "markdown")
  );
  const pageEdit = useSelector((state) => state?.cmsEdit);
  const pageDetails = useSelector((state) => state?.pageDetails);
  const {
    result: { content_name, video_link, content, heading, stars, category },
  } = pageDetails;
  useEffect(() => {
    //console.log(params.name);
    dispatch(pageDetailsApi({name:params.name,lang:"en"}));
  }, []);
  useEffect(() => {
    //console.log(params.name);
    dispatch(pageDetailsApi({name:params.name,lang:about_language ==="english" ? "en" :"hi"}));
  }, [about_language]);

  const handleUpdateForm = async (value1) => {
    var newObj;
   
    newObj = { ...value1, page: params.name,content:editorValue.toString("html") };
    console.log(newObj)
    await dispatch(pageEditApi(newObj));
    if (!pageEdit?.loading) {
      navigate("/manage-pages");
  
    }
  };
  const handleChange = (value) => {
    setEditorValue(value);
    setValue(value.toString("html"));
  };
  useEffect(() => {
    let defalutValues = {};

    defalutValues["heading"] = pageDetails.result[0]?.heading;
    setEditorValue( RichTextEditor.createValueFromString(pageDetails.result[0]?.content, "html"))
    
    reset(defalutValues);
  }, [pageDetails]);
  
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Page Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />

                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Page Heading
                        </InputLabel>
                        <OutlinedInput
                          {...register("heading")}
                          label="Content Name"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.heading?.message}
                          defaultValue={pageDetails.result[0]?.heading}
                        />
                        <FormHelperText error>
                          {errors?.heading?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  </>
                  <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Language
                        </InputLabel>
                        <Select
                          {...register("about_language")}
                          label="language"
                          notched
                          id="outlined-adornment-category"
                          error={!!errors?.about_language?.message}
                          value={about_language ? about_language : null}
                          onChange={(e) => setAbout_language(e.target.value)}
                        >
                          <MenuItem value={"hindi"}>Hindi</MenuItem>
                          <MenuItem value={"english"}>English</MenuItem>
                          <MenuItem value={"both"}>Both</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.about_language?.message}
                        </FormHelperText>
                      </FormControl>
            
                      <InputLabel htmlFor="outlined-adornment-email" shrink>
                        Page Content
                      </InputLabel>
                      <RichTextEditor
                        value={editorValue}
                        onChange={handleChange}
                        required
                        id="body-text"
                        name="bodyText"
                        type="string"
                        multiline
                        variant="filled"
                        style={{ minHeight: 410 }}
                      />
                    
                </Stack>
              </Card>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
    </>
  );
}
