import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0CAEEB",
    },
  },

  typography: {
    subtitle2: {
      fontSize: 10,
    },
    body1: {
      fontSize: 15,
    },
    p: {
      fontSize: 10,
      fontWeight: 400,
    },
    a: {
      fontSize: 12,
      cursor: "pointer",
    },
    h6: {
      fontSize: 13,
    },
    h5: {
      fontSize: 16,
    },
    h4: {
      fontSize: 20,
    },
    h3: {
      fontSize: 24,
    },
    h2: {
      fontSize: 26,
    },
    h1: {
      fontSize: 30,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          // backgroundColor:'#FF0000',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#05141F",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: 10,
          borderRadius: 5,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "none",
          color: "#fff",
        },
      },
      defaultProps: {
        disableGutters: true,
        elevation: 0,
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 1000,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 1,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background: "#fff",
        },
      },
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        sizeSmall: true,
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        size: 25
      }
    },
  },
});


