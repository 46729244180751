import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NodataImg from "../../assets/images/noData.png";
import { podcastDeleteApi } from "../../store/podcast/deletePodcast";
import { podcastApi } from "../../store/podcast/podcastList";
import { entryExitApi } from "../../store/entryExit/entryExitList";
import { entryExitDeleteApi } from "../../store/entryExit/deleteEntryExit";

export default function DeleteEntryExit({ open, setOpen,id }) {

  const deletePodcast = useSelector((state) => state?.deletePodcast);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleClose = () => setOpen(false);

  const deleteCourseConfirm = async () => {
    await dispatch(
      entryExitDeleteApi(id)
    );
    setOpen(false);
    if (!deletePodcast?.loading) {
     
        dispatch(entryExitApi());
      
      navigate("/entry-exit-management");
    }

  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card className="modal" sx={{ width: "30%" }}>
          <Stack alignItems="flex-end">
            <IconButton onClick={handleClose}>
              <HighlightOffIcon />
            </IconButton>
          </Stack>
          <Stack alignItems="center" my={2}>
            <Typography variant="h5" fontWeight={600}>
              Are you sure you want to delete this  ?
            </Typography>
            <Typography variant="h5" fontWeight={600}>
              Please click confirm
            </Typography>
          </Stack>
          <Stack sx={{ mt: 2 }} justifyContent="center" direction="row">
            <Button variant="outlined" onClick={() => setOpen(false)} sx={{ mr: 2 }}>
              Back
            </Button>
            <Stack direction="row" spacing={1}>
              <Button variant="contained" onClick={deleteCourseConfirm}>
                Confirm
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>
    </>
  );
}

DeleteEntryExit.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  id: PropTypes.any,
};
