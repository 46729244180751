import { Box, Button, Chip, Paper, Stack, Typography } from "@mui/material";
import { DataGrid, getGridStringOperators, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState, useContext, useCallback } from "react";
import { ThemeContext } from '../../context/ThemeContext';
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import NodataImg from "../../assets/images/noData.png";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate } from "react-router-dom";
import { subjectApi } from "../../store/subject/subjectList";
import SubjectDelete from "./SubjectDelete";
import CustomGridToolbar from "../../components/CustomGridToolbar";
import apiRoutes from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";
export default function SubjectList() {
  const subjectList = useSelector((state) => state?.subject);
  const { result, loading, total } = subjectList;
  const [deleteId, setDeleteId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { paginationModel, setPaginationModel } = useContext(ThemeContext)
  const [selectedRow, setSelectedRow] = useState([]);
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);
  const [isDownload, setIsDownload] = useState(false)

  const handleNavigation = async (id) => {
    navigate(`/edit-subject/${id}`);
  };
  const columns = [
    {
      field: "title",
      headerName: "Subject Name",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 400,

    },

    {
      field: "title_hn",
      headerName: "Subject Name (hindi)",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 400,

    },
    {
      field: "update_subject",
      headerName: "Update Subject",
      filterable: false,
      sortable: false,
      width: 150,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            color="info"
            endIcon={<EditIcon />}
            label="Available" variant="outlined"
            onClick={() => handleNavigation(row.id)}
          >
            Edit
          </Button>
        </>
      ),
    },
    {
      field: "delete_subject",
      headerName: "Delete Subject",
      filterable: false,
      sortable: false,
      width: 150,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            endIcon={<DeleteOutlineIcon />}
            color="error"
            variant="outlined"
            style={{ marginLeft: "5px" }}
            onClick={() => {
              setNotSelectedProductModel(true)
              setDeleteId(row.id)
            }}
          >
            Delete
          </Button>
        </>
      ),
    },


  ];

  const addToCartHandler = async () => {

    navigate("/add-subject")
  };

  const backToCatHandler = () => {
    navigate("/subject-management");
  };

  const [queryOptions, setQueryOptions] = useState({});

  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  useEffect(() => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    const query = {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
    };
    dispatch(subjectApi(query));

  }, [dispatch, queryOptions, paginationModel]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>


        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Subject List</Typography>
        </Stack>

        <Stack sx={{ my: 2 }} justifyContent="space-between" direction="row">

          <Stack direction="row" spacing={1}>

            <Button
              onClick={addToCartHandler}
              variant="contained"

            >
              Add New Subject
            </Button>
          </Stack>
        </Stack>

        <Paper elevation={0}>
          <DataGrid
            loading={loading}
            rowCount={total}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            pageSize={paginationModel?.pageSize}
            onPageSizeChange={(newPageSize) => {
              setPaginationModel({
                ...paginationModel,
                pageSize: newPageSize,
              })
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pagination
            paginationMode="server"
            sx={{ height: 500 }}
            getRowId={(row) => row.id}
            rows={result}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            filterMode="server"
            onFilterModelChange={onFilterChange}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: (props) =>
                (CustomGridToolbar({ ...props, moduleType: "subjectList", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.subjectList })),
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Paper>

      </Body>

      <SubjectDelete
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        setSelectedRow={setSelectedRow}
        id={deleteId}
      />
      <BackdropUI open={isDownload} />
      </>
  );
}
