import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from '@mui/icons-material/Add';
import { Button, Card, CircularProgress, FormControl, FormHelperText, InputLabel, OutlinedInput, Stack, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { courseSubSectionAddApi } from "../../store/courses/sub-section/addSubSection";
import { courseSubSectionDeleteApi } from "../../store/courses/sub-section/deleteSubSection";
import { getCourseSubSectionApi } from "../../store/courses/sub-section/getSubSection";
import { updateSubSectionApi } from "../../store/courses/sub-section/updateSubSection";
import InfoIcon from '@mui/icons-material/Info';


let newArray = []

const schema = yup.object({
    sub_section_title: yup.string().required(),
    published: yup.boolean().required()
})

export default function AddCourseSubSection() {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: { published: true }
    });

    const getCourseSubSectionData = useSelector((state) => state.getCourseSubSection);
    const { result: courBlockResult } = getCourseSubSectionData
    const courseSubSectionAddData = useSelector((state) => state.courseSubSectionAdd);
    const { loading: courseSubSectionLoading } = courseSubSectionAddData

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();

    const course_id = new URLSearchParams(location.search).get('course_id');
    const section_block_id = new URLSearchParams(location.search).get('section_block_id');


    const startDate = useState(dayjs().toJSON())[0]
    const [addSubSectionVisible, setAddSubSectionVisible] = useState(false)
    const [subSectionData, setSubSectionData] = useState([])


    const handleAddSubSection = async (data) => {
        const courseSectinAdd = await dispatch(courseSubSectionAddApi({
            course_id: course_id.replaceAll(" ", "+"),
            start_date: startDate,
            section_block_id: section_block_id.replaceAll(" ", "+"),
            published: data.published,
            sub_section_title: data.sub_section_title
        }))
        if (!courseSectinAdd?.payload?.action?.error) {
            setAddSubSectionVisible(false)
            dispatch(getCourseSubSectionApi({ course_id: course_id.replaceAll(" ", "+"), section_block_id: section_block_id }))
            reset()
        }
    }

    const handleDeleteSubSection = async (data) => {
        const courseSectinDelete = await dispatch(courseSubSectionDeleteApi(data))
        if (!courseSectinDelete?.payload?.action?.error) {
            setAddSubSectionVisible(false)
            dispatch(getCourseSubSectionApi({ course_id: course_id.replaceAll(" ", "+"), section_block_id: section_block_id }))
            reset()
        }
    }


    const updateSubSection = async (data) => {
        const updateSubSectionData = await dispatch(updateSubSectionApi(data))
        if (!updateSubSectionData?.payload?.action?.error) {
            setAddSubSectionVisible(false)
            dispatch(getCourseSubSectionApi({ course_id: course_id.replaceAll(" ", "+"), section_block_id: section_block_id }))
            reset()
        }
    }


    const handleNavigateSubSectionUnit = (data) => {
        navigate(`/edit-course/sub-section/units?course_id=${course_id.replaceAll(" ", "+")}&sub_section_block_id=${data.subsection_block_id}`)
    }

    const handleChangeSection = (event, idx, data) => {
        newArray = [...subSectionData]
        newArray[idx] = { ...data, title: event.target.value }
        setSubSectionData(newArray)
    }


    useEffect(() => {
        dispatch(getCourseSubSectionApi({ course_id: course_id.replaceAll(" ", "+"), section_block_id: section_block_id }))
    }, [dispatch])

    useEffect(() => {
        setSubSectionData(courBlockResult)
    }, [courBlockResult])



    return (
        <>

            <Appbar />
            <Drawer />
            <Body>
                <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
                    <HiTemplate size={30} />
                    <Typography>Courses Sub Section List</Typography>
                    {/* page tooltip */}
                    <Tooltip arrow title="You cannot add more than one sub-section within a single section!.">
                        <InfoIcon color="primary" />
                    </Tooltip>
                </Stack>

                {subSectionData.length < 1 ? <Card p={2} m={3} sx={{ background: '#e1f2f2', mt: 2 }}>
                    <Button sx={{ mb: 2 }} disabled={addSubSectionVisible} variant="contained" endIcon={<AddIcon />} onClick={() => setAddSubSectionVisible(true)}>Add New Sub Section </Button>

                    {addSubSectionVisible
                        ? <Fragment>
                            <Stack spacing={2}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel> Sub Section Title</InputLabel>
                                    <OutlinedInput {...register("sub_section_title")} error={errors?.sub_section_title?.message} label="Sub Section Title" />
                                    <FormHelperText error>{errors?.sub_section_title?.message}</FormHelperText>
                                </FormControl>
                                <Stack direction='row' spacing={2}>
                                    <Button disabled={courseSubSectionLoading} color="success" variant="contained" onClick={handleSubmit(handleAddSubSection)} sx={{ mb: 2 }}>{courseSubSectionLoading ? <CircularProgress /> : "Publish"}</Button>
                                </Stack>
                            </Stack>
                        </Fragment>
                        : <></>
                    }
                </Card> : null}

                {subSectionData?.map((data, idx) => (
                    <Card key={idx} p={2} m={3} sx={{ background: '#e1f2f2', mt: 2 }}>
                        <Fragment key={idx}>
                            <Stack spacing={2}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel> Sub Section Title</InputLabel>
                                    <OutlinedInput label="Sub Section Title" defaultValue={data?.title} onChange={(event) => handleChangeSection(event, idx, data)} />
                                </FormControl>
                                <Stack direction='row' spacing={2}>
                                    <Button disabled={subSectionData?.length >= 1} color="success" variant="contained" sx={{ mb: 2 }} onClick={() => updateSubSection(data)}>{"Update"}</Button>
                                    <Button color="error" variant="contained" sx={{ mb: 2 }} onClick={() => handleDeleteSubSection(data)}>{'Delete'}</Button>
                                    <Button color="info" variant="contained" sx={{ mb: 2 }} onClick={() => handleNavigateSubSectionUnit(data)}>Add Units</Button>
                                </Stack>
                            </Stack>
                        </Fragment>
                    </Card >
                ))}
            </Body>
        </>
    )
}