import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const subjectGlobalApi = createAsyncThunk(
    'auth/subjects/list/global',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.publicapiAction("subject", 1), { params: data })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const subjectGlobalSlice = createSlice({
    name: 'auth/subjects/list/global',
    initialState: { result: [], loading: false, error: null, message: "", total: 0 },
    extraReducers: (builder) => {
        builder.addCase(subjectGlobalApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(subjectGlobalApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
            state.total = action?.payload?.count;
        })
        builder.addCase(subjectGlobalApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const subjectGlobalReducer = subjectGlobalSlice.reducer