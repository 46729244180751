import { yupResolver } from "@hookform/resolvers/yup";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, Card, FormControl, FormHelperText, IconButton, InputLabel, Modal, OutlinedInput, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { resetPasswordVerifyOtpApi } from "../../store/auth/resetPasswordVerifyOtp";
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types'


const schema = yup.object({
  new_password: yup.string().required(),
  confirm_password: yup.string().required(),
}).required()

export default function ResetPassword({ open, setOpen }) {

  const { register, handleSubmit, formState: { errors }, setError } = useForm({ resolver: yupResolver(schema) })

  const dispatch = useDispatch()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfPassword, setShowConfPassword] = useState(false)

  const handleClose = () => setOpen(false)
  const handleResetPassword = (data) => {
    if (data?.new_password !== data?.confirm_password) {
      return setError("confirm_password", { type: "custom", message: "new password and confirm password does not match" })
    }

    dispatch(resetPasswordVerifyOtpApi(data))
    setOpen(false)
  }

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card className='modal'>
          <form onSubmit={handleSubmit(handleResetPassword)}>
            <Stack spacing={2}>
              <Stack alignItems='flex-end'><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack>
              <Stack alignItems='center'>
                <Typography variant='h5' fontWeight={600}>Reset Password</Typography>
                <Typography>Your new password must be different from previous used passwords</Typography>
              </Stack>

              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  {...register("new_password")}
                  error={errors?.new_password?.message}
                  label='Password'
                  type={showPassword ? 'text' : 'password'}
                  id="outlined-adornment-password"
                  startAdornment={<LockIcon />}
                  endAdornment={
                    showPassword
                      ? <IconButton onClick={() => setShowPassword(false)}><Visibility /></IconButton>
                      : <IconButton onClick={() => setShowPassword(true)}><VisibilityOff /></IconButton>
                  }
                />
                <FormHelperText error>{errors?.new_password?.message}</FormHelperText>
              </FormControl>

              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-confirm-password">confirm Password</InputLabel>
                <OutlinedInput
                  {...register("confirm_password")}
                  error={errors?.confirm_password?.message}
                  label='confirm Password'
                  type={showConfPassword ? 'text' : 'password'}
                  id="outlined-adornment-confirm-password"
                  startAdornment={<LockIcon />}
                  endAdornment={
                    showConfPassword
                      ? <IconButton onClick={() => setShowConfPassword(false)}><Visibility /></IconButton>
                      : <IconButton onClick={() => setShowConfPassword(true)}><VisibilityOff /></IconButton>
                  }
                />
                <FormHelperText error>{errors?.confirm_password?.message}</FormHelperText>
              </FormControl>
              <Button variant='contained' type="submit">Reset password</Button>
            </Stack>
          </form>
        </Card>
      </Modal>
    </>
  )
}


ResetPassword.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
};