import { useState, useMemo, useCallback, useEffect, useContext } from "react";
import {
    Box,
    Button,
    Paper,
    Stack,
    Typography,
    Tabs,
    Tab,
    Card,
    Grid,
    CardActions,
    CardContent,
    MenuItem,
    FormHelperText,
    FormControl,
    InputLabel,
    OutlinedInput,
    Chip,
    Select,
    Tooltip,
    CircularProgress
} from "@mui/material";
import { FaDownload } from "react-icons/fa6";
import { DataGrid, useGridApiRef, GridToolbarContainer, getGridStringOperators, GridToolbar, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExportContainer, gridFilteredSortedRowEntriesSelector } from "@mui/x-data-grid";
import Chart from "../../components/Chart"
import NodataImg from "../../assets/images/noData.png";
import { useDispatch, useSelector } from "react-redux";
import { analyticsTableApi } from "../../store/analytics/analyticsList";
import { ThemeContext } from '../../context/ThemeContext';
import dayjs from "dayjs";
import CustomGridToolbar from "../../components/CustomGridToolbar";
import apiRoutes from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";

const columns = [

    {
        field: "id",
        headerName: "Sno.",
        width: 150,
        filterable: false,
        sortable: false,
    },
    {
        field: "fullname",
        headerName: "Full Name",
        width: 200,
        filterable: true,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals' | operator.value === 'contains',
        ),
        sortable: false,
    },
    {
        field: "email",
        headerName: "Email",
        width: 200,
        filterable: true,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals' | operator.value === 'contains',
        ),
        sortable: false,
    },
    {
        field: "phone_number",
        headerName: "Phone Number",
        width: 200,
        filterable: true,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals' | operator.value === 'contains',
        ),
        sortable: false,
    },
    {
        field: "state",
        headerName: "State Name",
        width: 450,
        filterable: true,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals',
        ),
        sortable: false,
    },

    {
        field: "city",
        headerName: "Region Name",
        width: 200,
        filterable: true,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals',
        ),
        sortable: false,
    },


    {
        field: "language",
        headerName: "Language",
        width: 200,
        filterable: true,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals',
        ),
        sortable: false,
    },
    {
        field: "gender",
        headerName: "Gender",
        width: 200,
        filterable: true,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals',
        ),
        sortable: false,
    },
    {
        field: "pincode",
        headerName: "Pincode",
        width: 200,
        filterable: true,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals',
        ),
        sortable: false,
    },
    {
        field: "school_name",
        headerName: "School Name",
        width: 200,
        filterable: true,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals' | operator.value === 'contains',
        ),
        sortable: false,
    },
    {
        field: "school_type",
        headerName: "School Type",
        width: 200,
        filterable: true,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals',
        ),
        sortable: false,
    },
    {
        field: "last_login",
        headerName: "Last login",
        width: 200,
        filterable: false,
        sortable: false,
        renderCell: (data) => (<>
            <Typography sx={{ overflowWrap: "anywhere" }}>{data?.row?.last_login && dayjs(data?.row?.last_login).format("YYYY-MM-DD HH:mm:ss")}</Typography>
        </>)
    },
    {
        field: "subjects",
        headerName: "Subjects",
        width: 200,
        renderCell: (data) => (<>
            <Typography sx={{ overflowWrap: "anywhere" }}>{Array.isArray(data?.row?.subjects) && data?.row?.subjects.toString()}</Typography>
        </>),
        filterable: false,
        sortable: false,
    },
    {
        field: "classes",
        headerName: "Classes",
        width: 200,
        renderCell: (data) => (<>
            <Typography sx={{ overflowWrap: "anywhere" }}>{Array.isArray(data?.row?.classes) && data?.row?.classes.toString()}</Typography>
        </>),
        filterable: false,
        sortable: false,
    },
    {
        field: "user_type",
        headerName: "User type",
        width: 200,
        filterable: false,
        sortable: false,
    },
    {
        field: "courses_enrolled",
        headerName: "Courses enrolled",
        width: 200,
        filterable: false,
        sortable: false,
    },
    {
        field: "courses_completed",
        headerName: "Courses completed",
        width: 200,
        filterable: false,
        sortable: false,
    },
    {
        field: "created_at",
        sortable: true,
        filterable: false,
        headerName: "Registration Date",
        width: 150,
    },
];

export default function HealthCheck({ graphData, region, state, loading, type, monthDaily, startDate, endDate }) {
    const [activevsavgtimespent, setActiveVsAvgTimeSpent] = useState({ l1: true, l2: true });
    const [teacherRegister, setTeacherRegistration] = useState({ l1: true, l2: true, l3: true, l4: true });
    const apiRef = useGridApiRef();
    const dispatch = useDispatch();
    const [isDownload, setIsDownload] = useState(false)
    const analyticsTable = useSelector((state) => state?.analyticsTable);
    const { result: tableResult, loading: tableLoading, total } = analyticsTable;
    const [queryOptions, setQueryOptions] = useState({});
    const { paginationModel, setPaginationModel } = useContext(ThemeContext)

    const onFilterChange = useCallback((filterModel) => {
        setQueryOptions({ filterModel: { ...filterModel } });
    }, []);
    const transformedGraphData = useMemo(() => {
        const aggregatedData = {};
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        graphData?.analytics_data?.forEach(entry => {
            const entryDate = new Date(entry.date);
            const day = entryDate?.getDate();
            const monthName = entryDate.toLocaleString('default', { month: 'long' }).slice(0, 3);
            const dateKey = type === 'daily' ? `${day} ${monthName}` : entry.date;

            if (!aggregatedData[dateKey]) {
                aggregatedData[dateKey] = {
                    name: dateKey,
                    "New user": 0,
                    "Active user": 0,
                    "Average Time Spent": 0,
                    "Teachers": 0,
                    "Educators": 0,
                    "Parents": 0,
                    amt: 0,
                    graphData: []
                };
            }
            aggregatedData[dateKey]["Active user"] += parseInt(entry.activeUsers);
            const roundedDuration = parseFloat(Number(entry.averageSessionDuration).toFixed(2));
            aggregatedData[dateKey]["Average Time Spent"] = Number(parseFloat(aggregatedData[dateKey]["Average Time Spent"]) + roundedDuration);
            aggregatedData[dateKey].amt += roundedDuration;
            aggregatedData[dateKey].graphData.push(entry);
        });

        for (let i = 0; i <= currentMonth; i++) {
            const monthName = new Date(currentYear, i).toLocaleString('default', { month: 'long' }).slice(0, 3);
            const dateKey = type === 'daily' ? `${i + 1} ${monthName}` : monthName;

            if (!aggregatedData[dateKey]) {
                aggregatedData[dateKey] = {
                    name: dateKey,
                    "Average Time Spent": 0,
                    "Teachers": 0,
                    "Active user": 0,
                    "New user": 0,
                    "Educators": 0,
                    "Parents": 0,
                    amt: 0,
                    graphData: []
                };
            }
        }

        graphData?.registration_data && Object.keys(graphData?.registration_data)?.forEach(entry => {
            if (entry === "teachers") {
                graphData?.registration_data[entry].forEach((obj) => {
                    const entryDate = (obj?.day) ? new Date(obj?.day) : new Date(obj.month);
                    const day = entryDate?.getDate();
                    const monthName = (obj?.day) ? entryDate.toLocaleString('default', { month: 'long' }).slice(0, 3) : obj?.month;
                    const dateKey = type === 'daily' ? `${day} ${monthName}` : monthName.slice(0, 3);
                    if (!aggregatedData[dateKey]) {
                        aggregatedData[dateKey] = {
                            name: dateKey,
                            "Average Time Spent": 0,
                            "Teachers": 0,
                            "Active user": 0,
                            "New user": 0,
                            "Educators": 0,
                            "Parents": 0,
                            amt: 0,
                            graphData: []
                        };
                    }
                    if (aggregatedData[dateKey]["Teachers"]) {
                        aggregatedData[dateKey]["Teachers"] += parseInt(obj.count);
                    } else {
                        aggregatedData[dateKey]["Teachers"] = parseInt(obj.count);
                    }
                });
            }
            if (entry === "parents") {
                graphData?.registration_data[entry].forEach((obj) => {
                    const entryDate = (obj.day) ? new Date(obj.day) : new Date(obj.month);
                    const day = entryDate?.getDate();
                    const monthName = (obj?.day) ? entryDate.toLocaleString('default', { month: 'long' }).slice(0, 3) : obj?.month;
                    const dateKey = type === 'daily' ? `${day} ${monthName}` : monthName.slice(0, 3);

                    if (!aggregatedData[dateKey]) {
                        aggregatedData[dateKey] = {
                            name: dateKey,
                            "Average Time Spent": 0,
                            "Teachers": 0,
                            "Active user": 0,
                            "New user": 0,
                            "Educators": 0,
                            "Parents": 0,
                            amt: 0,
                            graphData: []
                        };
                    }
                    if (aggregatedData[dateKey]["Parents"]) {
                        aggregatedData[dateKey]["Parents"] += parseInt(obj.count);
                    } else {
                        aggregatedData[dateKey]["Parents"] = parseInt(obj.count);
                    }
                });
            }
            if (entry === "educators") {
                graphData?.registration_data[entry].forEach((obj) => {
                    const entryDate = (obj.day) ? new Date(obj.day) : new Date(obj.month);
                    const day = entryDate?.getDate();
                    const monthName = (obj?.day) ? entryDate.toLocaleString('default', { month: 'long' }).slice(0, 3) : obj?.month;
                    const dateKey = type === 'daily' ? `${day} ${monthName}` : monthName.slice(0, 3);
                    if (!aggregatedData[dateKey]) {
                        aggregatedData[dateKey] = {
                            name: dateKey,
                            "Average Time Spent": 0,
                            "Teachers": 0,
                            "Active user": 0,
                            "New user": 0,
                            "Educators": 0,
                            "Parents": 0,
                            amt: 0,
                            graphData: []
                        };
                    }
                    if (aggregatedData[dateKey]["Educators"]) {
                        aggregatedData[dateKey]["Educators"] += parseInt(obj.count);
                    } else {
                        aggregatedData[dateKey]["Educators"] = parseInt(obj.count);
                    }
                });
            }
            if (entry === "total_user") {
                graphData?.registration_data[entry].forEach((obj) => {
                    const entryDate = (obj.day) ? new Date(obj.day) : new Date(obj.month);
                    const day = entryDate?.getDate();
                    const monthName = (obj?.day) ? entryDate.toLocaleString('default', { month: 'long' }).slice(0, 3) : obj?.month;
                    const dateKey = type === 'daily' ? `${day} ${monthName}` : monthName.slice(0, 3);
                    if (!aggregatedData[dateKey]) {
                        aggregatedData[dateKey] = {
                            name: dateKey,
                            "Average Time Spent": 0,
                            "Teachers": 0,
                            "Active user": 0,
                            "New user": 0,
                            "Educators": 0,
                            "Parents": 0,
                            amt: 0,
                            graphData: []
                        };
                    }
                    if (aggregatedData[dateKey]["New user"]) {
                        aggregatedData[dateKey]["New user"] += parseInt(obj.count);
                    } else {
                        aggregatedData[dateKey]["New user"] = parseInt(obj.count);
                    }
                });
            }
        });
        const mergedData = Object.values(aggregatedData).map(dateData => {
            dateData["Average Time Spent"] = parseFloat(Number(dateData["Average Time Spent"]).toFixed(2))
            return { ...dateData };
        });
        function compareMonths(a, b) {
            const monthAbbreviations = {
                'Jan': 'January', 'Feb': 'February', 'Mar': 'March', 'Apr': 'April', 'May': 'May', 'Jun': 'June', 'Jul': 'July', 'Aug': 'August', 'Sep': 'September', 'Oct': 'October', 'Nov': 'November', 'Dec': 'December'
            };
            const monthsOrder = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

            const monthA = monthAbbreviations[a.name] || a.name;
            const monthB = monthAbbreviations[b.name] || b.name;

            return monthsOrder.indexOf(monthA) - monthsOrder.indexOf(monthB);
        }
        function compareDates(a, b) {
            const dateA = new Date(a.name);
            const dateB = new Date(b.name);
            return dateA - dateB;
        }
        return mergedData.sort(type == "daily" ? compareDates : compareMonths);
    }, [graphData, type]);
    const toggleTeacherRegistration = useCallback((key) => {
        setTeacherRegistration(prevState => ({ ...prevState, [key]: !prevState[key] }));
    }, []);
    const toggleActiveVsAvgTimeSpent = useCallback((key) => {
        setActiveVsAvgTimeSpent(prevState => ({ ...prevState, [key]: !prevState[key] }));
    }, []);
    const getRowSpacing = useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
        };
    }, []);
    const handleSortModelChange = useCallback((sortModel) => {
        setQueryOptions({ sortModel: [...sortModel] });
    }, []);
    useEffect(() => {
        const filterModel = queryOptions?.filterModel;
        const items = filterModel?.items;
        const firstItem = items && items.length > 0 ? items[0] : {};
        const quickFilterValues = filterModel?.quickFilterValues;
        const sortModel = queryOptions?.sortModel;
        let query
        if (sortModel?.length > 0) {
            query = {
                page: paginationModel?.page + 1,
                page_size: paginationModel?.pageSize,
                sort_order: sortModel[0].sort || "desc",
                field: firstItem.field || '',
                operator: firstItem.operator || (quickFilterValues && "contains") || '',
                value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
            };
        } else {
            query = {
                page: paginationModel?.page + 1,
                page_size: paginationModel?.pageSize,
                field: firstItem.field || '',
                city: region,
                sort_order: "desc",
                state: state,
                startDate: startDate,
                endDate: endDate,
                operator: firstItem.operator || (quickFilterValues && "contains") || '',
                value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
            };
        }
        dispatch(analyticsTableApi(query));
    }, [dispatch, queryOptions, paginationModel, region, state, startDate, endDate,]);
    return (<>
        <Stack direction="row" spacing={1} sx={{ my: 2, padding: "10px" }} alignItems="center" justifyContent={"space-between"}>
            <Typography variant="h3">Health Check</Typography>
            {/* <Tooltip title="Download Report">
                {isDownload ? (<CircularProgress />) : (<Button type="button" onClick={() => { handleApiCall() }}><FaDownload size={28} /></Button>)}
            </Tooltip> */}
        </Stack>
        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
            <Grid container spacing={1}>
                <Grid item xl={3} lg={3} sm={12} xs={12}>
                    <Card sx={{
                        minHeight: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: teacherRegister.l1 && "antiquewhite"
                    }}
                        onClick={() => toggleTeacherRegistration('l1')}
                    >
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="div">Teachers</Typography>
                        </CardContent>
                    </Card>

                </Grid>
                <Grid item xl={3} lg={3} sm={12} xs={12}>
                    <Card sx={{
                        minHeight: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: teacherRegister.l2 && "antiquewhite"
                    }}
                        onClick={() => toggleTeacherRegistration('l2')}
                    >
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="div">New User</Typography>
                        </CardContent>
                    </Card>

                </Grid>
                <Grid item xl={3} lg={3} sm={12} xs={12}>
                    <Card sx={{
                        minHeight: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: teacherRegister.l3 && "antiquewhite"
                    }}
                        onClick={() => toggleTeacherRegistration('l3')}>
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="div">Parents</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xl={3} lg={3} sm={12} xs={12}>
                    <Card sx={{
                        minHeight: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: teacherRegister.l4 && "antiquewhite"
                    }}
                        onClick={() => toggleTeacherRegistration('l4')}>
                        <CardContent>
                            <Typography gutterBottom variant="h4" component="div">Educators</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
            <Chart type={"line"} height={500} data={transformedGraphData} module={"teacherRegister"} lineState={teacherRegister} />
        </Stack>
        <Stack direction="row" spacing={1} sx={{ my: 2, padding: "10px" }} alignItems="center">
            <DataGrid
                loading={tableLoading}
                rowCount={total}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                pageSize={paginationModel?.pageSize}
                onPageSizeChange={(newPageSize) => {
                    setPaginationModel({
                        ...paginationModel,
                        pageSize: newPageSize,
                    })
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pagination
                paginationMode="server"
                sx={{ height: 500 }}
                getRowId={(row) => row.id}
                rows={tableResult}
                getRowHeight={() => 'auto'}
                getRowSpacing={getRowSpacing}
                columns={columns}
                disableColumnSelector
                disableDensitySelector
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                slots={{
                    noRowsOverlay: () => (
                        <Box className="noData">
                            <img src={NodataImg} alt="no data found" />
                        </Box>
                    ),
                    toolbar: (props) =>
                    (CustomGridToolbar({
                        ...props, moduleType: "healthCheck", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.userList,
                        region: region,
                        state: state,
                        startDate: startDate,
                        endDate: endDate
                    })),
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Stack>
        <BackdropUI open={isDownload} />
    </>)
}