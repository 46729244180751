import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const detailsQnsBankApi = createAsyncThunk(
    'qns-bank/detail',
    async (id, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.questionBankAction(`update/${id}`, 0))
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: {}, loading: false, error: null, message: "" }

const detailsQnsBankSlice = createSlice({
    name: 'qns-bank/detail',
    initialState: { result: {}, loading: false, error: null, message: "" },
    reducers: {
        resetQuiz: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(detailsQnsBankApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(detailsQnsBankApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(detailsQnsBankApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { resetQuiz } = detailsQnsBankSlice.actions;
export const detailsQnsBankReducer = detailsQnsBankSlice.reducer