import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, CircularProgress, FormControl, FormHelperText, InputLabel, Modal, OutlinedInput, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { addVideoUnitApi } from "../../store/courses/video-unit/addVideoUnit";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getUnitApi, resetPreview } from "../../store/courses/unit/getUnit";
import { uploadImageApi } from "../../store/upload/uploadImage";
import { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { resetPreviewUnit } from "../../store/courses/getScrom";
const schema = yup.object({
    video_title: yup.string().required(),
    youtube_video_url: yup.string().required()
})


export default function AddVideoUnitModal({ open, setOpen, unit_block_id, sub_section_block_id }) {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {}
    });
    const [duration, setDuration] = useState('0:00');
    const [imageLink, setImageLink] = useState(null);
    const [images, setImages] = useState([]);
    const uploadImage = useSelector((state) => state?.uploadImage);
    const scromU = useSelector((state) => state.scrom);
    const { result: section_details } = scromU
    const getSecondsFromHHMMSS = (value) => {
        const [str1, str2, str3] = value.split(":");

        const val1 = Number(str1);
        const val2 = Number(str2);
        const val3 = Number(str3);

        if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
            return val1;
        }

        if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
            return val1 * 60 + val2;
        }

        if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
            return val1 * 60 * 60 + val2 * 60 + val3;
        }

        return 0;
    };

    const toHHMMSS = (secs) => {
        const secNum = parseInt(secs.toString(), 10);
        const hours = Math.floor(secNum / 3600);
        const minutes = Math.floor(secNum / 60) % 60;
        const seconds = secNum % 60;

        return [hours, minutes, seconds]
            .map((val) => (val < 10 ? `0${val}` : val))
            .filter((val, index) => val !== "00" || index > 0)
            .join(":")
            .replace(/^0/, "");
    };
    const handleDurtion = (e) => {
        //console.log(e.target.value)
        const value1 = e.target.value;
        const seconds = Math.max(0, getSecondsFromHHMMSS(value1));

        const time = toHHMMSS(seconds);
        //console.log(time)
        setDuration(time);
    }
    const onUploadImage = async (imageList, addUpdateIndex) => {
        if (imageList.length > 0) {
            let formData = new FormData();
            formData.append("file", imageList[0]?.file);
            await dispatch(uploadImageApi(formData));
        }
        setImages(imageList);
    };
    const addVideoUnitData = useSelector((state) => state.addVideoUnit);
    const { loading: addVideoUnitLoading } = addVideoUnitData

    const location = useLocation();
    const course_id = new URLSearchParams(location.search).get('course_id');

    const dispatch = useDispatch()

    const handleClose = () => setOpen(false)

    const handleAddVideo = async (data) => {
        dispatch(resetPreviewUnit())
        if (
            !uploadImage?.loading &&
            uploadImage.result && uploadImage.result.length > 0
        ) {
            data.banner=uploadImage.result
        } else {
            data.banner=imageLink
        }
        const videoUnitData = await dispatch(addVideoUnitApi({ ...data, unit_block_id, course_id: course_id.replaceAll(" ", "+") }))
        if (!videoUnitData?.payload?.error) {
            setOpen(false)
            dispatch(getUnitApi({ course_id: course_id.replaceAll(" ", "+"), sub_section_block_id: sub_section_block_id }))
        }

    }
    useEffect(() => {
        setImageLink(scromU.result.banner)
        // if (scromU?.result?.scorm_url?.length) {
        //   setSelectedFile({
        //     name: "Scrom File",
        //   });
        // }
        let defalutValues = {};
        defalutValues["video_title"] = scromU?.result?.scorm_title;
        defalutValues["youtube_video_url"] = scromU?.result?.scorm_url;
        defalutValues["duration"] = scromU?.result?.duration;
        reset(defalutValues);
      }, [scromU])
    return (
        <>

            <Modal open={open} onClose={handleClose}>
                <Card className='modal' sx={{ width: "60%" }}>
                    <Stack spacing={2}>
                        <Typography variant='h4'>Add Video</Typography>
                        <Stack spacing={2}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Video Title</InputLabel>
                                <OutlinedInput {...register("video_title")} error={errors?.video_title?.message} label="Video Title" />
                                <FormHelperText error>{errors?.video_title?.message}</FormHelperText>
                            </FormControl>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Youtube Url</InputLabel>
                                <OutlinedInput {...register("youtube_video_url")} error={errors?.youtube_video_url?.message} label="Youtube Url" />
                                <FormHelperText error>{errors?.youtube_video_url?.message}</FormHelperText>
                            </FormControl>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel shrink>Video Duration*</InputLabel>
                                <OutlinedInput
                                    {...register("duration")}
                                    label="Video Duration"
                                    name="duration"
                                    id="outlined-adornment-email"
                                    notched
                                    value={duration}
                                    onChange={(e) => handleDurtion(e)}
                                    error={errors?.duration?.message}
                                />
                                <FormHelperText error>
                                    {errors?.duration?.message}
                                </FormHelperText>

                            </FormControl>
                            <Stack alignItems="center" my={2}>
                                {imageLink ? (
                                    <div className="image-item">
                                        <img src={imageLink} alt="" width="320" height="180" />
                                        <div className="image-item__btn-wrapper">
                                            <Button
                                                onClick={() => setImageLink(null)}
                                                variant="contained"
                                            >
                                                Remove
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <ImageUploading
                                        // multiple
                                        acceptType={['png', 'jpeg', 'jpg']}
                                        value={images}
                                        onChange={onUploadImage}
                                        maxNumber={1}
                                        // resolutionType="ratio"
                                        dataURLKey="data_url"

                                    >
                                        {({
                                            imageList,
                                            onImageUpload,
                                            onImageRemoveAll,
                                            onImageUpdate,
                                            onImageRemove,
                                            isDragging,
                                            dragProps,
                                            errors,
                                        }) => (
                                            <>
                                                <div
                                                    className="upload__image-wrapper"
                                                    style={{ marginTop: "2%", marginBottom: "2%" }}
                                                >
                                                    {imageList.length > 0 ? null : (
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            style={isDragging ? { color: "red" } : undefined}
                                                            onClick={onImageUpload}
                                                            {...dragProps}
                                                        >
                                                            Click or Drop Banner
                                                        </Button>
                                                    )}

                                                    {errors && (
                                                        <div>
                                                            {errors.maxNumber && (
                                                                <span>
                                                                    Number of selected images exceed maxNumber
                                                                </span>
                                                            )}
                                                            {errors.acceptType && (
                                                                <span>
                                                                    Your selected file type is not allow
                                                                </span>
                                                            )}
                                                            {errors.maxFileSize && (
                                                                <span>
                                                                    Selected file size exceed maxFileSize
                                                                </span>
                                                            )}
                                                            {errors.resolution && (
                                                                <span>
                                                                    Selected file is not match your desired
                                                                    resolution i.e (328*150)
                                                                </span>
                                                            )}
                                                        </div>
                                                    )}
                                                    {imageList.map((image, index) => (
                                                        <div key={index} className="image-item">
                                                            <img
                                                                src={image["data_url"]}
                                                                alt=""
                                                                width="320" height="180"
                                                            />
                                                            <div className="image-item__btn-wrapper" style={{ display: "flex", justifyContent: "center" }}>

                                                                <Button
                                                                    onClick={() => onImageUpdate(index)}
                                                                    variant="contained"
                                                                    sx={{ mr: 2 }}
                                                                >
                                                                    Update
                                                                </Button>
                                                                <Button
                                                                    onClick={() => onImageRemove(index)}
                                                                    variant="contained"
                                                                >
                                                                    Remove
                                                                </Button>

                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </ImageUploading>
                                )}
                            </Stack>
                            <Stack direction='row' spacing={2}>
                                <Button disabled={addVideoUnitLoading} onClick={handleSubmit(handleAddVideo)} color="success" variant="contained" sx={{ mb: 2 }} >{addVideoUnitLoading ? <CircularProgress /> : "Add"}</Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Card>
            </Modal>
        </>
    )
}



AddVideoUnitModal.propTypes = {
    open: PropTypes.any,
    setOpen: PropTypes.any,
    unit_block_id: PropTypes.any,
    sub_section_block_id: PropTypes.any,
};