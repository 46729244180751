import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../../config/authAxios"
import apiRoutes from "../../apiConfigs"

export const deleteUnitApi = createAsyncThunk(
    'courses/sub-section-delete-unit',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.delete(apiRoutes.courseUnitAction(`${data.course_id}`, 0),
                {
                    params: {
                        unit_block_id: data.unit_block_id,
                    }
                })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "" }
const deleteUnitSlice = createSlice({
    name: 'sub-section-delete',
    initialState: intialState,
    reducers: {
        reset: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(deleteUnitApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(deleteUnitApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(deleteUnitApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { reset } = deleteUnitSlice.actions;
export const deleteUnitReducer = deleteUnitSlice.reducer