import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const uploadImageApi = createAsyncThunk(
  "auth/image/upload",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.post(apiRoutes.globalsAction(`upload/assets`, 0), data, { headers: { "Content-Type": "multipart/form-data" } })

      return response?.data;
    } catch (error) {
      //console.log("error",error);
      return rejectWithValue(error?.response?.data);
    }
  }
);
const intialState = { result: [], loading: false, error: null, message: "" }
const uploadImageSlice = createSlice({
  name: "image_upload",
  initialState: { result: [], loading: false, error: null, message: "" },
  reducers: {
    resetImage: () => intialState,
  },
  extraReducers: (builder) => {
    builder.addCase(uploadImageApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(uploadImageApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(uploadImageApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetImage } = uploadImageSlice.actions;
export const uploadImageReducer = uploadImageSlice.reducer;
