import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar, getGridStringOperators } from "@mui/x-data-grid";
import { useContext, useEffect, useState, useCallback } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import NodataImg from "../../assets/images/noData.png";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { useNavigate } from "react-router-dom";
import DeleteHolidayHomeWork from './DeleteHolidayHomeWork'
// import DeletePartner from "./DeletePartner";
import { hhwApproveApi } from "../../store/hhw/approveHhw";
import { hhwApi } from "../../store/hhw/hhwList";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import { ThemeContext } from '../../context/ThemeContext';
import CustomGridToolbar from "../../components/CustomGridToolbar";
import apiRoutes from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";
export default function Managehhw() {
  const navigate = useNavigate();

  const { paginationModel, setPaginationModel } = useContext(ThemeContext)
  const [isDownload, setIsDownload] = useState(false)

  const podcastSelector = useSelector((state) => state?.hhwList);
  const hhwApprove = useSelector((state) => state?.hhwApprove);
  const { result, loading } = podcastSelector;
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const dispatch = useDispatch();
  const [queryOptions, setQueryOptions] = useState({});

  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const columns = [
    {
      field: "title",
      headerName: "Title",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 250,
    },

    {
      field: "idea",
      headerName: "Idea",
      filterable: false,
      sortable: false,
      width: 250,
    },
    {
      field: "hhw_class",
      headerName: "Class",
      filterable: false,
      sortable: false,
      width: 200,
      renderCell: (data) => (<>
        <Typography sx={{ textWrap: "wrap" }}>{Array.isArray(data?.row?.hhw_class) && data?.row?.hhw_class.map((obj, index) => (`${obj['title']}, `))}</Typography>
      </>)
    },

    {
      field: "subjects",
      headerName: "Subjects",
      filterable: false,
      sortable: false,
      width: 250,
      renderCell: (data) => (<>
        <Typography sx={{ textWrap: "wrap" }}>{Array.isArray(data?.row?.subjects) && data?.row?.subjects.map((obj, index) => (`${obj['title']}, `))}</Typography>
      </>)
    },

    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      width: 500,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            color="info"
            endIcon={<EditIcon />}
            label="Available"
            variant="outlined"
            sx={{ margin: "0px 10px" }}
            onClick={() => navigate(`/edit-holiday-homework-management/${row.row.id}`)}
          >
            EDIT
          </Button>
          <Button
            color="error"
            sx={{ margin: "0px 10px" }}

            endIcon={<DeleteIcon />}
            variant="outlined"
            onClick={() => {
              setNotSelectedProductModel(true)
              setDeleteId(row.id)
            }}
          >
            Delete
          </Button>
          <Button
            endIcon={<ArrowDownwardIcon />}
            color="error"
            variant="outlined"
            sx={{ margin: "0px 10px" }}
            onClick={() => {
              console.log("row", row)
              window.open(row.row.file)
            }}
          >
            Download
          </Button>
          <Button
            color="info"
            endIcon={<DoneIcon />}
            sx={{ margin: "0px 10px" }}

            label="Available" variant="outlined"
            onClick={() => addToCartHandler(row.row.id, row.row.is_approved)}

          >
            {row.row.is_approved ? "Disapprove" : "Approve"}
          </Button>
        </>
      ),
    },


  ];

  const addToCartHandler = async (id, approve) => {
    var obj = {
      home_work_id: id,
      is_approved: !approve,
    }
    await dispatch(
      hhwApproveApi(obj)
    );
    if (!hhwApprove?.loading) {
      dispatch(
        hhwApi({
          page: paginationModel?.page + 1,
          page_size: paginationModel?.pageSize,
        })
      );

    }
  };
  console.log("sadasd", queryOptions)
  useEffect(() => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    const query = {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
    };
    dispatch(hhwApi(query));
  }, [dispatch, paginationModel, queryOptions]);
  const addEntry = async () => {
    navigate("/add-holiday-homework-management");
  };
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>

        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>hhw List</Typography>
        </Stack>
        <Stack sx={{ my: 2 }} justifyContent="space-between" direction="row">
          <Stack direction="row" spacing={1}>
            <Button
              onClick={addEntry}
              variant="contained"
            >
              Add New
            </Button>

          </Stack>
        </Stack>
        <Paper elevation={0}>
          <DataGrid
            loading={podcastSelector.loading}
            rowCount={podcastSelector.total}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            pageSize={paginationModel?.pageSize}
            onPageSizeChange={(newPageSize) => {
              setPaginationModel({
                ...paginationModel,
                pageSize: newPageSize,
              })
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pagination
            paginationMode="server"
            sx={{ height: 500 }}
            getRowId={(row) => row.id}
            rows={result}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            filterMode="server"
            onFilterModelChange={onFilterChange}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: (props) =>
                (CustomGridToolbar({ ...props, moduleType: "holidayHomeworkManagement", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.holidayHomeWorkList })),
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Paper>
      </Body>

      <DeleteHolidayHomeWork
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        id={deleteId}
      />
      <BackdropUI open={isDownload} />

    </>
  );
}
