import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const uploadCertificatehtmltextApi = createAsyncThunk(
  "auth/setting/uploadCertificatehtmltext",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.post(
        apiRoutes.courseCertificateList,
        data
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const settingEditSlice = createSlice({
  name: "upload certificate",
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(
      uploadCertificatehtmltextApi.pehtmltextnding,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      uploadCertificatehtmltextApi.fulfhtmltextilled,
      (state, action) => {
        state.loading = false;
        state.result = action?.payload?.result;
        state.message = action?.payload?.message;
      }
    );
    builder.addCase(
      uploadCertificatehtmltextApi.rejhtmltextected,
      (state, action) => {
        state.loading = false;
        state.error = action?.payload?.error;
        state.message = action?.payload?.message;
      }
    );
  },
});

export const uploadCertificatehtmltextReducer = settingEditSlice.reducer;
