import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Box,
  Chip
} from "@mui/material";
import dayjs from "dayjs";
import { useParams, useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ImageUploading from "react-images-uploading";
import DefaultProfileImg from "../../assets/images/defaultProfileImg.jpg";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Drawer from "../../components/Drawer";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-hot-toast";
import { webinarEditApi } from "../../store/webinar/editWebinar";
import { webinarAddApi } from "../../store/webinar/addWebinar";
import { webinarDetailsApi } from "../../store/webinar/webinarDetail";
import { categoryApi } from "../../store/categoryDetails/categoryList";
import { uploadImageApi } from "../../store/upload/uploadImage";
import { uploadSpeakerImageApi } from "../../store/upload/uploadSpeakerImage";
import {
  resetHandoutUpload,
  uploadHandoutsApi,
} from "../../store/upload/uploadHandouts";
import { storiesDetailsApi } from "../../store/impactStory/storyDetails";
import { storiesEditApi } from "../../store/impactStory/editStories";
import { classGlobalApi } from "../../store/class/classListGlobal";
import { subjectGlobalApi } from "../../store/subject/subjectListGlobal";
import { useTheme } from '@mui/material/styles';
import BackdropUI from "../../ui/Backdrop";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const schema = yup
  .object({

  })
  .required();

export default function EditImpact() {
  const theme = useTheme();
  const { result: classList, loading: classLoading } = useSelector((state) => state?.classGlobal);
  const { result: subjectList, loading: subjectLoading } = useSelector((state) => state?.subjectGlobal);
  const webinarEdit = useSelector((state) => state?.webinarEdit);
  const webinarAdd = useSelector((state) => state?.webinarAdd);
  const webinarDetails = useSelector((state) => state?.storyDetails);
  const uploadImage = useSelector((state) => state?.uploadImage);
  const uploadSpeakerImage = useSelector((state) => state?.uploadSpeakerImage);
  const uploadHandouts = useSelector((state) => state?.uploadHandouts);
  const refInput = useRef(null);
  const [language, setLanguage] = useState();
  const { result, loading } = webinarDetails;
  const {
    result: {
      story_title,
      awards_details,
      change_created,
      know_me_little,
      problem_wanted_to_solve,
      teacher_designation,
      teacher_email,
      teacher_name,
      teacher_school,
      reason,
      category,
    },
  } = webinarDetails;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    control
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });

  const [cat, setCat] = useState();
  const [approve, setApprove] = useState("pending");
  const [order, setOrder] = React.useState([]);
  const [highlight, setHighlight] = useState(false);
  const [dateTime, setDateTime] = useState();
  const [imageLink, setImageLink] = useState();
  const [speakerImage, setSpeakerImage] = useState();
  const [simages, setSImages] = React.useState([]);
  const [handOuts, setHandouts] = React.useState([]);
  const ref = useRef(null);
  const [images, setImages] = React.useState([]);
  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const handleUpdateForm = async (data) => {
    setFileUploadLoader(true)
    var newObj;

    newObj = {
      ...data,
      category: cat,
      admin_approved: approve,
      order: order,
      highlight: highlight,
      reason: data.reason,
      story_title: data?.story_title,
      awards_details: data?.awards_details,
      change_created: data?.change_created,
      know_me_little: data?.know_me_little,
      problem_wanted_to_solve: data?.problem_wanted_to_solve,
      teacher_designation: data?.teacher_designation,
      teacher_email: data?.teacher_email,
      teacher_name: data?.teacher_name,
      teacher_school: data?.teacher_school,




    };
    if (imageLink) {
      newObj =
      {
        ...newObj, story_image: imageLink,
      };
    } else if (images.length > 0 && !uploadImage?.loading && uploadImage.result) {
      newObj = { ...newObj, story_image: uploadImage.result, };
    } else {
      toast.error("Please Upload An Image", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      setFileUploadLoader(false)
      return;
    }
    if (params.id) {
      newObj = { ...newObj, id: params.id };
      await dispatch(storiesEditApi(newObj));
      if (!webinarEdit?.loading) {
        navigate("/impact-stories-management");
      }
    }
    setFileUploadLoader(false)
  };
  useEffect(() => {
    dispatch(classGlobalApi());
    dispatch(subjectGlobalApi());
    if (params.id) {
      dispatch(storiesDetailsApi(params.id));
    }
  }, []);
  const onUploadImage = async (imageList, addUpdateIndex) => {
    if (imageList.length > 0) {
      let formData = new FormData();
      setFileUploadLoader(true)

      formData.append("file", imageList[0]?.file);
      await dispatch(uploadImageApi(formData));
      setFileUploadLoader(false)
    }
    setImages(imageList);
  };
  useEffect(() => {
    setApprove(result?.admin_approved != null ? result?.admin_approved : undefined);
    setCat(result?.category);
    setSpeakerImage(result?.story_image);
    setImageLink(
      result?.story_image
    );
    setOrder(result?.order);
    setHighlight(result?.highlight);
  }, [webinarDetails]);


  useEffect(() => {
    let defalutValues = {};
    defalutValues["story_title"] = result?.story_title;
    defalutValues["awards_details"] = result?.awards_details;
    defalutValues["change_created"] = result?.change_created;
    defalutValues["know_me_little"] = result?.know_me_little;
    defalutValues["problem_wanted_to_solve"] = result?.problem_wanted_to_solve;
    defalutValues["teacher_designation"] = result?.teacher_designation;
    defalutValues["teacher_email"] = result?.teacher_email;
    defalutValues["teacher_name"] = result?.teacher_name;
    defalutValues["teacher_school"] = result?.teacher_school;
    defalutValues["category"] = result?.category;
    defalutValues["language"] = result?.language;
    defalutValues["admin_approved"] = result?.admin_approved;
    defalutValues["highlight"] = result?.highlight;
    defalutValues["order"] = result?.order;
    defalutValues["reason"] = result?.reason;
    defalutValues["subjects"] = result?.subjects?.map(o => o?.id);
    defalutValues["classes"] = result?.classes?.map(o => o?.id);
    setLanguage(result?.language)

    reset(defalutValues);
  }, [result]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          uploadHandouts?.loading ||
          uploadImage?.loading ||
          uploadSpeakerImage?.loading
        }
      // onClick={handleClose}
      >
        <Typography>Uploading Data Please Wait ....</Typography>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Story Details</Typography>
                <Stack spacing={2} my={2}>
                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Story Title</InputLabel>
                        <OutlinedInput
                          {...register("story_title")}
                          label="Story Title"
                          notched
                          // disabled
                          error={errors?.story_title?.message}
                          defaultValue={story_title}
                        />
                        <FormHelperText error>
                          {errors?.story_title?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Language
                        </InputLabel>
                        <Select
                          {...register("language")}
                          label="Language"
                          notched
                          id="outlined-adornment-category"
                          error={!!errors?.language?.message}
                          value={language ? language : null}
                          onChange={(e) => setLanguage(e.target.value)}
                        >
                          <MenuItem value={"hindi"}>Hindi</MenuItem>
                          <MenuItem value={"english"}>English</MenuItem>
                          <MenuItem value={"both"}>Both</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.language?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Category</InputLabel>
                        <Select
                          {...register("category")}
                          label="Category"
                          notched
                          id="outlined-adornment-category"
                          value={cat ? cat : null}
                          error={errors?.allocate_stars?.message}
                          onChange={(e) => {
                            setCat(e.target.value);
                          }}
                        >
                          <MenuItem value={"Teacher trail blazer"}>Teacher trail blazer</MenuItem>
                          <MenuItem value={"A torch bearer"}>A torch bearer</MenuItem>
                          <MenuItem value={"Forerunners for transformation"}>Forerunners for transformation</MenuItem>
                        </Select>
                        <FormHelperText error>
                          {errors?.category?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Admin Approve</InputLabel>
                        <Select
                          {...register("admin_approved")}
                          label="Admin Approve"
                          notched
                          id="outlined-adornment-category"
                          value={approve}
                          error={errors?.allocate_stars?.message}
                          onChange={(e) => {
                            setApprove(e.target.value);
                          }}
                        >
                          <MenuItem value={true}>Approved</MenuItem>
                          <MenuItem value={false}>Rejected</MenuItem>
                          <MenuItem disabled value={"pending"}>Pending</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.category?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Subject
                        </InputLabel>
                        <Controller
                          name="subjects"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              multiple
                              value={watch('subjects', [])}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                setValue('subjects', typeof value === 'string' ? value.split(',') : value);
                                field.onChange(e);
                              }}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={subjectList.find(obj => obj.id === value)?.title || ""} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {subjectList.map((obj) => (
                                <MenuItem
                                  key={obj.title}
                                  value={obj.id}
                                  style={getStyles(obj.title, watch('subjects', []), theme)}
                                >
                                  {obj.title}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.subjects && <span>{errors.subjects.message}</span>}
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Class
                        </InputLabel>
                        <Controller
                          name="classes"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              multiple
                              value={watch('classes', [])}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                setValue('classes', typeof value === 'string' ? value.split(',') : value);
                                field.onChange(e);
                              }}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={classList.find(obj => obj.id === value)?.title || ""} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {classList.map((obj) => (
                                <MenuItem
                                  key={obj.title}
                                  value={obj.id}
                                  style={getStyles(obj.title, watch('classes', []), theme)}
                                >
                                  {obj.title}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.classes && <span>{errors.classes.message}</span>}
                        <FormHelperText error>
                          {errors?.classes?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Order</InputLabel>
                        <Select
                          {...register("order")}
                          label="Order"
                          notched
                          id="outlined-adornment-category"
                          value={order ? order : null}
                          error={errors?.order?.message}
                          onChange={(e) => {
                            setOrder(e.target.value);
                          }}
                        >
                          <MenuItem value={"1"}>1</MenuItem>
                          <MenuItem value={"2"}>2</MenuItem>
                          <MenuItem value={"3"}>3</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.order?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Highlight</InputLabel>
                        <Select
                          {...register("category")}
                          label="Highlight"
                          notched
                          id="outlined-adornment-category"
                          value={highlight}
                          error={errors?.allocate_stars?.message}
                          onChange={(e) => {
                            setHighlight(e.target.value);
                          }}
                        >
                          <MenuItem value={true}>True</MenuItem>
                          <MenuItem value={false}>False</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.category?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Change Created</InputLabel>
                        <OutlinedInput
                          notched
                          // disabled
                          multiline
                          {...register("change_created")}
                          label="Change Created"
                          name="change_created"
                          error={errors?.change_created?.message}
                        />
                        <FormHelperText error>
                          {errors?.change_created?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Awards Details</InputLabel>
                        <OutlinedInput
                          {...register("awards_details")}
                          label="Awards Details"
                          notched
                          multiline
                          // disabled
                          error={errors?.awards_details?.message}
                          defaultValue={awards_details}
                        />
                        <FormHelperText error>
                          {errors?.awards_details?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Know Me Little</InputLabel>
                        <OutlinedInput
                          {...register("know_me_little")}
                          label="Know Me Little"
                          notched
                          multiline
                          // disabled
                          error={errors?.know_me_little?.message}
                          defaultValue={know_me_little}
                        />
                        <FormHelperText error>
                          {errors?.know_me_little?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Problem Wanted To Solve</InputLabel>
                        <OutlinedInput
                          {...register("problem_wanted_to_solve")}
                          label="Problem Wanted To Solve"
                          notched
                          multiline
                          // disabled
                          error={errors?.problem_wanted_to_solve?.message}
                          defaultValue={problem_wanted_to_solve}
                        />
                        <FormHelperText error>
                          {errors?.problem_wanted_to_solve?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Teacher Name</InputLabel>
                        <OutlinedInput
                          {...register("teacher_name")}
                          label="Teacher Name"
                          notched
                          // disabled
                          error={errors?.teacher_name?.message}
                          defaultValue={teacher_name}
                        />
                        <FormHelperText error>
                          {errors?.teacher_name?.message}
                        </FormHelperText>
                      </FormControl>

                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Teacher Email</InputLabel>
                        <OutlinedInput
                          {...register("teacher_email")}
                          label="Teacher Email"
                          notched
                          // disabled
                          error={errors?.teacher_email?.message}
                          defaultValue={teacher_email}
                        />
                        <FormHelperText error>
                          {errors?.teacher_email?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Teacher Designation</InputLabel>
                        <OutlinedInput
                          {...register("teacher_designation")}
                          label="Teacher Designation"
                          notched
                          // disabled
                          error={errors?.teacher_designation?.message}
                          defaultValue={teacher_designation}
                        />
                        <FormHelperText error>
                          {errors?.teacher_designation?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Teacher School</InputLabel>
                        <OutlinedInput
                          {...register("teacher_school")}
                          label="Teacher School"
                          notched
                          // disabled
                          error={errors?.teacher_school?.message}
                          defaultValue={teacher_school}
                        />
                        <FormHelperText error>
                          {errors?.teacher_school?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Reason</InputLabel>
                        <OutlinedInput
                          notched

                          multiline
                          {...register("reason")}
                          label="Reason"
                          name="reason"
                          error={errors?.reason?.message}
                        />
                        <FormHelperText error>
                          {errors?.reason?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  </>
                </Stack>
                <InputLabel style={{ marginTop: "2%", marginBottom: "2%" }}>
                  Story Image
                </InputLabel>
                {imageLink ? (
                  <div className="image-item">
                    <img src={imageLink} alt="" width="200" height="200" />
                    <div className="image-item__btn-wrapper">
                      <Button
                        onClick={() => setImageLink(null)}
                        variant="contained"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                ) : (
                  <ImageUploading

                    acceptType={['png', 'jpeg', 'jpg']}
                    value={images}
                    onChange={onUploadImage}
                    maxNumber={1}

                    dataURLKey="data_url"

                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                      errors,
                    }) => (
                      <>
                        <div className="upload__image-wrapper" style={{ marginTop: "2%", marginBottom: "2%" }}>
                          {imageList.length > 0 ? null : (
                            <Button
                              type="button"
                              variant="contained"
                              style={
                                isDragging ? { color: "red" } : undefined
                              }
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Click or Drop here
                            </Button>
                          )}

                          {errors && (
                            <div>
                              {errors.maxNumber && (
                                <span>
                                  Number of selected images exceed maxNumber
                                </span>
                              )}
                              {errors.acceptType && (
                                <span>
                                  Your selected file type is not allow
                                </span>
                              )}
                              {errors.maxFileSize && (
                                <span>
                                  Selected file size exceed maxFileSize
                                </span>
                              )}
                              {errors.resolution && (
                                <span>
                                  Selected file is not match your desired
                                  resolution
                                </span>
                              )}
                            </div>
                          )}
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img
                                src={image["data_url"]}
                                alt=""
                                width="320" height="180"
                              />
                              <div className="image-item__btn-wrapper">
                                <Button
                                  onClick={() => onImageUpdate(index)}
                                  variant="contained"
                                  sx={{ mr: 2 }}
                                >
                                  Update
                                </Button>
                                <Button
                                  onClick={() => onImageRemove(index)}
                                  variant="contained"
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </ImageUploading>
                )}
              </Card>
              <Button
                type="button"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
      <BackdropUI open={fileUploadLoader} />

    </>
  );
}
