import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const tlmApi = createAsyncThunk(
  "auth/tlms",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.get(
        apiRoutes.learningMaterialList, { params: data }
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const tlmSlice = createSlice({
  name: "tlm",
  initialState: {
    result: [],
    loading: false,
    error: null,
    message: "",

    total: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(tlmApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(tlmApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
      state.total = action?.payload?.count;
    });
    builder.addCase(tlmApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const tlmReducer = tlmSlice.reducer;
