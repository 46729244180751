import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const teachingVideoAddApi = createAsyncThunk(
    'auth/teachingVideos/add',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(apiRoutes.teachingVideosList, data)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const teachingVideoAddSlice = createSlice({
    name: 'teachingVideo Add',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(teachingVideoAddApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(teachingVideoAddApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(teachingVideoAddApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const teachingVideoAddReducer = teachingVideoAddSlice.reducer