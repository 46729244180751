import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Button,
  Card,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tlmDeleteApi } from "../../store/tlm/deleteTLM";
import { tlmApi } from "../../store/tlm/tlmList";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

export default function DeletePoll({ open, setOpen, id }) {
  const deleteSundayPoll = useSelector((state) => state?.tlmList);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { paginationModel } = useContext(ThemeContext);

  const handleClose = () => setOpen(false);

  const deleteCourseConfirm = async () => {
    await dispatch(tlmDeleteApi(id));
    setOpen(false);
    if (!deleteSundayPoll?.loading) {
      dispatch(
        tlmApi({
          page: paginationModel?.page + 1,
          page_size: paginationModel?.pageSize,
        })
      );
      navigate("/tlm-management");
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card className="modal" sx={{ width: "30%" }}>
          <Stack alignItems="flex-end">
            <IconButton onClick={handleClose}>
              <HighlightOffIcon />
            </IconButton>
          </Stack>
          <Stack alignItems="center" my={2}>
            <Typography variant="h5" fontWeight={600}>
              Are you sure you want to delete this TLM ?
            </Typography>
            <Typography variant="h5" fontWeight={600}>
              Please click confirm
            </Typography>
          </Stack>

          <Stack sx={{ mt: 2 }} justifyContent="center" direction="row">
            <Button
              variant="outlined"
              onClick={() => setOpen(false)}
              sx={{ mr: 2 }}
            >
              Back
            </Button>
            <Stack direction="row" spacing={1}>
              {/* <Button variant='outlined' onClick={notRequiredOrderHandler}>Not Required</Button> */}
              <Button variant="contained" onClick={deleteCourseConfirm}>
                Confirm
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>
    </>
  );
}

DeletePoll.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  id: PropTypes.any,
};
