import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs";

export const analyticsGraphApi = createAsyncThunk(
    'auth/analytics/graph',
    async (requestData, { rejectWithValue }) => {
        try {
            const { type, params } = requestData;
            const response = await AuthAxios.get(apiRoutes.analytics(`${type}`), { params })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "" }
const analyticsGraphSlice = createSlice({
    name: 'analitics/graph',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {
        resetAnalyticsGraph: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(analyticsGraphApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(analyticsGraphApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(analyticsGraphApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { resetAnalyticsGraph } = analyticsGraphSlice.actions;
export const analyticsGraphReducer = analyticsGraphSlice.reducer