import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { SketchPicker } from "react-color";
import ImageUploading from "react-images-uploading";
import DefaultProfileImg from "../../assets/images/defaultProfileImg.jpg";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import Chip from "@mui/material/Chip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Style from "../../styles/profile.module.scss";
import { TimeField } from "@mui/x-date-pickers";
import { toast } from "react-hot-toast";
import { pageEditApi } from "../../store/cms/editPage";
import { pageDetailsApi } from "../../store/cms/getAboutUs";
import { categoryEditApi } from "../../store/categoryDetails/categoryEdit";
import { categoryAddApi } from "../../store/categoryDetails/categoryAdd";
import { categoryDetailsApi } from "../../store/categoryDetails/categoryDetail";
const schema = yup
  .object({
    // heading: yup.string().required("Please Provide title Name"),

    title: yup.string().required("Please Provide A category ").trim(),
    title_hn:yup.string().required("Please Provide A category name in hindi ").trim(),
    is_card_title_dark: yup.string().required("Please Select One "),
  })
  .required();

export default function CategoryEdit() {
  const categoryDetails = useSelector((state) => state?.categoryDetails);
  const {
    result: { title_name, video_link, title, heading, stars, category },
  } = categoryDetails;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [accountToggle, setAccountToggle] = useState("accountToggle");
  const [cardTitleDark, setCardTitleDark] = useState(null);
  const [colour, setColour] = useState();
  const [showColorPicker, setShowColorPicker] = useState();
  const [metaTag, setMetaTag] = useState([]);
  const [images, setImages] = React.useState([]);
  const [value, setValue] = useState();
  const [imageLink, setImageLink] = useState();
  const ref = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const onUploadImage = (imageList, addUpdateIndex) => {
    //console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };
  const categoryAdd = useSelector((state) => state?.categoryAdd);
  const categoryEdit = useSelector((state) => state?.categoryEdit);
  useEffect(() => {
    //console.log(params.id);
    if (params.id) {
      dispatch(categoryDetailsApi(params.id));
    }
  }, []);
  const handleChangeComplete = (color) => {
    //console.log(color.hex);
    setColour(color.hex);
  };
  const handleUpdateForm = async (value) => {
    var newObj;
    if (colour) {
      newObj = { ...value, card_bg_color: colour };
    } else {
      toast.error("Please Provide Color", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      return;
    }

    //console.log(newObj);
    if (params.id) {
      newObj = { ...value, card_bg_color: colour, id: params.id };
      await dispatch(categoryEditApi(newObj));
      if (!categoryEdit?.loading) {
        navigate("/category-management");
        //console.log("edit");
      }
    } else {
      await dispatch(categoryAddApi(newObj));
      if (!categoryAdd?.loading) {
        navigate("/category-management");
        //console.log("true");
      }
    }
  };
  useEffect(() => {
    setCardTitleDark(categoryDetails.result?.is_card_title_dark);
    setColour(categoryDetails.result?.card_bg_color);
    // setCardTitleDark("False")
  }, [categoryDetails]);

  useEffect(() => {
    let defalutValues = {};
    defalutValues["title"] = categoryDetails.result?.title;
    defalutValues["title_hn"] = categoryDetails.result?.title_hn;
    defalutValues["is_card_title_dark"] =
      categoryDetails.result?.is_card_title_dark;
    reset(defalutValues);
  }, [categoryDetails]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Category Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />

                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-category" shrink>
                        Title
                      </InputLabel>
                      <OutlinedInput
                        {...register("title")}
                        label="title"
                        notched
                        id="outlined-adornment-category"
                        // InputLabelProps={{ shrink: categoryDetails.result.title ? true :false }}
                        error={errors?.title?.message}
                        defaultValue={categoryDetails.result.title || ""}
                      />
                      <FormHelperText error>
                        {errors?.title?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-category" shrink>
                        Title (Hindi)
                      </InputLabel>
                      <OutlinedInput
                        {...register("title_hn")}
                        label="Title (Hindi)"
                        notched
                        id="outlined-adornment-category"
                        error={errors?.title_hn?.message}
                      />
                      <FormHelperText error>
                        {errors?.title_hn?.message}
                      </FormHelperText>
                    </FormControl>

                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-email" shrink>
                        Card Title Dark
                      </InputLabel>
                      <Select
                        {...register("is_card_title_dark")}
                        label="Card Title Dark"
                        id="outlined-adornment-category"
                        notched
                        error={!!errors?.is_card_title_dark?.message}
                        value={cardTitleDark !== null ? cardTitleDark : null}
                        // defaultValue={page ? page : null}
                        onChange={(e) => setCardTitleDark(e.target.value)}
                      >
                        <MenuItem value={"true"}>True</MenuItem>
                        <MenuItem value={"false"}>False</MenuItem>
                      </Select>

                      <FormHelperText error>
                        {errors?.is_card_title_dark?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                  <InputLabel>Color</InputLabel>

                  <Stack direction="row" spacing={2}>
                    {showColorPicker ? (
                      <>
                        <SketchPicker
                          color={colour||""}
                          onChange={(e) => handleChangeComplete(e)}
                        />
                      </>
                    ) : (<div style={{ width: "100%" }}>
                      Colour is {colour}
                      <Button
                        variant="contained"

                        style={{ backgroundColor: colour, marginLeft: "2%" }}
                        onClick={() => setShowColorPicker(true)}
                      >
                        {colour ? "Change" : "Select"}
                      </Button>

                    </div>
                    )}
                  </Stack>
                </Stack>
                {showColorPicker ? (
                  <Button
                    variant="contained"
                    onClick={() => setShowColorPicker(false)}

                  >
                    Set
                  </Button>
                ) : null}
              </Card>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate("/category-management")}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
    </>
  );
}
