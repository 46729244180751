import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs";

export const entryExitApi = createAsyncThunk(
    'auth/entryExits',
    async (data, { rejectWithValue }) => {
        try {
            const paramsData = new URLSearchParams(data);
            const response = await AuthAxios.get(apiRoutes.entryExitList, { params: data })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const entryExitSlice = createSlice({
    name: 'entryExit',
    initialState: { result: [], loading: false, error: null, message: "", total: 0 },
    extraReducers: (builder) => {
        builder.addCase(entryExitApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(entryExitApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
            state.total = action?.payload?.count
        })
        builder.addCase(entryExitApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const entryExitReducer = entryExitSlice.reducer