import EmailIcon from '@mui/icons-material/Email';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Box, Button, Card, CardActionArea, IconButton, Modal, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import ForgetPasswordEmailConf from './ForgetPasswordEmailConf';
import ForgetPasswordSMSConf from './ForgetPasswordSMSConf';
import Style from "../../styles/login.module.scss"
import PropTypes from 'prop-types'


export default function ForgetPassword({ open, setOpen }) {

  const [emailInputConf, setEmailInputConf] = useState(false)
  const [phoneVerifyModal, setPhoneVerifyModal] = useState(false)
  const [selectedSection, setSelectedSection] = useState(null)

  const handleClose = () => {
    setOpen(false)
    setSelectedSection(null)
  }

  const handleSentOTPViaEmail = () => {
    setSelectedSection('email')
  }

  const handleSentOTPViaSMS = () => {
    setSelectedSection('phone')
  }


  const handleSendOTP = () => {
    if (selectedSection == 'email') {
      setEmailInputConf(true)
    }
    else if (selectedSection == 'phone') {
      setPhoneVerifyModal(true)
    }
    setOpen(false)
  }

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card className='modal'>
          <Stack spacing={2}>
            <Stack alignItems='flex-end'><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack>
            <Stack alignItems='center'>
              <Typography variant='h5' fontWeight={600}>Forget Password</Typography>
              <Typography>Please select the option to send an email for reseting your password.</Typography>
            </Stack>
            <Card sx={{ p: 0 }} className={selectedSection == "email" && Style.selectedOTPCard}>
              <CardActionArea sx={{ p: 2 }} onClick={handleSentOTPViaEmail}>
                <Stack direction='row' alignItems='center' spacing={3}>
                  <EmailIcon />
                  <Box>
                    <Typography fontWeight={600}>Reset via Email</Typography>
                    <Typography>A link will be send to your registered email address.</Typography>
                  </Box>
                </Stack>
              </CardActionArea>
            </Card>
        
            <Button variant='contained' onClick={handleSendOTP} disabled={!selectedSection}>Send </Button>
          </Stack>
        </Card>
      </Modal>

      <ForgetPasswordEmailConf open={emailInputConf} setOpen={setEmailInputConf} back={setOpen} />
    </>
  )
}

ForgetPassword.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
};