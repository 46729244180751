import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { settingDetailsApi } from "../../store/settings/settingDetail";
import { settingAddApi } from "../../store/settings/settingAdd";
import { settingEditApi } from "../../store/settings/settingEdit";
const schema = yup
  .object({

    title: yup.string().required("Please Provide A category ").trim(),
  })
  .required();

export default function SettingsEdit() {
  const settingDetails = useSelector((state) => state?.settingDetails);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const params = useParams();
  const navigate = useNavigate();
  const classAdd = useSelector((state) => state?.classAdd);
  const classEdit = useSelector((state) => state?.classEdit);
  useEffect(() => {
    if (params.id) {
      dispatch(settingDetailsApi(params.id));
    }
  }, [dispatch]);
  const handleUpdateForm = async (value) => {
    var newObj;

    if (params.id) {
      newObj = { ...value, id: params.id };
      await dispatch(settingEditApi(newObj));
      if (!classEdit?.loading) {
        navigate("/settings");
      }
    } else {
      await dispatch(settingAddApi(value));
      if (!classAdd?.loading) {
        navigate("/settings");
      }
    }
  };

  useEffect(() => {
    let defalutValues = {};
    if (params.id) {
      defalutValues["title"] = settingDetails?.result?.title;
      defalutValues["min_percent"] = settingDetails?.result?.min_percent;
      defalutValues["max_percent"] = settingDetails?.result?.max_percent;
    }
    reset(defalutValues);
  }, [settingDetails]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Setting Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />

                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-category" shrink>
                        Title
                      </InputLabel>
                      <OutlinedInput
                        {...register("title")}
                        label="title"
                        readOnly={true}
                        notched
                        id="outlined-adornment-category"
                        error={errors?.title?.message}
                      />
                      <FormHelperText error>
                        {errors?.title?.message}
                      </FormHelperText>
                    </FormControl>

                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-category" shrink>
                        Max percentage
                      </InputLabel>
                      <OutlinedInput
                        {...register("max_percent", {
                          required: "Max percentage is required",
                          min: { value: 0.1, message: "Minimum value is 0.1%" },
                          max: { value: 100, message: "Maximum value is 100%" },
                          valueAsNumber: true,
                        })}
                        label="Max percentage"
                        notched
                        inputProps={{
                          type: "number",
                          step: "0.1",
                          min: 0.1,
                          max: 100,
                        }}
                        id="outlined-adornment-max-percent"
                        error={!!errors.max_percent}
                      />
                      <FormHelperText error>
                        {errors?.max_percent?.message}
                      </FormHelperText>
                    </FormControl>

                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-category" shrink>
                        Min percentage
                      </InputLabel>
                      <OutlinedInput
                        {...register("min_percent", {
                          required: "Min percentage is required",
                          min: { value: 0.1, message: "Minimum value is 0.1%" },
                          max: { value: 100, message: "Maximum value is 100%" },
                          valueAsNumber: true,
                        })}
                        label="Min percentage"
                        notched
                        inputProps={{
                          type: "number",
                          step: "0.1",
                          min: 0.1,
                          max: 100,
                        }}
                        id="outlined-adornment-min-percent"
                        error={!!errors.min_percent}
                      />
                      <FormHelperText error>
                        {errors?.min_percent?.message}
                      </FormHelperText>
                    </FormControl>

                  </Stack>
                </Stack>
              </Card>
              <Button
                type="button"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
    </>
  );
}
