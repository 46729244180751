import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../../config/authAxios"
import apiRoutes from "../../apiConfigs"

export const courseSubSectionAddApi = createAsyncThunk(
    'courses/sub-section-add',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(apiRoutes.courseSubSecAction(`${data.course_id}`,0), data)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "" }
const courseSubSectionAddSlice = createSlice({
    name: 'sub-section-add',
    initialState: intialState,
    reducers: {
        reset: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(courseSubSectionAddApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(courseSubSectionAddApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(courseSubSectionAddApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { reset } = courseSubSectionAddSlice.actions;
export const courseSubSectionAddReducer = courseSubSectionAddSlice.reducer