import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const uploadScromApi = createAsyncThunk(
  "scrom_upload/upload",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.post(apiRoutes.courseScormAction(`${data.course_id}`, 0), data.file, { headers: { "Content-Type": "multipart/form-data" } })
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const intialState = { result: [], loading: false, error: null, message: "" }
const uploadScromSlice = createSlice({
  name: "scrom_upload",
  initialState: { result: [], loading: false, error: null, message: "" },
  reducers: {
    resetImage: () => intialState,
  },
  extraReducers: (builder) => {
    builder.addCase(uploadScromApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(uploadScromApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(uploadScromApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetImage } = uploadScromSlice.actions;
export const uploadScromReducer = uploadScromSlice.reducer;
