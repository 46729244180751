import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const settingApi = createAsyncThunk(
    'auth/setting/list',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.adminAction("passing-criteria", 1))
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const settingSlice = createSlice({
    name: 'setting',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(settingApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(settingApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(settingApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const settingReducer = settingSlice.reducer