import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/notAuthAxios"
import apiRoutes from "../apiConfigs"
 var data1 = {
        "username":"admin"
        ,"password":"admin@123",
        "edx_username":"admin1",
        "edx_password":"admin@123"
    }

export const loginApi = createAsyncThunk(
    'auth/login',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(apiRoutes.login, data)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const loginSlice = createSlice({
    name: 'login',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(loginApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(loginApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(loginApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})


export const loginReducer = loginSlice.reducer