import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import { userContent } from "../apiConfigs"

export const userContentReportTableApi = createAsyncThunk(
    'user/content/report/table',
    async (requestData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(userContent, { params: requestData })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const userContentReportTableSlice = createSlice({
    name: 'user/content/report/table',
    initialState: { result: [], loading: false, error: null, message: "", total: 0 },
    extraReducers: (builder) => {
        builder.addCase(userContentReportTableApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(userContentReportTableApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
            state.total = action?.payload?.count
        })
        builder.addCase(userContentReportTableApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const userContentReportTableReducer = userContentReportTableSlice.reducer