import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs";

export const analiticsFilterApi = createAsyncThunk(
    'auth/analytics',
    async (requestData, { rejectWithValue }) => {
        try {
            const { type, params } = requestData;
            const response = await AuthAxios.get(apiRoutes.analytics(`${type}`), { params })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const analiticsFilterSlice = createSlice({
    name: 'analitics/filter',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(analiticsFilterApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(analiticsFilterApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(analiticsFilterApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const analiticsFilterReducer = analiticsFilterSlice.reducer