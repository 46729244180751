import { Box, Stack, Typography } from "@mui/material";
import styles from "../styles/brand-info.module.scss";
import teacherLogo from "../assets/images/bharti-image.png"
import LadisImage from "../assets/icons/icons_17.png"


export default function BrandInfo() {
    return (
        <>
            <Box className={styles['main']}>
                <Box className={styles["info-block"]}>
                    <img src={LadisImage} alt="logo" />
                    <Stack spacing={1} alignItems="center">
                        <Typography variant="h2" className={styles["header-content"]}>
                            <pre> Collaborate & meet impactful </pre> teacher community
                        </Typography>
                        <Typography className={styles["body-content"]}>
                            मिले अपने जैसे प्रभावशाली शिक्षकों से
                        </Typography>
                    </Stack>
                    <img src={teacherLogo} alt="logo" width={110} height={100} />
                </Box>
            </Box>
        </>
    );
}
