import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from '@mui/icons-material/Add';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import InfoIcon from '@mui/icons-material/Info';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Backdrop, Box, Button, Card, CircularProgress, Fab, FormControl, FormHelperText, InputLabel, OutlinedInput, Stack, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { getScromApi } from "../../store/courses/getScrom";
import { addUnitApi } from "../../store/courses/unit/addUnit";
import { deleteUnitApi } from "../../store/courses/unit/deleteUnit";
import { getUnitApi } from "../../store/courses/unit/getUnit";
import { publishUnitApi } from "../../store/courses/unit/publishUnit";
import { updateUnitApi } from "../../store/courses/unit/updateUnit";
import AddVideoUnitModal from "./AddVideoUnitModal";
import ScromUpload from "./ScromUpload";



let newArray = []

const schema = yup.object({
    unit_title: yup.string().required(),
    published: yup.boolean().required()
})

export default function AddCourseUnit() {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: { published: true }
    });

    const getUnitData = useSelector((state) => state.getUnit);
    const { result: unitData, loading: unitDataLoading } = getUnitData
    const publishUnitLoader = useSelector((state) => state.publishUnit);
    const addUnitData = useSelector((state) => state.addUnit);
    const scromU = useSelector((state) => state.scrom);
    const { loading: courseUnitLoading } = addUnitData

    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();


    const course_id = new URLSearchParams(location.search).get('course_id');
    const sub_section_block_id = new URLSearchParams(location.search).get('sub_section_block_id');
    const [scrom, setScrom] = useState(false);
    const [addUnitVisible, setAddUnitVisible] = useState(false)
    // const [unitData, setUnitData] = useState([])
    const [addVideoUnitModal, setAddVideoUnitModal] = useState(false)
    const [scromModal, setScromModal] = useState(false)
    const [singleCourse, setSingleCourse] = useState(false)



    const handleAddUnit = async (data) => {
        const courseSectinAdd = await dispatch(addUnitApi({
            course_id: course_id.replaceAll(" ", "+"),
            start_date: dayjs().toJSON(),
            sub_section_block_id: sub_section_block_id.replaceAll(" ", "+"),
            published: data.published,
            unit_title: data.unit_title
        }))
        if (!courseSectinAdd?.payload?.action?.error) {
            setAddUnitVisible(false)
            dispatch(getUnitApi({ course_id: course_id.replaceAll(" ", "+"), sub_section_block_id: sub_section_block_id }))
            reset()
        }
    }

    const handleDeleteUnit = async (data) => {
        const courseSectinDelete = await dispatch(deleteUnitApi(data))
        if (!courseSectinDelete?.payload?.action?.error) {
            setAddUnitVisible(false)
            dispatch(getUnitApi({ course_id: course_id.replaceAll(" ", "+"), sub_section_block_id: sub_section_block_id }))
            reset()
        }
    }


    const updateUnit = async (data) => {
        const updateUnitData = await dispatch(updateUnitApi(data))
        if (!updateUnitData?.payload?.action?.error) {
            setAddUnitVisible(false)
            dispatch(getUnitApi({ course_id: course_id.replaceAll(" ", "+"), sub_section_block_id: sub_section_block_id }))
            reset()
        }
    }

    const publishUnit = async (data) => {
        const updateUnitData = await dispatch(publishUnitApi(data))
        if (!updateUnitData?.payload?.action?.error) {
            setAddUnitVisible(false)
            dispatch(getUnitApi({ course_id: course_id.replaceAll(" ", "+"), sub_section_block_id: sub_section_block_id }))
            reset()
        }
    }


    const handleNavigateUnit = (data) => {
        setSingleCourse(data)
        setAddVideoUnitModal(true)
    }
    const handleScromUnit = (data) => {
        setSingleCourse(data)
        setScromModal(true)
    }

    const handleChangeSection = (event, idx, data) => {
        newArray = [...unitData]
        newArray[idx] = { ...data, unit_title: event.target.value }
        // setUnitData(newArray)
    }

    const handleUnitList = (data) => {
        navigate(`/edit-course/sub-section/units/list?course_id=${course_id}&unit_block_id=${data.unit_block_id}`)
    }


    useEffect(() => {
        dispatch(getUnitApi({ course_id: course_id.replaceAll(" ", "+"), sub_section_block_id: sub_section_block_id }))
    }, [dispatch])



    useEffect(() => {

        if (unitData.length) {
            dispatch(getScromApi(unitData[0]))
        }
    }, [dispatch, unitData])


    return (
        <>
        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={publishUnitLoader.loading 
                }

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Appbar />
            <Drawer />
            <Body>
                <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
                    <HiTemplate size={30} />
                    <Typography>Courses Sub Section Units</Typography>
                    <Tooltip arrow title={
                        ` 
                        You cannot add more than one unit within a single subsection!.
                        You cannot add more than one scorm within a single unit!.

                    `}
                    >
                        <InfoIcon color="primary" />
                    </Tooltip>
                </Stack>

                {unitData.length < 1 ? <Card p={2} m={3} sx={{ background: '#e1f2f2', mt: 2 }}>
                    <Button sx={{ mb: 2 }} disabled={addUnitVisible} variant="contained" endIcon={<AddIcon />} onClick={() => setAddUnitVisible(true)}>Add New Sub Section Unit </Button>

                    {addUnitVisible
                        ? <Fragment>
                            <Stack spacing={2}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel> Sub Section Unit Title</InputLabel>
                                    <OutlinedInput {...register("unit_title")} error={errors?.unit_title?.message} label="Sub Section Unit Title" />
                                    <FormHelperText error>{errors?.unit_title?.message}</FormHelperText>
                                </FormControl>
                                <Stack direction='row' spacing={2}>
                                    <Button disabled={courseUnitLoading} color="success" variant="contained" onClick={handleSubmit(handleAddUnit)} sx={{ mb: 2 }}>{courseUnitLoading ? <CircularProgress /> : "Add"}</Button>
                                </Stack>
                            </Stack>
                        </Fragment>
                        : <></>
                    }
                </Card> : null}

                <>
                    <Typography variant="h4">Courses Sub Section Units List</Typography>
                    {
                        unitDataLoading
                            ? <CircularProgress />
                            : unitData?.map((data, idx) => (
                                <Card key={idx} p={3} m={3} sx={{ background: '#fff', mt: 2 }}>
                                    <Fragment >
                                        <Stack spacing={2}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel> Sub Section Unit Title</InputLabel>
                                                <OutlinedInput label="Sub Section Unit Title" defaultValue={data?.title} onChange={(event) => handleChangeSection(event, idx, data)} />
                                            </FormControl>
                                            <Stack direction='row' spacing={2} justifyContent='flex-end' alignItems='center'>
                                                {/* <Button endIcon={<ArrowRightIcon />} color="info" variant="contained" sx={{ mb: 2 }} onClick={() => handleUnitList(data)}>Units List</Button> */}
                                                <Stack direction='row' spacing={2}>
                                                    {/* <Fab color="primary" size="small" onClick={() => updateUnit(data)}><EditNoteIcon /></Fab> */}
                                                    <Fab color="error" size="small" onClick={() => handleDeleteUnit(data)}><DeleteIcon /></Fab>
                                                </Stack>
                                            </Stack>
                                            <Stack spacing={2} direction='row'>
                                                <Fab color="primary" size="small" disabled={scrom} onClick={() => handleNavigateUnit(data)}><OndemandVideoIcon /></Fab>
                                                <Fab color="primary" size="small" onClick={() => handleScromUnit(data)}><AddIcon /></Fab>
                                                {/* <Fab color="primary" size="small" disabled><FontDownloadIcon /></Fab> */}
                                            </Stack>
                                            {scromU.result.scorm_url ?
                                                <iframe style={{ width: '87%', height: '600px' }} src={scromU.result.scorm_url}></iframe>
                                                : null}

                                            <Box>
                                                <Button disabled={data?.published} color="success" variant="contained"
                                                    endIcon={data?.published ? <CheckIcon /> : <ArrowRightIcon />}
                                                    sx={{ mb: 2 }} onClick={() => publishUnit(data)}
                                                >
                                                    {data?.published ? "Published" : "Publish"}
                                                </Button>
                                            </Box>
                                        </Stack>
                                    </Fragment>
                                </Card>
                            ))}
                </>
            </Body>

            <ScromUpload
                open={scromModal}
                setOpen={setScromModal}
                unit_block_id={singleCourse?.unit_block_id}
                sub_section_block_id={sub_section_block_id}
            />
            <AddVideoUnitModal open={addVideoUnitModal} setOpen={setAddVideoUnitModal} unit_block_id={singleCourse?.unit_block_id} sub_section_block_id={sub_section_block_id} />
        </>
    )
}