import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../../config/authAxiosForm";
import apiRoutes from "../../apiConfigs";

export const addVideoUnitApi = createAsyncThunk(
    "auth/courses/add-video-unit",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(apiRoutes.courseUnitUploadVideoAction(`${data.course_id}`,0), data)
            return response?.data;
        } catch (error) {

            return rejectWithValue(error?.response?.data);
        }
    }
);

const addVideoUnitSlice = createSlice({
    name: "add-video-unit",
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(addVideoUnitApi.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(addVideoUnitApi.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action?.payload?.result;
            state.message = action?.payload?.message;
        });
        builder.addCase(addVideoUnitApi.rejected, (state, action) => {
            state.loading = false;
            state.error = action?.payload?.error;
            state.message = action?.payload?.message;
        });
    },
});

export const addVideoUnitReducer = addVideoUnitSlice.reducer;
