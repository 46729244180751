import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Box
} from "@mui/material";
import dayjs from "dayjs";
import { useParams, useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ImageUploading from "react-images-uploading";
import DefaultProfileImg from "../../assets/images/defaultProfileImg.jpg";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Drawer from "../../components/Drawer";
import Chip from "@mui/material/Chip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-hot-toast";
import { webinarEditApi } from "../../store/webinar/editWebinar";
import { webinarAddApi } from "../../store/webinar/addWebinar";
import { webinarDetailsApi } from "../../store/webinar/webinarDetail";
import { categoryApi } from "../../store/categoryDetails/categoryList";
import { uploadImageApi } from "../../store/upload/uploadImage";
import { uploadSpeakerImageApi } from "../../store/upload/uploadSpeakerImage";
import {
  resetHandoutUpload,
  uploadHandoutsApi,
} from "../../store/upload/uploadHandouts";
import { classGlobalApi } from "../../store/class/classListGlobal";
import { subjectGlobalApi } from "../../store/subject/subjectListGlobal";
import { useTheme } from '@mui/material/styles';
import BackdropUI from "../../ui/Backdrop";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const schema = yup
  .object({
    content_name: yup.string().required("Please Provide Content Name").trim(),
    webinar_url: yup.string().required("Please Provide Video Link").trim(),
    allocate_stars: yup
      .number()
      .required("Please Provide Number Of Stars ")
      .max(1000, "Max Stars Can Be 1000")
      .min(1, "Minimum stars should be 1")
      .typeError("Please provide a number"),
    speakers: yup.string().required("Please Provide Speaker ").trim(),
    speaker_title: yup
      .string()
      .required("Please Provide Speaker Title ")
      .trim(),
    category: yup.string().required("Please Provide A Catergory "),
    topic: yup.string().required("Please Provide Topic ").trim(),
    introduction: yup.string().required("Please Provide Introduction ").trim(),
    // password: yup.string(),
  })
  .required();

export default function AddWebinars() {
  const theme = useTheme();
  const { result: classList, loading: classLoading } = useSelector((state) => state?.classGlobal);
  const { result: subjectList, loading: subjectLoading } = useSelector((state) => state?.subjectGlobal);
  const webinarEdit = useSelector((state) => state?.webinarEdit);
  const webinarAdd = useSelector((state) => state?.webinarAdd);
  const webinarDetails = useSelector((state) => state?.webinarDetails);
  const uploadImage = useSelector((state) => state?.uploadImage);
  const uploadSpeakerImage = useSelector((state) => state?.uploadSpeakerImage);
  const uploadHandouts = useSelector((state) => state?.uploadHandouts);
  const refInput = useRef(null);
  const { result, loading } = webinarDetails;
  const {
    result: {
      content_name,
      webinar_url,
      speakers,
      video_duration,
      agenda,
      speaker_title,
      allocate_stars,
      date_and_time,
      introduction,
      meta_tags,
      content_description,
      topic,
      category,
    },
  } = webinarDetails;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    control
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });

  const [cat, setCat] = useState();
  const [meta, setMeta] = useState("");
  const [metaTag, setMetaTag] = useState([]);
  const [images, setImages] = React.useState([]);
  const [timeDuration, setTimeDuration] = useState("0:00");
  const [language, setLanguage] = useState();
  const [dateTime, setDateTime] = useState();
  const [imageLink, setImageLink] = useState();
  const [speakerImage, setSpeakerImage] = useState();
  const [simages, setSImages] = React.useState([]);
  const [handOuts, setHandouts] = React.useState([]);
  const ref = useRef(null);
  const [fileUploadLoader, setFileUploadLoader] = useState(false);

  const onUploadImage = async (imageList, addUpdateIndex) => {
    if (imageList.length > 0) {
      setFileUploadLoader(true)
      let formData = new FormData();

      formData.append("file", imageList[0]?.file);
      await dispatch(uploadImageApi(formData));
      setFileUploadLoader(false)
    }
    setImages(imageList);
  };
  const onUploadSpeakerImage = async (speakerImage, addUpdateIndex) => {
    if (speakerImage.length > 0) {
      let formData = new FormData();
      setFileUploadLoader(true)

      formData.append("file", speakerImage[0]?.file);
      await dispatch(uploadSpeakerImageApi(formData));
      setFileUploadLoader(false)
    }
    setSImages(speakerImage);
  };
  const onUploadHandout = async (file) => {
    setFileUploadLoader(true)
    let formData = new FormData();
    formData.append("file", file);
    dispatch(uploadHandoutsApi(formData)).then((res) => {
      if (res.payload.result.length > 0 && !res.payload.result.error) {

        let obj = {
          fileName: file.name,
          fileUrl: res.payload.result,
        };
        setHandouts([...handOuts, obj]);
        setFileUploadLoader(false)

        refInput.current.value = null;
      }
    })
  };

  const params = useParams();
  const navigate = useNavigate();
  const categories = useSelector((state) => state?.categories);
  const handleUpdateForm = async (data) => {
    setFileUploadLoader(true)
    var newObj;
    if (imageLink) {
      newObj = { ...data, banner: imageLink };
    } else if (
      images.length > 0 &&
      !uploadImage?.loading &&
      uploadImage.result
    ) {
      newObj = { ...data, banner: uploadImage.result };
    } else {
      setFileUploadLoader(false)
      toast.error("Please Upload An Image", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      return;
    }
    if (speakerImage) {
      newObj = { ...newObj, speaker_image: speakerImage };
    } else if (
      simages.length > 0 &&
      !uploadSpeakerImage?.loading &&
      uploadSpeakerImage.result
    ) {
      newObj = { ...newObj, speaker_image: uploadSpeakerImage.result };
    } else {
      setFileUploadLoader(false)
      toast.error("Please Upload A Speaker", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      return;
    }
    if (metaTag.length < 5) {
      setFileUploadLoader(false)
      toast.error("Please provide 5 meta tags", {
        id: "toast",
        duration: 4000,
        position: "top-center",
      });
      return;
    }
    console.log('dataTime',dateTime)
    if (!dateTime) {
      
      setFileUploadLoader(false)
      toast.error("Please provide Date & Time", {
        id: "toast",
        duration: 4000,
        position: "top-center",
      });
      return;
    }
    newObj = {
      ...newObj,
      meta_tags: metaTag.join(","),
      duration: timeDuration,
      date_and_time: dateTime,
      handouts: handOuts,
    };
    if (params.id) {
      newObj = { ...newObj, id: params.id };
      await dispatch(webinarEditApi(newObj));
      if (!webinarEdit?.loading) {
        navigate("/webinars");
      }
    } else {
      await dispatch(webinarAddApi(newObj));
      if (!webinarAdd?.loading) {
        navigate("/webinars");
      }
    }
    setFileUploadLoader(false)
  };
  useEffect(() => {
    dispatch(categoryApi());
    dispatch(classGlobalApi());
    dispatch(subjectGlobalApi());
    if (params.id) {
      dispatch(webinarDetailsApi(params.id));
    }
  }, []);
  useEffect(() => {
    setTimeDuration(video_duration);
    setDateTime(date_and_time);
    setCat(result?.category);
    setImageLink(result?.banner);
    setSpeakerImage(result?.speaker_image);
    if (meta_tags) {
      setMetaTag(meta_tags.split(","));
    }
    if (result?.handouts) {
      setHandouts(result?.handouts);
    }
    setLanguage(webinarDetails.result?.language);
  }, [webinarDetails]);
  const handleChipDelete = (element) => {
    var local = metaTag;
    setMetaTag(local.filter((item) => item !== element));
  };
  const handleHandoutDelete = (element) => {
    var local = handOuts;
    setHandouts(local.filter((item) => item !== element));
  };
  const handleChipAdd = (value) => {
    if (!metaTag.includes(ref?.current?.value.trim())) {
      setMetaTag([...metaTag, meta]);
      setMeta(null);
      ref.current.value = null;
    } else {
      toast.error("This Meta Tag Already Exists", {
        id: "toast",
        duration: 4000,
        position: "top-center",
      });
    }
  };
  useEffect(() => {
    let defalutValues = {};
    defalutValues["content_name"] = result?.content_name;
    defalutValues["webinar_url"] = result?.webinar_url;
    defalutValues["allocate_stars"] = result?.allocate_stars;
    defalutValues["speakers"] = result?.speakers;
    defalutValues["speaker_title"] = result?.speaker_title;
    defalutValues["agenda"] = result?.agenda;
    defalutValues["introduction"] = result?.introduction;
    defalutValues["content_description"] = result?.content_description;
    defalutValues["topic"] = result?.topic;
    defalutValues["category"] = result?.category;
    defalutValues["banner"] = result?.banner;
    defalutValues["language"] = result?.language;
    defalutValues["subjects"] = result?.subjects?.map(o => o?.id);
    defalutValues["classes"] = result?.classes?.map(o => o?.id);
    reset(defalutValues);
  }, [result]);
  const getSecondsFromHHMMSS = (value) => {
    const [str1, str2, str3] = value.split(":");

    const val1 = Number(str1);
    const val2 = Number(str2);
    const val3 = Number(str3);

    if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
      return val1;
    }

    if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
      return val1 * 60 + val2;
    }

    if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
      return val1 * 60 * 60 + val2 * 60 + val3;
    }

    return 0;
  };
  const toHHMMSS = (secs) => {
    const secNum = parseInt(secs.toString(), 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor(secNum / 60) % 60;
    const seconds = secNum % 60;

    return [hours, minutes, seconds]
      .map((val) => (val < 10 ? `0${val}` : val))
      .filter((val, index) => val !== "00" || index > 0)
      .join(":")
      .replace(/^0/, "");
  };
  const handleDurtion = (e) => {
    const value1 = e.target.value;
    const seconds = Math.max(0, getSecondsFromHHMMSS(value1));

    const time = toHHMMSS(seconds);

    setTimeDuration(time);
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          uploadHandouts?.loading ||
          uploadImage?.loading ||
          uploadSpeakerImage?.loading
        }
      // onClick={handleClose}
      >
        <Typography>Uploading Data Please Wait ....</Typography>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Webinars Details</Typography>
                <Stack spacing={2} my={2}>
                  {/* {accountToggle == "accountToggle" && */}
                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Content Name*</InputLabel>
                        <OutlinedInput
                          {...register("content_name")}
                          label="Content Name"
                          notched
                          error={errors?.content_name?.message}
                          defaultValue={content_name}
                        />
                        <FormHelperText error>
                          {errors?.content_name?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Webinar Url*</InputLabel>
                        <OutlinedInput
                          {...register("webinar_url")}
                          label="Webinar Url"
                          notched
                          error={errors?.webinar_url?.message}
                          defaultValue={webinar_url}
                        />
                        <FormHelperText error>
                          {errors?.webinar_url?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Video Duration*</InputLabel>
                        <OutlinedInput
                          notched
                          {...register("video_duration")}
                          label="Video Duration"
                          name="video_duration"
                          value={timeDuration ? timeDuration : null}
                          onChange={(e) => handleDurtion(e)}
                          error={errors?.video_duration?.message}
                        />
                        <FormHelperText error>
                          {errors?.video_duration?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Allocated Stars*</InputLabel>
                        <OutlinedInput
                          {...register("allocate_stars")}
                          label="Allocated Stars"
                          notched
                          error={errors?.allocate_stars?.message}
                          defaultValue={allocate_stars}
                        />
                        <FormHelperText error>
                          {errors?.allocate_stars?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Category*</InputLabel>
                        <Select
                          {...register("category")}
                          label="Category"
                          notched
                          id="outlined-adornment-category"
                          value={cat ? cat : null}
                          error={errors?.allocate_stars?.message}
                          // defaultValue={category}
                          onChange={(e) => {
                            setCat(e.target.value);
                            //console.log(e);
                          }}
                        >
                          {categories?.result?.map(({ id, title }, index) => (
                            <MenuItem value={title} key={index}>
                              {title}
                            </MenuItem>
                          ))}
                        </Select>

                        <FormHelperText error>
                          {errors?.category?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>Meta Tag</InputLabel>
                        <OutlinedInput
                          label="meta_tag"
                          onChange={(e) => {
                            if (e.target.value.trim().length > 0) {
                              setMeta(e.target.value);
                            } else {
                              setMeta(null);
                              ref.current.value = null;
                            }
                          }}
                          defaultValue={meta}
                          inputRef={ref}
                          endAdornment={
                            <Button
                              variant="contained"
                              className="btn3"
                              disabled={
                                ref?.current?.value.trim().length > 0
                                  ? false
                                  : true
                              }
                              onClick={() => handleChipAdd()}
                            >
                              Add
                            </Button>
                          }
                        />
                        <FormHelperText error></FormHelperText>
                        <Stack
                          direction="row"
                          spacing={1}
                          style={{ display: " inline" }}
                        >
                          {metaTag.map((i, element) => (
                            <>
                              <Chip
                                label={i}
                                variant="outlined"
                                className=""
                                style={{ marginTop: "5px" }}
                                onDelete={() => handleChipDelete(i)}
                              />
                            </>
                          ))}
                        </Stack>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Speaker*</InputLabel>
                        <OutlinedInput
                          {...register("speakers")}
                          label="Speaker"
                          notched
                          error={errors?.speakers?.message}
                          defaultValue={speakers}
                        />
                        <FormHelperText error>
                          {errors?.speakers?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Speaker Title*</InputLabel>
                        <OutlinedInput
                          {...register("speaker_title")}
                          label="Speaker Title"
                          notched
                          error={errors?.speaker_title?.message}
                          defaultValue={speaker_title}
                        />
                        <FormHelperText error>
                          {errors?.speaker_title?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Topic*</InputLabel>
                        <OutlinedInput
                          {...register("topic")}
                          label="Topic"
                          notched
                          error={errors?.topic?.message}
                          defaultValue={topic}
                          multiline={true}
                          rows={2}
                        />
                        <FormHelperText error>
                          {errors?.topic?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            {...register("date")}
                            label="Date & Time*"
                            name="date"
                            error={errors?.date?.message}
                            value={dateTime ? dayjs(dateTime) : null}
                            onChange={(newValue) => {
                              setDateTime(dayjs(newValue).toJSON());
                            }}
                          />
                        </LocalizationProvider>
                        <FormHelperText error>
                          {errors?.date?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Language*
                        </InputLabel>
                        <Select
                          {...register("language")}
                          label="Language"
                          notched
                          id="outlined-adornment-category"
                          error={!!errors?.language?.message}
                          value={language ? language : null}
                          onChange={(e) => setLanguage(e.target.value)}
                        >

                          <MenuItem value={"hindi"}>Hindi</MenuItem>
                          <MenuItem value={"english"}>English</MenuItem>
                          <MenuItem value={"both"}>Both</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.language?.message}
                        </FormHelperText>
                      </FormControl>

                    </Stack>
                    {/* <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Subject
                        </InputLabel>
                        <Controller
                          name="subjects"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              multiple
                              value={watch('subjects', [])}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                setValue('subjects', typeof value === 'string' ? value.split(',') : value);
                                field.onChange(e);
                              }}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={subjectList.find(obj => obj.id === value)?.title || ""} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {subjectList.map((obj) => (
                                <MenuItem
                                  key={obj.title}
                                  value={obj.id}
                                  style={getStyles(obj.title, watch('subjects', []), theme)}
                                >
                                  {obj.title}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.subjects && <span>{errors.subjects.message}</span>}
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Class
                        </InputLabel>
                        <Controller
                          name="classes"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              multiple
                              value={watch('classes', [])}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                setValue('classes', typeof value === 'string' ? value.split(',') : value);
                                field.onChange(e);
                              }}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={classList.find(obj => obj.id === value)?.title || ""} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {classList.map((obj) => (
                                <MenuItem
                                  key={obj.title}
                                  value={obj.id}
                                  style={getStyles(obj.title, watch('classes', []), theme)}
                                >
                                  {obj.title}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.classes && <span>{errors.classes.message}</span>}
                        <FormHelperText error>
                          {errors?.classes?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack> */}

                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Subject
                        </InputLabel>
                        <Controller
                          name="subjects"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              multiple
                              value={watch('subjects', [])}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                // setValue('subjects', typeof value === 'string' ? value.split(',') : value);
                                field.onChange(e);
                              }}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={subjectList.find(obj => obj.id === value)?.title || ""} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {subjectList.map((obj) => (
                                <MenuItem
                                  key={obj.title}
                                  value={obj.id}
                                  style={getStyles(obj.title, watch('subjects', []), theme)}
                                >
                                  {obj.title}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.subjects && <span>{errors.subjects.message}</span>}
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Class
                        </InputLabel>
                        <Controller
                          name="classes"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              multiple
                              value={watch('classes', [])}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                // setValue('classes', typeof value === 'string' ? value.split(',') : value);
                                field.onChange(e);
                              }}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={classList.find(obj => obj.id === value)?.title || ""} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {classList.map((obj) => (
                                <MenuItem
                                  key={obj.title}
                                  value={obj.id}
                                  style={getStyles(obj.title, watch('classes', []), theme)}
                                >
                                  {obj.title}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.classes && <span>{errors.classes.message}</span>}
                        <FormHelperText error>
                          {errors?.classes?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Introduction*</InputLabel>
                        <OutlinedInput
                          {...register("introduction")}
                          label="introduction"
                          multiline
                          notched
                          error={errors?.introduction?.message}
                          defaultValue={introduction}
                        />
                        <FormHelperText error>
                          {errors?.introduction?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>

                  </>
                  {/* } */}
                </Stack>
                <InputLabel style={{ marginTop: "2%", marginBottom: "2%" }}>Speaker Image</InputLabel>
                {speakerImage ? (
                  <div className="image-item">
                    <img src={speakerImage} alt="" width="200" height="200" />
                    <div className="image-item__btn-wrapper">
                      <Button
                        onClick={() => setSpeakerImage(null)}
                        variant="contained"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                ) : (
                  <ImageUploading
                    acceptType={['png', 'jpeg', 'jpg']}
                    value={simages}
                    onChange={onUploadSpeakerImage}
                    maxNumber={1}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                      errors,
                    }) => (
                      <>
                        <div
                          className="upload__image-wrapper"
                          style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                          {imageList.length > 0 ? null : (
                            <Button
                              type="button"
                              variant="contained"
                              style={isDragging ? { color: "red" } : undefined}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Click or Drop here
                            </Button>
                          )}

                          {errors && (
                            <div>
                              {errors.maxNumber && (
                                <span>
                                  Number of selected images exceed maxNumber
                                </span>
                              )}
                              {errors.acceptType && (
                                <span>
                                  Your selected file type is not allow
                                </span>
                              )}
                              {errors.maxFileSize && (
                                <span>
                                  Selected file size exceed maxFileSize
                                </span>
                              )}
                              {errors.resolution && (
                                <span>
                                  Selected file is not match your desired
                                  resolution
                                </span>
                              )}
                            </div>
                          )}
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img
                                src={image["data_url"]}
                                alt=""
                                width="200"
                                height="200"
                              />
                              <div className="image-item__btn-wrapper">
                                <Button
                                  onClick={() => onImageUpdate(index)}
                                  variant="contained"
                                  sx={{ mr: 2 }}
                                >
                                  Update
                                </Button>
                                <Button
                                  onClick={() => onImageRemove(index)}
                                  variant="contained"
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </ImageUploading>
                )}
                <InputLabel style={{ marginTop: "2%", marginBottom: "2%" }}>
                  Thumbnail Image
                </InputLabel>
                {imageLink ? (
                  <div className="image-item">
                    <img src={imageLink} alt="" width="320" height="180" />
                    <div className="image-item__btn-wrapper">
                      <Button
                        onClick={() => setImageLink(null)}
                        variant="contained"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                ) : (
                  <ImageUploading
                    acceptType={['png', 'jpeg', 'jpg']}
                    value={images}
                    onChange={onUploadImage}
                    maxNumber={1}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                      errors,
                    }) => (
                      <>
                        <div
                          className="upload__image-wrapper"
                          style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                          {imageList.length > 0 ? null : (
                            <Button
                              type="button"
                              variant="contained"
                              style={isDragging ? { color: "red" } : undefined}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Click or Drop here
                            </Button>
                          )}

                          {errors && (
                            <div>
                              {errors.maxNumber && (
                                <span>
                                  Number of selected images exceed maxNumber
                                </span>
                              )}
                              {errors.acceptType && (
                                <span>
                                  Your selected file type is not allow
                                </span>
                              )}
                              {errors.maxFileSize && (
                                <span>
                                  Selected file size exceed maxFileSize
                                </span>
                              )}
                              {errors.resolution && (
                                <span>
                                  Selected file is not match your desired
                                  resolution
                                </span>
                              )}
                            </div>
                          )}
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img
                                src={image["data_url"]}
                                alt=""
                                width="320"
                                height="180"
                              />
                              <div className="image-item__btn-wrapper">
                                <Button
                                  onClick={() => onImageUpdate(index)}
                                  variant="contained"
                                  sx={{ mr: 2 }}
                                >
                                  Update
                                </Button>
                                <Button
                                  onClick={() => onImageRemove(index)}
                                  variant="contained"
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </ImageUploading>
                )}
                <InputLabel style={{ marginTop: "2%", marginBottom: "2%" }}>
                  Handout Documents
                </InputLabel>
                <Stack
                  direction="row"
                  spacing={1}
                  style={{ display: " inline" }}
                >
                  {handOuts.map((i, element) => (
                    <>
                      <Chip
                        key={element}
                        label={i.fileName}
                        variant="outlined"
                        className=""
                        style={{ marginTop: "5px" }}
                        onDelete={() => handleHandoutDelete(i)}
                      />
                    </>
                  ))}
                </Stack>
                <input
                  accept="application/pdf,.doc,.docx"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  ref={refInput}
                  onChange={(e) => onUploadHandout(e.target?.files[0])}
                />
                <Stack
                  direction="row"
                  spacing={1}
                  style={{ display: " block", marginTop: "2%" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => refInput.current.click()}
                  >
                    Upload File
                  </Button>
                </Stack>
              </Card>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate("/webinars")}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
      <BackdropUI open={fileUploadLoader} />
    </>
  );
}
