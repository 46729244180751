import { ThemeProvider } from '@mui/material'
import React from 'react'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import ThemeContextProvider from './context/ThemeContext'
import MainRoutes from './routes'
import { store } from './store/store'
import { theme } from './theme/Mui'

export default function App() {


  return (
    <>
      <ThemeProvider theme={theme}>
        <ThemeContextProvider>
          <Provider store={store}>
            <MainRoutes />
          </Provider>
        </ThemeContextProvider>
      </ThemeProvider>
      <Toaster />
    </>
  )
}




