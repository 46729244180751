import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { AiOutlineLogout, AiOutlineUser } from 'react-icons/ai';
import { GrUserManager } from "react-icons/gr";
import { useNavigate } from 'react-router-dom';
import DefaultProfileImg from "../assets/images/defaultProfileImg.jpg";
import Style from "../styles/appbar.module.scss";
export default function Appbar() {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleUserMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCLoseUserMenu = () => setAnchorEl(null);


  const handleLogout = async () => {
    localStorage.clear()
    navigate("/login")
  }



  useEffect(() => {
    // dispatch(getCreatedOrderApi({ status: 'created' }))
  }, [])


  return (
    <Box className={Style.open}>
      <Stack sx={{ px: 2 }} width={'100%'} justifyContent='space-between' direction='row' alignItems='center' spacing={2}>
        <Stack height={'100%'} direction='row' alignItems='center' spacing={1}>
          {/* <img src={DealershipIcon} alt='dealership icon' /> */}
          <GrUserManager />
          <Typography variant='h4' fontWeight={600}>Admin Panel</Typography>
        </Stack>
        <Stack direction='row' alignItems='center'>
          <IconButton sx={{ borderRadius: 10 }} onClick={handleUserMenu}>
            <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
              <Avatar src={ DefaultProfileImg} />
              <Stack alignItems='flex-start'>
                <KeyboardArrowDownIcon />
              </Stack>
            </Stack>
          </IconButton>
        </Stack>
      </Stack >

      {/* user menu */}
      < Menu
        anchorEl={anchorEl} open={open} onClose={handleCLoseUserMenu} onClick={handleCLoseUserMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }
        }
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* <MenuItem>
          <ListItemIcon ><AiOutlineUser size={20} /></ListItemIcon>
          My Account
        </MenuItem> */}
        {/* <Divider /> */}
        {/* <Divider /> */}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon ><AiOutlineLogout size={20} /></ListItemIcon>
          Log Out
        </MenuItem>
      </Menu >
    </Box >
  )
}
