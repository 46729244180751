import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import axios from "axios"



const initialState = {
    result: [],
    loading: false,
    error: false,
    message: ""
}

export const csrfToken = createAsyncThunk(
    'csrf-token',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_OPENEDX_BASE_URL}csrf/api/v1/token`, 
           
            { 
                withCredentials: true,
              
              })
            return response?.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const openEdxLoginSlice = createSlice({
    name: 'csrf-token',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(csrfToken.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(csrfToken.fulfilled, (state, action) => {
            state.loading = false
            state.result = action.payload.result
            state.message = action.payload.message
        })
        builder.addCase(csrfToken.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload.error
            state.message = action.payload.message
        })
    },
    reducers: {}
})


export const openEdxLoginReducer = openEdxLoginSlice.reducer