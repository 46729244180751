import { toast } from "react-hot-toast";
import axios from "axios";


const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
});


instance.interceptors.request.use(function (config) {
    if (localStorage.getItem("access_token") !== '' && localStorage.getItem("access_token") !== null) {
        config.headers['Authorization'] = 'Bearer ' + localStorage.getItem("access_token");
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});


instance.interceptors.response.use(function (response) {
    if ((response?.config?.method != "get" && response?.data?.message)) {
        toast.success(response?.data?.message, { id: 'toast', duration: 5000, position: 'top-center' })
    } if (response?.data?.error === true) {
        toast.error(response?.data?.message, { id: 'toast', duration: 5000, position: 'top-center' })
    }
    return response;
}, function (error) {
    if (error.response?.config?.method != "get") {
        toast.error(error?.response?.data?.message, { id: 'toast', duration: 5000, position: 'top-center' })
    }

    return Promise.reject(error);
});

export default instance