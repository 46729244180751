import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NodataImg from "../../assets/images/noData.png";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SnakBar from "../../common/SnakBar";
import { supportApi } from "../../store/support/supportList";

const schema = yup
  .object({
    feedback: yup.string().required("Please provide a feedback"),
  })
  .required();
export default function SupportReply({ open, setOpen, details }) {
  // const supportEdit = useSelector((state) => state?.supportEdit);
  // const { result, loading } = supportEdit;

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const order_deatils = JSON.parse(localStorage.getItem("order_deatils"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => setOpen(false);

  const handleSupport = async (data) => {
    // var newObj;
    // newObj = { ...data, id: details.id };
    // await dispatch(supportEditApi(newObj));
    // setOpen(false);
    // if (!supportEdit?.loading) {
    await dispatch(supportApi());
    //   navigate("/support-management");
    // }
  };
  useEffect(() => {
    handleSupport()
  }, [])
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card className="modal" sx={{ width: "50%" }}>
          <form onSubmit={handleSubmit(handleSupport)}>
            <Stack spacing={2}>
              <Stack alignItems="flex-end">
                <IconButton onClick={handleClose}>
                  <HighlightOffIcon />
                </IconButton>
              </Stack>
              <Stack alignItems="center">
                <Typography variant="h5" fontWeight={600}>
                  User Query
                </Typography>
                <Typography>{details?.query}</Typography>
              </Stack>
              <Stack alignItems="center">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-email">
                    Feedback
                  </InputLabel>
                  <OutlinedInput
                    {...register("feedback")}
                    error={errors?.feedback?.message}
                    label="Feedback"
                    multiline
                    id="outlined-adornment-feedback"
                  />
                  <FormHelperText error>
                    {errors?.feedback?.message}
                  </FormHelperText>
                </FormControl>
              </Stack>
              {errors?.feedback?.message && (
                <SnakBar type="error" error={errors?.feedback?.message} />
              )}

              <Stack
                sx={{ mt: 2 }}
                justifyContent="center"
                direction="row"
              >
                <Button variant="outlined" onClick={() => setOpen(false)} sx={{ mr: 2 }}>
                  Back
                </Button>
                <Stack direction="row" spacing={1}>
                  <Button variant="contained" type="submit">
                    Confirm
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </Card>
      </Modal>
    </>
  );
}

SupportReply.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  details: PropTypes.any,
};
