import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../../config/authAxios"
import apiRoutes from "../../apiConfigs"

export const getCourseSubSectionApi = createAsyncThunk(
    'courses/get-sub-section',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.courseSubSecAction(`${data.course_id}`,0),
                {
                    params: {
                        section_block_id: data.section_block_id,
                    }
                })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)


const intialState = { result: [], loading: false, error: null, message: "" }
const getCourseSubSectionSlice = createSlice({
    name: 'get-sub-section',
    initialState: intialState,
    reducers: {
        reset: () => intialState,
        resetSubsection: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getCourseSubSectionApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getCourseSubSectionApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(getCourseSubSectionApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { reset,resetSubsection } = getCourseSubSectionSlice.actions;
export const getCourseSubSectionReducer = getCourseSubSectionSlice.reducer