import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const courseDeleteApi = createAsyncThunk(
    'auth/courses/delete',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(apiRoutes.courseAction(`${data.course_id}/publish-course`, 0),
                {
                    published: data.published ? 0 : 1,
                }
            )
            return response.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const courseDeleteSlice = createSlice({
    name: 'course_delete',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(courseDeleteApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(courseDeleteApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(courseDeleteApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const courseDeleteReducer = courseDeleteSlice.reducer