import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const EditpblApi = createAsyncThunk(
  "auth/Editpbl",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.patch(
        apiRoutes.projectBasedAction(`${data.id}`, 1),
        data
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const EditpblSlice = createSlice({
  name: "Edit pbl",
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(EditpblApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(EditpblApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(EditpblApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const Editpblreducer = EditpblSlice.reducer;
