import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const sundayPollResultsApi = createAsyncThunk(
    'auth/sundayPoll/detail',
    async (data, { rejectWithValue }) => {
        try {
            const { id: detId } = data
            delete data?.id
            const response = await AuthAxios.get(`${apiRoutes.sundayPollsAction(`poll-analytics/${detId}`,1)}`,
                {
                    params: data
                })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: {}, loading: false, error: null, message: "", total: 0 }
const sundayPollResultsSlice = createSlice({
    name: 'sundayPoll detail',
    initialState: { result: {}, loading: false, error: null, message: "", total: 0 },
    reducers: {
        resetSundayPollResult: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(sundayPollResultsApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(sundayPollResultsApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
            state.total = action?.payload?.count;
        })
        builder.addCase(sundayPollResultsApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { resetSundayPollResult } = sundayPollResultsSlice.actions;
export const sundayPollResultsReducer = sundayPollResultsSlice.reducer