import React, { PureComponent } from "react";

import PieChartComponent from "./PieChartComponent"
import LineChartComponent from "./LineChartComponent"

export default function Chart({ type, module, height = 300, width = 500, data, lineState }) {

  return (
    <>
      {type === "line" && (<>
        <LineChartComponent
          module={module}
          data={data}
          height={height}
          width={width}
          lineState={lineState}
        />
      </>)}
      {type === "pie" && (<>
        <PieChartComponent
          data={data}
          module={module}
          height={height}
          width={width}
        />
      </>)}
      {type === "bar" && (<>

      </>)}
    </>
  );
}
