import { Box, Card, Grid, Stack, Typography } from '@mui/material'
import { default as React, useEffect, useState } from 'react'
import { AiOutlineBell, AiOutlineInbox, AiOutlineMessage, AiOutlineSetting } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import NotificationList from './NotificationList'
import { userCountApi } from '../../../store/dashboard/userCount'
import { recentUserCountApi } from '../../../store/dashboard/recentUserCount'

export default function NotificationSection() {

    const dispatch = useDispatch()
    const userCount = useSelector((state) => state?.userCount?.result);
    const recentUserCount = useSelector((state) => state?.recentUserCount?.result);
    const [notificationListShow, setNotificationListShow] = useState(false)

    const handleShowNotification = () => {
        setNotificationListShow(!notificationListShow)
    }

    useEffect(() => {
        dispatch(userCountApi());
        dispatch(recentUserCountApi());
    }, [dispatch])

    return (
        <Stack spacing={2}>
            <Card sx={{ py: 2 }}>
                <Typography fontWeight={600} variant='h5' my={1}>Notifications & Updates</Typography>
                <Grid container rowGap={2}>
                    <Grid item xs={6} sm={6} md={6} lg={3}>
                        <Box className='customCard1'
                        // onClick={handleShowNotification}
                        >
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <AiOutlineBell size={25} />
                                <Stack >
                                    <Typography>Notifications</Typography>
                                    <Typography variant='h4' fontWeight={600}>10</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3}>
                        <Box className='customCard1' >
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <AiOutlineBell size={25} />
                                <Stack >
                                    <Typography>Total User Count</Typography>
                                    <Typography variant='h4' fontWeight={600}>{userCount}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3}>
                        <Box className='customCard1' >
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <AiOutlineBell size={25} />
                                <Stack >
                                    <Typography> Recent User Count</Typography>
                                    <Typography variant='h4' fontWeight={600}>{recentUserCount?.length}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3}>
                        <Box className='customCard1' >
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <AiOutlineBell size={25} />
                                <Stack >
                                    <Typography> Pending Feedbacks</Typography>
                                    <Typography variant='h4' fontWeight={600}>{recentUserCount?.length}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={6} sm={6} md={6} lg={3}>
                        <Box className='customCard2'>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <AiOutlineInbox size={25} />
                                <Stack>
                                    <Typography>Pending Action Items</Typography>
                                    <Typography variant='h4' fontWeight={600}>10</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3}>
                        <Box className='customCard3'>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <AiOutlineSetting size={25} />
                                <Stack>
                                    <Typography>Critical Actionables</Typography>
                                    <Typography variant='h4' fontWeight={600}>10</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3}>
                        <Box className='customCard4'>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <AiOutlineMessage size={23} />
                                <Stack>
                                    <Typography>Messages</Typography>
                                    <Typography variant='h4' fontWeight={600}>10</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid> */}
                </Grid>
            </Card>
            {notificationListShow && <NotificationList />}
        </Stack >
    )
}
