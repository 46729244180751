import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const pageDetailsApi = createAsyncThunk(
    'auth/pages/details',
    async (data, { rejectWithValue }) => {
        try {
            const { name, ...rest } = data;
            const response = await AuthAxios.get(apiRoutes.adminAction(name, 1), { params: rest }
            )
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "" }
const pageDetailsSlice = createSlice({
    name: 'page details',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {
        resetPage: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(pageDetailsApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(pageDetailsApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(pageDetailsApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})
export const { resetPage } = pageDetailsSlice.actions;
export const pageDetailsReducer = pageDetailsSlice.reducer