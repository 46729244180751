import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs";

export const qnsBankListApi = createAsyncThunk(
    'qns-bank/list',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.questionBankAction("add", 0), { params: data })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const quizSlice = createSlice({
    name: 'qns-bank/list',
    initialState: { result: [], loading: false, error: null, message: "", total: 0 },
    extraReducers: (builder) => {
        builder.addCase(qnsBankListApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(qnsBankListApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
            state.total = action?.payload?.count
        })
        builder.addCase(qnsBankListApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const qnsBankListReducer = quizSlice.reducer