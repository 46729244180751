import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from '@mui/icons-material/Add';
import { Backdrop, Button, Card, CircularProgress, FormControl, FormHelperText, InputLabel, OutlinedInput, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { courseSectionAddApi } from "../../store/courses/addSection";
import { deleteSectionApi } from "../../store/courses/deleteSection";
import { getCourseSectionApi } from "../../store/courses/getSection";
import { updateSectionApi } from "../../store/courses/updateSection";
import { addSectionDetails } from "../../store/courses/sectionDetails";

let newArray = []

const schema = yup.object({
    section_title: yup.string().required(),
    published: yup.boolean().required()
})

export default function AddCouresSection() {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: { published: true }
    });

    const courseDetailsData = useSelector((state) => state.coursesDetails);
    const { result } = courseDetailsData
    const getCourseSectionData = useSelector((state) => state.getCourseSection);
    const { result: courBlockResult } = getCourseSectionData
    const courseSectionAddData = useSelector((state) => state.courseSectionAdd);
    const { loading: courseSectionLoading } = courseSectionAddData


    const dispatch = useDispatch()
    const params = useParams();
    const navigate = useNavigate();

    const [loadingdelete, setLoadingdelete] = useState(false)
    const startDate = useState(dayjs().toJSON())[0]
    const [addSectionVisible, setAddSectionVisible] = useState(false)
    const [sectionData, setSectionData] = useState([])

    const handleAddSection = async (data) => {
        const courseSectinAdd = await dispatch(courseSectionAddApi({ id: params?.id, ...data, start_date: startDate, course_block_id: result?.[0]?.block_id }))
        if (!courseSectinAdd?.payload?.action?.error) {
            addSectionDetails(data)
            setAddSectionVisible(false)
            dispatch(getCourseSectionApi({ id: params?.id }))
            reset()
        }
    }

    const handleDeleteSection = async (data, idx) => {
        setLoadingdelete(true)
        const courseSectinDelete = await dispatch(deleteSectionApi(data))
        setSectionData(sectionData.filter((data)=>data.id ===idx))
        if (!courseSectinDelete?.payload?.action?.error) {
            setAddSectionVisible(false)
            setLoadingdelete(false)
            dispatch(getCourseSectionApi({ id: params?.id }))
        }
    }


    const updateSection = async (data, idx) => {
        let payload = { ...data, start_date: dayjs().toJSON(), published: true, section_title: data.title }
        const updateSectionData = await dispatch(updateSectionApi(payload))
        if (!updateSectionData?.payload?.action?.error) {
            dispatch(getCourseSectionApi({ id: params?.id }))
        }
    }
    const handleChangeSection = (event, idx, data) => {
        newArray = [...sectionData]
        newArray[idx] = { ...data, title: event.target.value }
        setSectionData(newArray)
    }

    const handleNavigateSubSection = (data) => {
        navigate(`/edit-course/sub-section?course_id=${params?.id}&section_block_id=${data.section_block_id}`)
    }

    useEffect(() => {
        dispatch(getCourseSectionApi({ id: params?.id }))
    }, [dispatch])

    useEffect(() => {
        setSectionData(courBlockResult)
    }, [courBlockResult])


    return (
        <>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingdelete
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <Typography sx={{ my: 2 }} variant='h4' fontWeight={600}>Courese Components</Typography>
            <Card p={2} m={3} sx={{ background: '#e1f2f2', mt: 2 }}>
                <Button sx={{ mb: 2 }} disabled={addSectionVisible} variant="contained" endIcon={<AddIcon />} onClick={() => setAddSectionVisible(true)}>Add New Section </Button>
                {addSectionVisible
                    ? <Fragment>
                        <Stack spacing={2}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel> Section Title</InputLabel>
                                <OutlinedInput {...register("section_title")} error={errors?.section_title?.message} label="Section Title" />
                                <FormHelperText error>{errors?.section_title?.message}</FormHelperText>
                            </FormControl>
                            <Stack direction='row' spacing={2}>
                                <Button disabled={courseSectionLoading} color="success" variant="contained" onClick={handleSubmit(handleAddSection)} sx={{ mb: 2 }}>{courseSectionLoading ? <CircularProgress /> : "Publish"}</Button>
                            </Stack>
                        </Stack>
                    </Fragment>
                    : <></>
                }
            </Card>

            {sectionData?.map((data, idx) => (
                <Card key={idx} p={2} m={3} sx={{ background: '#e1f2f2', mt: 2 }}>
                    <Fragment key={idx}>
                        <Stack spacing={2}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel> Section Title</InputLabel>
                                <OutlinedInput label="Section Title" defaultValue={data?.title} onChange={(event) => handleChangeSection(event, idx, data)} />
                            </FormControl>
                            <Stack direction='row' spacing={2}>
                                <Button disabled={sectionData[idx]['loading']} color="success" variant="contained" sx={{ mb: 2 }} onClick={() => updateSection(data, idx)}>{sectionData[idx]['loading'] ? <CircularProgress /> : "Update"}</Button>
                                <Button color="error" variant="contained" sx={{ mb: 2 }} onClick={() => handleDeleteSection(data, idx)}>{'Delete'}</Button>
                                <Button color="info" variant="contained" sx={{ mb: 2 }} onClick={() => handleNavigateSubSection(data)}>Add Sub Section</Button>
                            </Stack>
                        </Stack>

                    </Fragment>
                </Card >
            ))}
        </>
    )
}
