import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { difficultyLevelDeleteApi } from "../../store/difficulty/difficultyDelete";
import { difficultyLevelApi } from "../../store/difficulty/difficultyList";

export default function DifficultyLevelDelete({ open, setOpen ,id}) {
  const difficultyLevelDelete = useSelector((state) => state?.difficultyLevelDelete);
  const dispatch = useDispatch();

  const handleClose = () => setOpen(false);

  const deleteCourseConfirm = async () => {
    await dispatch(
      difficultyLevelDeleteApi(id)
    );
    setOpen(false);
    if (!difficultyLevelDelete?.loading) {
      await dispatch(
        difficultyLevelApi()
      );
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card className="modal" sx={{ width: "30%" }}>
          <Stack alignItems="flex-end">
            <IconButton onClick={handleClose}>
              <HighlightOffIcon />
            </IconButton>
          </Stack>
          <Stack alignItems="center" my={2}>
            <Typography variant="h5" fontWeight={600}>
              Are you sure you want to delete this difficulty level ?
            </Typography>
            <Typography variant="h5" fontWeight={600}>
              Please click confirm
            </Typography>
          </Stack>
          

          <Stack sx={{ mt: 2 }} justifyContent="center" direction="row">
            <Button variant="outlined" onClick={() => setOpen(false)} sx={{ mr: 2 }}>
              Back
            </Button>
            <Stack direction="row" spacing={1}>
              <Button variant="contained" onClick={deleteCourseConfirm}>
                Confirm
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>
    </>
  );
}

DifficultyLevelDelete.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  id: PropTypes.any,
};
