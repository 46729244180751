import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types'

const ProtectedRoute = (props) => {

    const navigate = useNavigate();

    const [isLoggedIn, setIsLoggedIn] = useState(true);

    const checkUserToken = () => {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken || accessToken == 'undefined') {
            setIsLoggedIn(false);
            return navigate('/login');
        }
        setIsLoggedIn(true);
    }

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);


    return (
        <Fragment>{isLoggedIn ? props.children : null}</Fragment>
    );
}


export default ProtectedRoute;

ProtectedRoute.propTypes = {
    children: PropTypes.any,
};