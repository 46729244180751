import { useEffect, useState, useCallback, useMemo, useContext, useId } from "react";
import {
    Box,
    Stack,
    Typography,
    MenuItem,
} from "@mui/material";
import { DataGrid, GridToolbarExportContainer, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, getGridStringOperators } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { userContentReportTableApi } from "../../store/analytics/userContentReport";
import { ThemeContext } from '../../context/ThemeContext';
import NodataImg from "../../assets/images/noData.png";
import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid';
import CustomGridToolbar from "../../components/CustomGridToolbar";
import { userContent } from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";

const columns = [
    {
        field: "username",
        headerName: "Username",
        width: 450,
        filterable: false,
        sortable: false,
        hideable: false
    },
    {
        field: "fullname",
        headerName: "FullName",
        width: 450,
        filterable: true,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals' | operator.value === 'contains',
        ),
        sortable: false,
        hideable: false
    },
    {
        field: "phone_number",
        headerName: "Phone Number",
        width: 450,
        filterable: true,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'contains',
        ),
        sortable: false,
        hideable: false
    },
    {
        field: "content_title",
        headerName: "Title",
        width: 450,
        filterable: true,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals' | operator.value === 'contains',
        ),
        sortable: false,
        hideable: false
    },
    {
        field: "content_type",
        headerName: "Content Type",
        width: 450,
        filterable: false,
        sortable: false,
        hideable: false
    },
    {
        field: "enrollment_date",
        headerName: "Enrolment date",
        width: 200,
        filterable: false,
        sortable: false,
        renderCell: (data) => (<>
            <Typography sx={{ overflowWrap: "anywhere" }}>{data?.row?.enrollment_date && dayjs(data?.row?.enrollment_date).format("YYYY-MM-DD HH:mm:ss")}</Typography>
        </>),
        hideable: true
    },
    {
        field: "like",
        headerName: "Like",
        width: 200,
        filterable: false,
        sortable: false,
        hideable: false
    },
    {
        field: "bookmark",
        headerName: "Bookmark",
        width: 200,
        filterable: false,
        sortable: false,
        hideable: false
    },
    {
        field: "grade",
        headerName: "Grade",
        width: 200,
        filterable: false,
        sortable: false,
        renderCell: (data) => (<>
            <Typography sx={{ overflowWrap: "anywhere" }}>{Number(data?.row?.grade).toFixed(2)}</Typography>
        </>),
        hideable: true
    },
    {
        field: "platform",
        headerName: "Platform",
        width: 200,
        filterable: false,
        sortable: false,
        hideable: true
    },
    {
        field: "passed",
        headerName: "Pass",
        width: 200,
        filterable: false,
        sortable: false,
        hideable: true
    },
    {
        field: "passing_date",
        headerName: "Passing date",
        width: 200,
        filterable: false,
        sortable: false,
        renderCell: (data) => (<>
            <Typography sx={{ overflowWrap: "anywhere" }}>{data?.row?.passing_date && dayjs(data?.row?.passing_date).format("YYYY-MM-DD HH:mm:ss")}</Typography>
        </>),
        hideable: true
    },
    {
        field: "certificate_generation",
        headerName: "Certificate generation",
        width: 200,
        filterable: false,
        sortable: false,
        hideable: true
    },
    {
        field: "total_time_spent",
        headerName: "Time spent",
        width: 200,
        filterable: false,
        sortable: false,
        hideable: false
    },
];

export default function ContentConsumptionNonGraded({ module, region, state }) {
    const userContentReportTable = useSelector((state) => state?.userContentReportTable);
    const { result: tableResult, loading: tableLoading, total } = userContentReportTable;
    const dispatch = useDispatch();
    const [isDownload, setIsDownload] = useState(false)
    const [queryOptions, setQueryOptions] = useState({});
    const { paginationModel, setPaginationModel } = useContext(ThemeContext)
    const onFilterChange = useCallback((filterModel) => {
        setQueryOptions({ filterModel: { ...filterModel } });
    }, []);
    const getRowSpacing = useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
        };
    }, []);
    useEffect(() => {
        const filterModel = queryOptions?.filterModel;
        const items = filterModel?.items;
        const firstItem = items && items.length > 0 ? items[0] : {};
        const quickFilterValues = filterModel?.quickFilterValues;

        const query = {
            page: paginationModel?.page + 1,
            page_size: paginationModel?.pageSize,
            field: firstItem.field || '',
            module_name: module,
            city: region,
            state: state,
            operator: firstItem.operator || (quickFilterValues && "contains") || '',
            value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
        };
        dispatch(userContentReportTableApi(query));
    }, [dispatch, queryOptions, paginationModel, module, region, state]);

    return (<>
        <Stack direction="row" spacing={1} sx={{ my: 2, padding: "10px" }} alignItems="center" justifyContent={"space-between"}>
            <Typography variant="h3">User Content Report</Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ my: 2, padding: "10px" }} alignItems="center">
            <DataGrid
                loading={tableLoading}
                rowCount={total}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                pageSize={paginationModel?.pageSize}
                onPageSizeChange={(newPageSize) => {
                    setPaginationModel({
                        ...paginationModel,
                        pageSize: newPageSize,
                    })
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pagination
                paginationMode="server"
                sx={{ height: 500 }}
                getRowId={(row) => `${row.user_id}${row.id}${row.module_id}${row.platform}${uuidv4()}`}
                rows={tableResult}
                getRowHeight={() => 'auto'}
                getRowSpacing={getRowSpacing}
                columns={columns}
                // disableColumnSelector
                disableDensitySelector
                filterMode="server"
                onFilterModelChange={onFilterChange}
                slots={{
                    noRowsOverlay: () => (
                        <Box className="noData">
                            <img src={NodataImg} alt="no data found" />
                        </Box>
                    ),
                    toolbar: (props) =>
                    (CustomGridToolbar({
                        ...props, moduleType: "communityEngagement", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: userContent, module: module,
                        region: region,
                        state: state
                    })),
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Stack>
        <BackdropUI open={isDownload} />

    </>)
}