import { yupResolver } from "@hookform/resolvers/yup";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Button, Card, FormControl, FormHelperText, IconButton, InputLabel, Modal, OutlinedInput, Stack, Typography } from '@mui/material';
import { default as React, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { authOtpViaSMSApi } from "../../store/auth/authOtpViaSMS";
import { forgetPasswordViaSMSApi } from "../../store/auth/forgetPasswordViaSMS";
import ResetViaPhoneOTP from "./ResetViaPhoneOTP";
import BackdropUI from "../../ui/Backdrop";
import SnakBar from "../../common/SnakBar";
import PropTypes from 'prop-types'



const schema = yup.object({
    mobile: yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, { message: "Invalid phone number" }).required('Phone number is required'),
}).required('Phone number is required.')


export default function ForgetPasswordSMSConf({ open, setOpen }) {

    const forgetPasswordViaSMSSelector = useSelector(state => state?.forgetPasswordViaSMS)
    const { loading } = forgetPasswordViaSMSSelector

    const { register, setError, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(schema) })

    const dispatch = useDispatch()

    const [smsVerifyModal, setSmsVerifyModal] = useState(false)
    const [backdrop, setBackDrop] = useState(false)

    const handleClose = () => {
        setOpen(false)
        reset()
    }

    const handleSendVierifyEmail = async (data) => {
        const passData = await dispatch(forgetPasswordViaSMSApi(data))
        if (!passData?.payload?.error) {
            localStorage.setItem("access_token", passData?.payload?.result?.access_token)
            localStorage.setItem("email", passData?.payload?.result?.email)
            dispatch(authOtpViaSMSApi())
            setSmsVerifyModal(true)
            setOpen(false)
            reset()
        } else {
            setError('phoneError', { type: 'custom', message: passData?.payload?.message })
        }
    }

    useEffect(() => {
        if (loading) setBackDrop(true)
        else setBackDrop(false)
    }, [backdrop, loading])

    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Card className='modal'>
                    <form onSubmit={handleSubmit(handleSendVierifyEmail)}>
                        <Stack spacing={2}>
                            <Stack alignItems='flex-end'><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack>
                            <Stack alignItems='center'>
                                <Typography variant='h5' fontWeight={600}>Phone Verification</Typography>
                                <Typography>Please enter your registered phone to complete your verification.</Typography>
                            </Stack>
                            <Stack alignItems='center'>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-email">Phone Number</InputLabel>
                                    <OutlinedInput
                                        {...register("mobile")}
                                        error={errors?.mobile?.message}
                                        label='Phone Number'
                                        id="outlined-adornment-mobile"
                                        startAdornment={<LocalPhoneIcon />}
                                    />
                                    <FormHelperText error>{errors?.mobile?.message}</FormHelperText>
                                </FormControl>
                            </Stack>
                            {errors?.phoneError?.message && <SnakBar type='error' error={errors?.phoneError?.message} />}
                            <Button variant='contained' type="submit">Send</Button>
                        </Stack>
                    </form>
                </Card>
            </Modal>


            <ResetViaPhoneOTP open={smsVerifyModal} setOpen={setSmsVerifyModal} />
            <BackdropUI open={backdrop} />
        </>
    )
}

ForgetPasswordSMSConf.propTypes = {
    open: PropTypes.any,
    setOpen: PropTypes.any,
};