import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import axios from "axios"




const initialState = {
    result: [],
    loading: false,
    error: false,
    message: ""
}

export const openEdxLogin = createAsyncThunk(
    'open-edx-login',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_OPENEDX_BASE_URL}api/user/v2/account/login_session/`, data,
           
            { 
                withCredentials: true,
                headers: {
                    "content-type":"application/x-www-form-urlencoded",
                    "x-csrftoken":`${data.csrftoken}`,
                }
              })
            return response?.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const openEdxLoginSlice = createSlice({
    name: 'open-edx-login',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(openEdxLogin.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(openEdxLogin.fulfilled, (state, action) => {
            state.loading = false
            state.result = action.payload.result
            state.message = action.payload.message
        })
        builder.addCase(openEdxLogin.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload.error
            state.message = action.payload.message
        })
    },
    reducers: {}
})


export const openEdxLoginReducer = openEdxLoginSlice.reducer