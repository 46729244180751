import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NodataImg from "../../assets/images/noData.png";
import { userApi } from "../../store/user/userList";
import { userStatusApi } from "../../store/user/statusChange";

export default function UserStatus({ open, setOpen,details }) {
  const userStatus = useSelector((state) => state?.userStatus);

  const order_deatils = JSON.parse(localStorage.getItem("order_deatils"));

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleClose = () => setOpen(false);

  const deleteCourseConfirm = async () => {
    let data ={
      userName: details.username,
      active_status: !details.is_active,
    }
    await dispatch(
        userStatusApi(data)
    );
    //console.log(data)
    setOpen(false);
    if (!userStatus?.loading) {
      dispatch(
        userApi()
      );
      navigate("/user-management");
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card className="modal" sx={{ width: "30%" }}>
          <Stack alignItems="flex-end">
            <IconButton onClick={handleClose}>
              <HighlightOffIcon />
            </IconButton>
          </Stack>
          <Stack alignItems="center" my={2}>
            <Typography variant="h5" fontWeight={600}>
              Are you sure you want to change the status of  this user ?
            </Typography>
            <Typography variant="h5" fontWeight={600}>
              Please click confirm
            </Typography>
          </Stack>
          

          <Stack sx={{ mt: 2 }} justifyContent="center" direction="row">
            <Button variant="outlined" onClick={() => setOpen(false)} sx={{ mr: 2 }}>
              Back
            </Button>
            <Stack direction="row" spacing={1}>
              {/* <Button variant='outlined' onClick={notRequiredOrderHandler}>Not Required</Button> */}
              <Button variant="contained" onClick={deleteCourseConfirm}>
                Confirm
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>
    </>
  );
}

UserStatus.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  details:PropTypes.any,
};
