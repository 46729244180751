import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Legend } from 'recharts';



const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};
class CustomLegend extends PureComponent {
    render() {
        const { payload } = this.props;
        const half = Math.ceil(payload.length / 2);
        const firstHalf = payload.slice(0, half);
        const secondHalf = payload.slice(half);
        const renderLegendItems = (items) => (
            items.map((entry, index) => (
                <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                    <div style={{ width: 10, height: 10, backgroundColor: entry.color, marginRight: 8 }}></div>
                    <span>{entry.value}</span>
                </div>
            ))
        );
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', columnGap: "2rem" }}>
                    <div>
                        {renderLegendItems(firstHalf)}
                    </div>
                    <div>
                        {renderLegendItems(secondHalf)}
                    </div>
                </div>
            </div>
        );
    }
}

export default class PieChartComponent extends PureComponent {

    state = {
        activeIndex: 0,
        data: [],
    };

    onPieEnter = (_, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    componentDidMount() {
        // Set initial data from props
        this.setState({ data: this.props.data });
    }

    componentDidUpdate(prevProps) {
        // Update data in state if props have changed
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.props.data });
        }
    }
    render() {
        return (
            <ResponsiveContainer aspect={2 / 1} >
                <PieChart>
                    <Legend
                        verticalAlign="middle"
                        align='right'
                        layout='vertical'
                        height={36}
                        content={<CustomLegend />}
                        wrapperStyle={{ display: "flex", alignItems: "center", alignContent: "center" }}
                    />
                    <Pie
                        activeIndex={this.state.activeIndex}
                        activeShape={renderActiveShape}
                        data={this.state.data}
                        cx="50%"
                        cy="50%"
                        innerRadius={120}
                        outerRadius={150}
                        fill="#8884d8"
                        dataKey="value"
                        onMouseEnter={this.onPieEnter}
                    />
                </PieChart>
            </ResponsiveContainer>
        );
    }
}
