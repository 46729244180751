import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Card, IconButton, Modal, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { authOtpViaEmailApi } from '../../store/auth/authOtpViaEmail';
import { verifyAuthOtpEmailApi } from '../../store/auth/verifyAuthOtpEmail';
import ResetPassword from './ResetPassword';
import { useForm } from 'react-hook-form';
import SnakBar from '../../common/SnakBar';
import PropTypes from 'prop-types'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'


export default function ResetViaEmailOTP({ open, setOpen, back }) {

  const { setError, formState: { errors }, } = useForm()

  const dispatch = useDispatch()

  const [otp, setOtp] = useState('')
  const [resetPasswordModal, setResetPasswordModal] = useState(false)

  const dealorEmail = localStorage.getItem("email")

  const handleClose = () => {
    setOpen(false)
    setOtp([])
  }

  const handleVerify = async () => {
    const verifyData = await dispatch(verifyAuthOtpEmailApi({ otp: Number(otp) }))
    if (!verifyData?.payload?.error) {
      localStorage.setItem("access_token", verifyData?.payload?.result?.access_token)
      setResetPasswordModal(true)
      setOpen(false)
    }
    else {
      setError('optError', { type: 'custom', message: verifyData?.payload?.message })
    }
  }

  const handleBack = () => {
    back(true)
    setOpen(false)
    setOtp([])
  }

  const handleResendOtp = () => {
    dispatch(authOtpViaEmailApi())
  }



  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card className='modal'>
          <Stack spacing={2}>
            <Stack direction='row' alignContent='center' justifyContent='space-between'>
              <IconButton onClick={handleBack}><MdOutlineKeyboardBackspace /></IconButton>
              <IconButton onClick={handleClose}><HighlightOffIcon /></IconButton>
            </Stack>
            <Stack alignItems='center'>
              <Typography variant='h5' fontWeight={600}>Email Verification</Typography>
              <Typography>{`We have sent code to your Email ${dealorEmail?.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2")}`}</Typography>
            </Stack>
            <OtpInput
              containerStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              inputStyle={{ width: 50, height: 50 }}
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>&nbsp; &nbsp;</span>}
              renderInput={(props) => <input {...props} />}
            />
            {errors?.optError?.message && <SnakBar type='error' error={errors?.optError?.message} />}
            <Button variant='contained' onClick={handleVerify} disabled={otp?.length < 4}>Verify Account</Button>
            <Stack spacing={0.5} justifyContent='center' direction='row' alignItems='center'>
              <Typography>Didn’t receive OTP?</Typography>
              <Typography sx={{ cursor: 'pointer' }} fontWeight={600} onClick={handleResendOtp}>Resend</Typography>
            </Stack>
          </Stack>
        </Card>
      </Modal>

      <ResetPassword open={resetPasswordModal} setOpen={setResetPasswordModal} />
    </>
  )
}


ResetViaEmailOTP.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  back: PropTypes.bool,
};