import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const classEditApi = createAsyncThunk(
    'auth/class',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.patch(apiRoutes.classesAction(`${data.id}`, 1), data)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const classEditSlice = createSlice({
    name: 'class Edit',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(classEditApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(classEditApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(classEditApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const classEditReducer = classEditSlice.reducer