import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const shortVideoEditApi = createAsyncThunk(
    'auth/shortVideos/edit',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.patch(apiRoutes.shortVideosAction(`${data.id}`, 1), data)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const shortVideoEditSlice = createSlice({
    name: 'shortVideo Edit',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(shortVideoEditApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(shortVideoEditApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(shortVideoEditApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const shortVideoEditReducer = shortVideoEditSlice.reducer