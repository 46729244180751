import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import dayjs from "dayjs";
import ImageUploading from "react-images-uploading";
import DefaultProfileImg from "../../assets/images/defaultProfileImg.jpg";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import Chip from "@mui/material/Chip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RichTextEditor from "react-rte";
import { podcastAddApi } from "../../store/podcast/addPodcast";
import { podcastDetailsApi } from "../../store/podcast/podcastDetails";
import Style from "../../styles/profile.module.scss";
import { TimeField } from "@mui/x-date-pickers";
import { toast } from "react-hot-toast";
import { podcastEditApi } from "../../store/podcast/editPodcast";
import { categoryApi } from "../../store/categoryDetails/categoryList";
import { uploadImageApi } from "../../store/upload/uploadImage";
import { partnerEditApi } from "../../store/partner/editPartner";
import { partnerAddApi } from "../../store/partner/addPartner";
import { partnerDetailsApi } from "../../store/partner/partnerDetails";
const schema = yup
  .object({
    name: yup.string().required("Please Provide name").trim(),
    partner_category: yup.string().required("Please Provide A Catergory "),
  })
  .required();

export default function AddPartner() {
  const podcastEdit = useSelector((state) => state?.partnerEdit);
  const podcastAdd = useSelector((state) => state?.partnerAdd);
  const podcastDetails = useSelector((state) => state?.partnerDetails);
  const uploadImage = useSelector((state) => state?.uploadImage);
  
  const { result } = podcastDetails;


  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });

  const [images, setImages] = React.useState([]);

  const [cat, setCat] = useState();
  const [imageLink, setImageLink] = useState();

  const ref = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {

    if (params.id) {
      dispatch(partnerDetailsApi(params.id));
    }
  }, [dispatch]);

  useEffect(() => {

    setCat(podcastDetails.result?.partner_category);
    setImageLink(podcastDetails.result?.image);

  }, [podcastDetails]);

  const onUploadImage = async (imageList, addUpdateIndex) => {
    if (imageList.length > 0) {
      let formData = new FormData();

      formData.append("file", imageList[0]?.file);
      await dispatch(uploadImageApi(formData));
    }
    setImages(imageList);
  };

  const handleUpdateForm = async (data) => {
    var newObj;

    if (imageLink) {
      newObj = { ...data, image: imageLink, };
    } else if (
      images.length > 0 &&
      !uploadImage?.loading &&
      uploadImage.result
    ) {
      newObj = { ...data, image: uploadImage.result ,};
    } else {
      toast.error("Please Upload An Image", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      return;
    }

    console.log(newObj,"newObj")
    if (params.id) {
      newObj = { ...newObj, id: params.id };
      await dispatch(partnerEditApi(newObj));
      if (!podcastEdit?.loading) {
        navigate("/partners-management");

      }
    } else {
      await dispatch(partnerAddApi(newObj));
      if (!podcastAdd?.loading) {
        navigate("/partners-management");
      
      }
    }

  };

 
 
  useEffect(() => {
    let defalutValues = {};
    defalutValues["name"] = result?.name;
    defalutValues["title"] = result?.title;
    defalutValues["url"] = result?.url;
    defalutValues["partner_category"] = result?.partner_category;
    defalutValues["banner"] = result?.banner;
    reset(defalutValues);
  }, [result]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Partner Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />

                  {/* {accountToggle == "accountToggle" && */}
                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Name</InputLabel>
                        <OutlinedInput
                          {...register("name")}
                          label="Name"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.name?.message}
                          defaultValue={result.name}
                        />
                        <FormHelperText error>
                          {errors?.name?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Title</InputLabel>
                        <OutlinedInput
                          {...register("title")}
                          label="Title"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.title?.message}
                          defaultValue={result.title}
                        />
                        <FormHelperText error>
                          {errors?.title?.message}
                        </FormHelperText>
                      </FormControl>
                   
                    </Stack>
                
                    <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Url Link</InputLabel>
                        <OutlinedInput
                          {...register("url")}
                          label="Url Link"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.url?.message}
                          defaultValue={result.url}
                        />
                        <FormHelperText error>
                          {errors?.url?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Category
                        </InputLabel>

                        <Select
                          {...register("partner_category")}
                          label="Category"
                          id="outlined-adornment-category"
                          value={cat ? cat : null}
                          notched
                          onChange={(e) => setCat(e.target.value)}
                        >
          
                          
                            <MenuItem
                              value={"State_Partners"}
                              key={"1"}
                              selected={"State_Partners" == result?.partner_category}
                            >
                              {"State Partners"}
                            </MenuItem>
                            <MenuItem
                              value={"NGO_Partners"}
                              key={"1"}
                              selected={"NGO_Partners" == result?.partner_category}
                            >
                              {"NGO Partners"}
                            </MenuItem>
                            <MenuItem
                              value={"Our_Experts"}
                              key={"1"}
                              selected={"Our_Experts" == result?.partner_category}
                            >
                              {"Our Experts"}
                            </MenuItem>
                       
                        </Select>

                        <FormHelperText error>
                          {errors?.partner_category?.message}
                        </FormHelperText>
                      </FormControl>
                    
                    </Stack>
                    
                  </>
 
                </Stack>

                <InputLabel style={{ marginTop:'2%' }}>Partner Image </InputLabel>
                {imageLink ? (
                  <div className="image-item">
                    <img src={imageLink} alt="" width="320" height="180" />
                    <div className="image-item__btn-wrapper">
                      <Button
                        onClick={() => setImageLink(null)}
                        variant="contained"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                ) : (
                  <ImageUploading
                    // multiple
                    acceptType={['png','jpeg','jpg']}
                    value={images}
                    onChange={onUploadImage}
                    maxNumber={1}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                      errors,
                    }) => (
                      <>
                        <div
                          className="upload__image-wrapper"
                          style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                          {imageList.length > 0 ? null : (
                            <Button
                              type="button"
                              variant="contained"
                              style={isDragging ? { color: "red" } : undefined}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Click or Drop here
                            </Button>
                          )}

                          {errors && (
                            <div>
                              {errors.maxNumber && (
                                <span>
                                  Number of selected images exceed maxNumber
                                </span>
                              )}
                              {errors.acceptType && (
                                <span>
                                  Your selected file type is not allow
                                </span>
                              )}
                              {errors.maxFileSize && (
                                <span>
                                  Selected file size exceed maxFileSize
                                </span>
                              )}
                              {errors.resolution && (
                                <span>
                                  Selected file is not match your desired
                                  resolution
                                </span>
                              )}
                            </div>
                          )}
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img
                                src={image["data_url"]}
                                alt=""
                                width="320"
                                height="180"
                              />
                              <div className="image-item__btn-wrapper">
                                <Button
                                  onClick={() => onImageUpdate(index)}
                                  variant="contained"
                                  sx={{ mr: 2 }}
                                >
                                  Update
                                </Button>
                                <Button
                                  onClick={() => onImageRemove(index)}
                                  variant="contained"
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </ImageUploading>
                )}
                {/* </Stack> */}
              </Card>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate("/partners-management")}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
    </>
  );
}
